import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
// import { D3Service, D3 } from 'd3-ng2-service';
import * as D3 from 'd3';
import { SuccessrateService } from '../../services/successrate/successrate.service';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { BrandService } from '../../services/brand/brand.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../../settings.global';
import { DashboardService } from '../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-success-rate-marketplace',
  templateUrl: './success-rate-marketplace.component.html',
  styleUrls: ['./success-rate-marketplace.component.scss']
})
export class SuccessRateMarketplaceComponent implements OnInit, AfterViewInit {

  @ViewChild('pieChart1') pieChart1: ElementRef;
  @ViewChild('pieChart2') pieChart2: ElementRef;

 // private d3: D3;
 // public colors: any;
  public data: any;
  public details: any;
  public pieData: any;
  public pieData1: any;
  public pieData2: any;
  //public chartOption: object;
  public title1: any;
  public title2: any;
  public order: any;
  public label1: any;
  public label2: any;
  public pieDatat1: any;
  public pieDatat2: any;
  public lsData: any;
  private radius: number;
  private width: number;
  private height: number;
  private sum: number;
  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  public mapData: any;
  public fullData:any;
  public pie1Array: any;
  public pie2Array: any;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public selectedDateRangeLabel : any;
  public evolutionSuccessRateLabel : any;
  public multiLineChartData : any;
  public multiLineChartData1 : any;
  public motherCompanyList : any;
  public motherCompanies : any;
  public marketplacesList : any;
  public allMarketplacesList : any;
  public marketplaces : any;
  public itemList = [];
  public selectedItems = [];
  public settings = {};
  public selectedMarketplaces = [];
  public selectedMotherCompany : any;
  public mplaceAllLIst : any;
  public mCompanyId: any;
  public multiLineChartAxisData : any ={
    startDate:'',
    endDate:'',
    quartersCount:''
  };  
  public multiChartColors: any;

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };
 
  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;

  constructor(
     private successrateService: SuccessrateService,
     private brandService: BrandService,
     private loaderService:LoaderService,
     private authServiceObj:AuthService,
     private toastr: ToastrService,
     private lsService: LocalStorageService,
     private dashboardService:DashboardService) {
      this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel'); 
    //this.d3 = this.d3Service.getD3();
    this.details = [];
    this.data = [];
    this.pieData1 = [];
    this.pieData2 = [];
    // this.order = 'ASC';
    this.order = 'DESC';
    this.label1 = false;
    this.label2 = false;
    this.pieDatat1 = [];
    this.pieDatat2 = [];
    this.mapData = [];
    this.fullData = [];
    this.motherCompanyList = [];
    this.motherCompanies = [];
    this.marketplacesList = [];
    this.allMarketplacesList = [];
    this.marketplaces = [];
    // this.multiChartColors = ['#4d4d00','#808000','#b3b300','#cccc00','#d7b70f','#f0d028','#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];
    this.multiChartColors = ['#4d4d00','#007acc','#d7b70f','#ff0000','#ff00ff','#000000','#d7b70f','#f0d028','#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];
    

    this.lsData = JSON.parse(this.lsService.retrieve('search_base'));

    this.details = {
        id: this.lsService.retrieve('active_brand_id'),
        region: this.lsData.region,
        fromDate: this.lsData.fromDate,
        toDate: this.lsData.toDate,
        orderBy: this.order
    };
    this.daterange.start = this.lsData.fromDate;
    this.daterange.end = this.lsData.toDate;
    this.pie1Array = [];
    this.pie2Array = [];
    //this.colors = ['#8B008B', '#C70039', '#248793', '#AF5F10', '#F3AA19'];
    
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  this.lsData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.lsData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;    
    this.datepickerOptions.startDate =  new Date(this.lsData.fromDate);
    this.datepickerOptions.endDate = new Date(this.lsData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate) 
   }

  ngAfterViewInit() {
    this.getChart(this.details);
  }

  onChange(order) {
    this.details.orderBy = order;
    this.getChart(this.details);
  }

   //Change date range
  selectedDate(value: any) {   
    console.log("^^^^selectedDate---",value)
    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);

    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

    this.details.fromDate =  this.datepickerOptions.startDate;
    this.details.toDate = this.datepickerOptions.endDate;

    this.lsData.fromDate = this.datepickerOptions.startDate;
    this.lsData.toDate = this.datepickerOptions.endDate;

    // this.searchBase.fromDate = this.datepickerOptions.startDate ;
    // this.searchBase.toDate = this.datepickerOptions.endDate;

    this.getChart(this.details);
    //-----------graph data starts-------
    if(!this.selectedMotherCompany) {
      this.selectedMotherCompany = 0;
    }
    var tempData = JSON.parse(this.lsService.retrieve('search_base'));
    var fromDate = tempData.fromDate;
    var toDate = tempData.toDate;    
    var brandId = this.lsService.retrieve('active_brand_id');  
      console.log('****Before mCompanyChange API-selectedItemsssss**-',this.selectedItems) 
      //------------------------------------------
      let selectedMPlaces = [];
      if(this.selectedItems.length !=0) {
        this.selectedItems.forEach(function(element) {
          console.log('****Before mCompanyChange one element**-',element) 
          if(element.id){           
            selectedMPlaces.push(element.id);
            console.log('****Before mCompanyChange selectedMPlaces**-',selectedMPlaces) 
          } else {             
              selectedMPlaces.push(element.values[0].marketplace_id);           
          }            
        }.bind(this)
        );
      } else {
        selectedMPlaces.push(0);
      }    
  
      //------------------------------------------         
      this.loaderService.display(true);
      // this.dashboardService.getEvolutionGraph(brandId,fromDate,toDate,this.selectedMotherCompany,0).subscribe( (res) =>{ 
        this.dashboardService.getEvolutionGraph(brandId,fromDate,toDate,this.selectedMotherCompany,selectedMPlaces).subscribe( (res) =>{       
        this.loaderService.display(false);
        // if(res.json().message=='no_data_found'){
          this.multiLineChartData = [];
          // this.selectedItems = [];
          // this.marketplacesList = [];
        // }
        if(res.json().status == "error"){
          this.multiLineChartData = null;
        }
        if(res.json().status == "success"){
          console.log('***mCompanyChange-getEvolutionGraph-response-',res.json().data_found)       
          //---------------------
          var marketplacesListTemp =[];                    
          this.multiLineChartData = res.json().data_found;
          console.log('***After mCompanyChange-getEvolutionGraph-response-multiLineChartData',res.json().data_found)    
          marketplacesListTemp = res.json().data_found;
          
          //--------------------------------------------------------------------------           
          // var fdate =  new Date('2019-02-01');
          // var tdate =  new Date('2019-03-01');
          var fdate =  new Date(fromDate);
          var tdate =  new Date(toDate);
          var firstQuarter = (fdate.getFullYear() * 4) + ((fdate.getMonth() - 1) / 3);
          var secondQuarter = (tdate.getFullYear() * 4) + ((tdate.getMonth() - 1) / 3);
          var diff = 1 + Math.abs(firstQuarter - secondQuarter);
          var startFromDateQuarter = moment(fdate).startOf('quarter');
          var endToDateQuarter = moment(tdate).endOf('quarter');
         
          this.multiLineChartAxisData.startDate = startFromDateQuarter['_d'];
          this.multiLineChartAxisData.endDate = endToDateQuarter['_d'];
          this.multiLineChartAxisData.quartersCount = Math.floor(diff);          
          //--------------------------------------------------------------------------                           
          this.selectedItems = [];
          this.selectedItems = marketplacesListTemp;
          this.marketplacesList = [];          
          // this.marketplacesList = this.mplaceAllLIst;
          // this.selectedMarketplaces = [];

          if(this.mCompanyId==0){        
            var temp= {};
            temp['id']=0;
            temp['name']='All';
            this.marketplacesList = [];
            this.selectedItems = [];               
            // this.selectedItems.push(temp);
            // this.marketplacesList.push(temp);
             //---new edit-----         
             this.selectedItems = res.json().data_found;
             this.marketplacesList = JSON.parse(JSON.stringify(this.allMarketplacesList));         
             //---new edit----- 
          } else {    
              this.selectedItems = marketplacesListTemp;
              this.marketplacesList = this.mplaceAllLIst;
              
              // this.marketplacesList = marketplacesListTemp;
            }  
          //--------------------------------------------------------------------------                
        }else {          
          // this.mCompanyId =0;
          // this.lsService.store('top_market_place', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        } 
      }); 
   
      //-----------graph data ends---------


    
    // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
    // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');
    // this.details.fromDate = this.daterange.start;
    // this.details.toDate = this.daterange.end;
    // this.lsData.fromDate = this.daterange.start;
    // this.lsData.toDate = this.daterange.end;
    // this.getChart(this.details);
    this.refershMotherCompany();
  }

  // Draw charts
  getChart(details) {
    this.lsService. store('search_base', JSON.stringify(this.lsData));
    this.loaderService.display(true);
    console.log('@@@@@details--',details)
    this.successrateService.getData(details).subscribe((res: any) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        //------------------------------------------------------------------
        /* Sort list based on the success rate, if same success-rate occurs then
           sort it based on total links
        */
         let graphData = res.json().success_rate;
         graphData.forEach(element => {           
          console.log(element)
          element.success_rate = Math.round(element.success_rate);
         });
         let sortBy = []; 
         console.log('@@@order by--',details)
         console.log('@@@order by.order by--',details.orderBy)
         if(details.orderBy == 'DESC') {
            sortBy = [{
              prop:'success_rate',
              direction: -1 //descending
            },{
              prop:'total_count',
              direction: -1
            }];
         } 
         if(details.orderBy == 'ASC') {
            sortBy = [{
              prop:'success_rate',
              direction: 1 //ascending
            },{
              prop:'total_count',
              direction: -1
            }];
         }
         graphData.sort(function(a,b) {
          let i = 0, result = 0;
          while(i < sortBy.length && result === 0) {
            // result = sortBy[i].direction*(a[ sortBy[i].prop ].toString() < b[ sortBy[i].prop ].toString() ? -1 : (a[ sortBy[i].prop ].toString() > b[ sortBy[i].prop ].toString() ? 1 : 0));
            result = sortBy[i].direction*(a[ sortBy[i].prop ] < b[ sortBy[i].prop ] ? -1 : (a[ sortBy[i].prop ] > b[ sortBy[i].prop ] ? 1 : 0));
            i++;
          }
          return result;
        })
        console.log('@@@Sorted--data--',graphData)
        //-------------------------------------------------------------------
        // this.data = res.json().success_rate;
        this.data = graphData;       
        if(this.data.length == 0){
          this.data = null;
        }
        this.pieData = res.json().success_rate_detailse;
        // if (this.pieData.length !== 0) {
        //     this.pieData1 = this.pieData[0].data.map(val => val.reported_percentage );
        //     this.pie1Array = this.pieData1;
        //     this.getPieChart(this.pieChart1,this.pieData1,this.pieData[0]);
        //     if(this.pieData[1]){
        //         this.pieData2 = this.pieData[1].data.map(val => val.reported_percentage );
        //         this.pie2Array = this.pieData2;
        //         this.getPieChart(this.pieChart2,this.pieData2,this.pieData[1]);
        //         this.title2 = this.pieData[1].name + ' Group';
        //         this.label2 = true;
        //     }else{
        //       this.pieData2 = [];
        //       this.pie2Array = null;
        //       this.getPieChart(this.pieChart2,this.pieData2,this.pieData[1]);
        //       this.title2 = 'Alibaba Group';
        //       this.label2 = false;
        //     } 
        //     this.title1 = this.pieData[0].name + ' Group';                
        //     this.label1 = true;
            
        // } else {
        //     this.pieData1 = [];
        //     this.pie1Array = null;
        //     this.getPieChart(this.pieChart1,this.pieData1,this.pieData[0]);
        //     this.pieData2 = [];
        //     this.pie2Array = null;
        //     this.getPieChart(this.pieChart2,this.pieData2,this.pieData[1]);
        //     this.label1 = false;
        //     this.label2 = false;
        //     this.title1 = 'Amazon Group';
        //     this.title2 = 'Alibaba Group';
        // }

      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }
      

    });
 }

 refershMotherCompany()
 {
  this.dashboardService.getMotherCompany('market_place',this.details.id,this.lsData.fromDate,this.lsData.toDate).subscribe( (res) =>{
    if(res.json().status == "success"){          
      this.motherCompanyList = res.json().data_found;  

      this.motherCompanyList.forEach(function(element) {
        this.motherCompanies.push(element);            
      }.bind(this)
    );
    }else {          
      this.loaderService.display(false);
      if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
        this.authServiceObj.logout(true);
      }
    } 
  });  
 }
  ngOnInit() {
    console.log('^^^^^oninit coccured----@@@@@@@@@@')
    // Get all marketplaces
    this.getAllmarketplaces();

    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
    //   this.loaderService.display(false);
    //     this.details.id = id;
    //     this.getChart(this.details);
    // });
    this.mCompanyId = 0;
     this.getChart(this.details);

     this.loaderService.display(true);
      this.dashboardService.getMotherCompany('market_place',this.details.id,this.lsData.fromDate,this.lsData.toDate).subscribe( (res) =>{
        // this.loaderService.display(false);
        if(res.json().status == "success"){          
          this.motherCompanyList = res.json().data_found;  

          this.motherCompanyList.forEach(function(element) {
            this.motherCompanies.push(element);            
          }.bind(this)
        );
          // Filter for amazon and alibaba group only
          // if((this.motherCompanyList.find(company => company.name === 'Alibaba Group'))){          
          // this.motherCompanies.push(this.motherCompanyList.find(company => company.name === 'Alibaba Group'));
          // }                    
          // if((this.motherCompanyList.find(company => company.name === 'Amazon'))){          
          // this.motherCompanies.push(this.motherCompanyList.find(company => company.name === 'Amazon'));          
          // } 

        }else {          
          // this.mCompanyId =0;
          // this.lsService.store('top_market_place', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        } 
      });     
          

      //-----------graph data starts-------
    var tempData = JSON.parse(this.lsService.retrieve('search_base'));
    var fromDate = tempData.fromDate;
    var toDate = tempData.toDate;      
    var brandId = this.lsService.retrieve('active_brand_id');    
    
      this.loaderService.display(true);
      this.dashboardService.getEvolutionGraph(brandId,fromDate,toDate,0,0).subscribe( (res) =>{        
        this.loaderService.display(false);
         // if(res.json().message=='no_data_found'){
          this.multiLineChartData = [];
          // this.selectedItems = [];
          // this.marketplacesList = [];
        // }
        if(res.json().status == "error"){
          this.multiLineChartData = null;
        }
        if(res.json().status == "success"){
          // console.log('***EvolutionGraphData-',res.json().data_found)
          this.multiLineChartData = res.json().data_found;   
          this.motherCompanyList = res.json().data_found; 
          
          //--------------------------------------------------------------------------           
          // var fdate =  new Date('2019-02-01');
          // var tdate =  new Date('2021-02-01');
          var fdate =  new Date(fromDate);
          var tdate =  new Date(toDate);
          var firstQuarter = (fdate.getFullYear() * 4) + ((fdate.getMonth() - 1) / 3);
          var secondQuarter = (tdate.getFullYear() * 4) + ((tdate.getMonth() - 1) / 3);
          var diff = 1 + Math.abs(firstQuarter - secondQuarter);
          var startFromDateQuarter = moment(fdate).startOf('quarter');
          var endToDateQuarter = moment(tdate).endOf('quarter');
         
          this.multiLineChartAxisData.startDate = startFromDateQuarter['_d'];
          this.multiLineChartAxisData.endDate = endToDateQuarter['_d'];
          this.multiLineChartAxisData.quartersCount = Math.floor(diff);
          //--------------------------------------------------------------------------          
            
            var temp= {};
            temp['id']=0;
            temp['name']='All';
            this.marketplacesList = [];
            this.selectedItems = [];               
            // this.selectedItems.push(temp);
            // this.marketplacesList.push(temp);    
            //---new edit-----         
            this.selectedItems = res.json().data_found;
            this.marketplacesList = JSON.parse(JSON.stringify(this.allMarketplacesList));         
            //---new edit-----   
          

        }else {          
          // this.mCompanyId =0;
          // this.lsService.store('top_market_place', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        } 
      }); 
   
      //-----------graph data ends---------
     this.evolutionSuccessRateLabel = 'Evolution Of Success Rate';
        //------------------------------multiselect stats-----------------------
         this.selectedItems = [
        // { "id": 0, "itemName": "All" }       
      ];
      this.marketplacesList = [];
    this.settings = {
        text: "Select Marketplaces",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "myclass custom-class",
        limitSelection: 5,
        labelKey: 'name',   
        // primaryKey: 'name',        
        primaryKey: 'id',        
        badgeShowLimit:1,       
        // noDataLabel:'All'
    };
  
  //------------------------------multiselect ends-----------------------

  }


  //------------------------------multiselect functions starts-----------------------
  onItemSelect(item: any) {
    // console.log('selected-item1',item)
    // console.log(this.selectedItems);
    console.log('****Before onItemSelect API-selectedItems-',this.selectedItems)
    //------------------
    this.selectedMarketplaces = [];
    this.selectedItems.forEach(function(element) {
            if(element.id){           
              this.selectedMarketplaces.push(element.id)
            } else {              
              this.selectedMarketplaces.push(element.values[0].marketplace_id)
            }            
          }.bind(this)
    );
    console.log('****Before onItemSelect API-selectedMarketplaces[]-',this.selectedMarketplaces)
    var temp = JSON.parse(this.lsService.retrieve('search_base'));
    var fromDate = temp.fromDate;
    var toDate = temp.toDate;    
    var brandId = this.lsService.retrieve('active_brand_id');     

      this.loaderService.display(true);
      this.dashboardService.getEvolutionGraph(brandId,fromDate,toDate,this.selectedMotherCompany,this.selectedMarketplaces).subscribe( (res) =>{        
        this.loaderService.display(false);
         // if(res.json().message=='no_data_found'){
          this.multiLineChartData = [];
          // this.selectedItems = [];
          // this.marketplacesList = [];
        // }
        if(res.json().status == "error"){
          this.multiLineChartData = null;
        }
        if(res.json().status == "success"){
          console.log('***onItemSelect-EvolutionGraphData-response',res.json().data_found)
          this.multiLineChartData = res.json().data_found;
          this.motherCompanyList = res.json().data_found;  
          //--------------------------------------------------------------------------           
          // var fdate =  new Date('2019-02-01');
          // var tdate =  new Date('2021-02-01');
          var fdate =  new Date(fromDate);
          var tdate =  new Date(toDate);
          var firstQuarter = (fdate.getFullYear() * 4) + ((fdate.getMonth() - 1) / 3);
          var secondQuarter = (tdate.getFullYear() * 4) + ((tdate.getMonth() - 1) / 3);
          var diff = 1 + Math.abs(firstQuarter - secondQuarter);
          var startFromDateQuarter = moment(fdate).startOf('quarter');
          var endToDateQuarter = moment(tdate).endOf('quarter');
         
          this.multiLineChartAxisData.startDate = startFromDateQuarter['_d'];
          this.multiLineChartAxisData.endDate = endToDateQuarter['_d'];
          this.multiLineChartAxisData.quartersCount = Math.floor(diff);         
          //--------------------------------------------------------------------------
             
        }else {          
          // this.mCompanyId =0;
          // this.lsService.store('top_market_place', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        } 
      });    
  }
  OnItemDeSelect(item: any) {
    console.log('selected-item2',item)  
    console.log('mother-company2',this.selectedMotherCompany)  
    if(!this.selectedMotherCompany) {
      this.selectedMotherCompany = 0;
    }
     var index = this.selectedMarketplaces.indexOf(item.id);
      if (index > -1) {
        this.selectedMarketplaces.splice(index, 1);
      }     
      //-----------------------------Graph data----------------------------------
      console.log('****Before onItemSelect API-selectedItems-',this.selectedItems)      
      this.selectedMarketplaces = [];
      this.selectedItems.forEach(function(element) {
              if(element.id){           
                this.selectedMarketplaces.push(element.id)
              } else {              
                this.selectedMarketplaces.push(element.values[0].marketplace_id)
              }            
            }.bind(this)
      );
      
      if(this.selectedItems.length==0){
        this.multiLineChartData = [];        
      }

      console.log('****Before onItemSelect API-selectedMarketplaces[]-',this.selectedMarketplaces)      
      var temp = JSON.parse(this.lsService.retrieve('search_base'));
      var fromDate = temp.fromDate;
      var toDate = temp.toDate;      
      var brandId = this.lsService.retrieve('active_brand_id');     
           
        this.loaderService.display(true);
        this.dashboardService.getEvolutionGraph(brandId,fromDate,toDate,this.selectedMotherCompany,this.selectedMarketplaces).subscribe( (res) =>{          
          this.loaderService.display(false);
           // if(res.json().message=='no_data_found'){
            this.multiLineChartData = [];
            // this.selectedItems = [];
            // this.marketplacesList = [];
          // }
          if(res.json().status == "error"){
            this.multiLineChartData = null;
          }
          if(res.json().status == "success"){
            console.log('***onItemSelect-EvolutionGraphData-response',res.json().data_found)
            this.multiLineChartData = res.json().data_found;
            this.motherCompanyList = res.json().data_found;  
            //--------------------------------------------------------------------------                
            var fdate =  new Date(fromDate);
            var tdate =  new Date(toDate);
            var firstQuarter = (fdate.getFullYear() * 4) + ((fdate.getMonth() - 1) / 3);
            var secondQuarter = (tdate.getFullYear() * 4) + ((tdate.getMonth() - 1) / 3);
            var diff = 1 + Math.abs(firstQuarter - secondQuarter);
            var startFromDateQuarter = moment(fdate).startOf('quarter');
            var endToDateQuarter = moment(tdate).endOf('quarter');
           
            this.multiLineChartAxisData.startDate = startFromDateQuarter['_d'];
            this.multiLineChartAxisData.endDate = endToDateQuarter['_d'];
            this.multiLineChartAxisData.quartersCount = Math.floor(diff); 
            
            //--------------------------------------------------------------------------
               
          }else {          
            // this.mCompanyId =0;
            // this.lsService.store('top_market_place', this.mCompanyId);
            this.loaderService.display(false);
            if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
              //this.toastr.error(this.errorDscrp,this.errorMsg);
              this.authServiceObj.logout(true);
            }
          } 
        }); 
      
      //-----------------------------Graph data ends-----------------------------

  }

  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log('deselect',items);    
    this.multiLineChartData  = null;    
  }
  //------------------------------multiselect functions ends-----------------------
  // Get all market places when All is selected
  getAllmarketplaces() {   
    var brandId = this.lsService.retrieve('active_brand_id');    
    console.log('^^^inside getAllmarketplaces--',brandId) 
    this.loaderService.display(true);          
    this.dashboardService.getAllmarketplaces(brandId).subscribe((res:any)=>{        
      if(res.status == 200) {                      
        this.allMarketplacesList = JSON.parse(res._body).data_found;                     
        this.loaderService.display(false);             
      }
      else {             
        this.loaderService.display(false);    
        // if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }               
    });
  }
  
  //-----mcompany changes starts----
  mCompanyChange(id) {
    console.log('***MC Selected--',id)
    var type='market_place'; 

    //------------------
    if(id == 'm_commerce') {
      //this.selectedMotherCompany = 99999; 
      this.selectedMotherCompany=99999;
      type=id;
    }else if(id == 'e_commerce')
    {
      type=id;
      this.selectedMotherCompany=88888;
      //this.selectedMotherCompany = 99999; 
    } else {
      this.selectedMotherCompany=id;

    }
    //------------------
    //this.selectedMotherCompany = id;  
    // this.lsService.store('top_market_place', id);
    let mCompanySelector ={
      id : '',
      type : 'market_place',
      is_mcommerce : false,
      is_ecommerce : false,
      from_date:'',
      to_date:'',
      brand_ids:''
    }
    // if(id == 'm_commerce'){
    //   this.mCompanySelector ={
    //     id : 5,
    //     type : 'market_place',
    //     is_mcommerce : 'true'
    //   }
    //   this.dashData['mother_company'] = '';
    // }
    // else{
    // -------------------------
    var tempData = JSON.parse(this.lsService.retrieve('search_base'));
    var fromDate = tempData.fromDate;
    var toDate = tempData.toDate;    
    var brandId = this.lsService.retrieve('active_brand_id'); 

    if(id == 'm_commerce') {
      mCompanySelector ={
        id : '5',
        type : 'market_place',
        is_mcommerce : true,
        is_ecommerce : false,
        from_date : fromDate,
        to_date : toDate,
        brand_ids : brandId
      }       
    }else  if(id == 'e_commerce') {
      mCompanySelector ={
        id : '5',
        type : 'market_place',
        is_mcommerce : false,
        is_ecommerce : true,
        from_date : fromDate,
        to_date : toDate,
        brand_ids : brandId
      }       
    } else {
      mCompanySelector ={
        id : id,
        type : 'market_place',
        is_mcommerce : false,
        is_ecommerce : false,
        from_date : fromDate,
        to_date : toDate,
        brand_ids : brandId
      } 
    }

  
    // var rowIndex = this.motherCompanies.findIndex(x => x.productName === oneProduct.product);
    // -------------------------

      //   mCompanySelector ={
      //   id : id,
      //   type : 'market_place',
      //   is_mcommerce : 'false'
      // }      
      this.loaderService.display(true);
      this.dashboardService.getMotherCompanyExt(mCompanySelector).subscribe((res) => {
      console.log('***mCompanyChange-getMarketplaces-response',res.json().data_found)
      this.loaderService.display(false);            
      var marketplacesListTemp =[];            
      marketplacesListTemp = res.json().data_found; 
      this.mplaceAllLIst = marketplacesListTemp; 
          
      //-----------graph data starts-------
   console.log(this.selectedMotherCompany);
      console.log('****Before mCompanyChange API-selectedItems-',this.selectedItems)          
      this.loaderService.display(true);
      this.dashboardService.getEvolutionGraph(brandId,fromDate,toDate,this.selectedMotherCompany,0,).subscribe( (res) =>{        
        this.loaderService.display(false);
        // if(res.json().message=='no_data_found'){
          this.multiLineChartData = [];
          // this.selectedItems = [];
          // this.marketplacesList = [];
        // }
        if(res.json().status == "error"){
          this.multiLineChartData = null;
        }
        if(res.json().status == "success"){
          console.log('***mCompanyChange-getEvolutionGraph-response-',res.json().data_found)       
          //---------------------
          var marketplacesListTemp =[];                    
          this.multiLineChartData = res.json().data_found;
          console.log('***After mCompanyChange-getEvolutionGraph-response-multiLineChartData',res.json().data_found)    
          marketplacesListTemp = res.json().data_found;
          
          //--------------------------------------------------------------------------           
          // var fdate =  new Date('2019-02-01');
          // var tdate =  new Date('2019-03-01');
          var fdate =  new Date(fromDate);
          var tdate =  new Date(toDate);
          var firstQuarter = (fdate.getFullYear() * 4) + ((fdate.getMonth() - 1) / 3);
          var secondQuarter = (tdate.getFullYear() * 4) + ((tdate.getMonth() - 1) / 3);
          var diff = 1 + Math.abs(firstQuarter - secondQuarter);
          var startFromDateQuarter = moment(fdate).startOf('quarter');
          var endToDateQuarter = moment(tdate).endOf('quarter');
         
          this.multiLineChartAxisData.startDate = startFromDateQuarter['_d'];
          this.multiLineChartAxisData.endDate = endToDateQuarter['_d'];
          this.multiLineChartAxisData.quartersCount = Math.floor(diff);          
          //--------------------------------------------------------------------------                           
          this.selectedItems = [];
          this.selectedItems = marketplacesListTemp;
          this.marketplacesList = [];          
          // this.marketplacesList = this.mplaceAllLIst;
          // this.selectedMarketplaces = [];
          if(id==0){        
            var temp= {};
            temp['id']=0;
            temp['name']='All';
            this.marketplacesList = [];
            this.selectedItems = [];               
            // this.selectedItems.push(temp);
            // this.marketplacesList.push(temp);    
            //---new edit-----         
            this.selectedItems = res.json().data_found;
            this.marketplacesList = JSON.parse(JSON.stringify(this.allMarketplacesList));         
            //---new edit-----   

          } else {       
              this.selectedItems = marketplacesListTemp;
              this.marketplacesList = this.mplaceAllLIst;
              if(!this.marketplacesList)
              {
                this.marketplacesList=[];
              }
              console.log(".................");
              console.log(this.marketplacesList);
              
              // this.marketplacesList = marketplacesListTemp;
            }  
          //--------------------------------------------------------------------------                
        }else {          
          // this.mCompanyId =0;
          // this.lsService.store('top_market_place', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        } 
      }); 
   
      //-----------graph data ends---------

      // this.marketplaces : any;
      // this.marketPlaceId = '';  
      // this.apiCallCount = 0;
      // this.pager["total_count"] = 0;
      // this.pager["current_page"] = 1;
      // this.pager["offset"] = 0;
      // this.offSet = 0;
      // this.listData = [];  
      // this.getMarketPlaceData(this.dashData,1);

    })
  }
  //-----mcompany changes ends------
  marketPlaceChange(id){
    console.log('***Selected id:-',id)
  }

  //-------marketPlaceChange ends----
  // onChart1Update(selection) {
  //   selection.append('title')
  //     .text((datum, index) =>  'Success rate : ' + Math.round(this.pieData[0].data[index].success_rate) + '%' );
  // }

  // onChart2Update(selection) {
  //   selection.append('title')
  //     .text((datum, index) =>  'Success rate : ' + Math.round(this.pieData[1].data[index].success_rate) + '%' );
  // }

  getPieChart(elRef: ElementRef, mapData ,fullData){
    let el: HTMLElement;

    el = elRef.nativeElement;
   // this.htmlElement = this.elRef.nativeElement;
    this.host = D3.select(el);
    this.mapData = mapData;
    this.fullData = fullData;


    this.width = el.clientWidth;

      this.height = el.clientHeight;
    if(this.mapData.length !== 0){
      this.setup();
      this.buildSVG();
      this.buildPie();
    }else{
      this.buildErrorMsg();
    }
  }

  private setup(): void {
   // this.radius = this.chartOption['outer_radius'];
    this.radius = 90;
    this.width = (this.radius *4);
    this.height = (this.radius *2.8);
   }
 
   private buildErrorMsg(): void {
     this.host.html('');
     //this.host.html('<h2 class="no_data"> No data found! </h2>');
   }
 
   private buildSVG(): void {
     this.host.html('');
     this.svg = this.host.append('svg')
         .attr('height', this.height)
         .attr('width', this.width)
         .attr('viewBox', '0 0 '+this.width+' '+this.height)
         .append('g')
         .attr('transform', `translate(${this.width / 2},${this.height / 2})`);
   }
 
   private buildPie(): void {
     const pie = D3.pie();
     //const values: number[] = <number[]>this.pieData.map( val => val.percent );
     this.sum = this.mapData.reduce((a, b) => a + b, 0);
     const arcSelection = this.svg.selectAll('.arc')
         .data(pie(this.mapData))
         .enter()
         .append('g')
         .attr('class', 'arc');
     this.populatePie(arcSelection);
   }
 
   private populatePie(arcSelection: D3.Selection<any, any, any, any>): void {
     const innerRadius = 55;
     const outerRadius = this.radius;
     const arc = D3.arc()
         .outerRadius(outerRadius)
         .innerRadius(innerRadius);
     arcSelection.append('path')
         .attr('d', arc)
         .attr('fill', (datum, index) => { const success = Math.round(this.fullData.data[index].success_rate); if(success <= 100 && success > 90){
                  return '#8B008B';
                }else if(success <= 90 && success > 75){
                  return'#C70039';
                }else if(success <= 75 && success > 50){
                  return '#248793';
                }else if(success <= 50 && success > 25){
                  return '#AF5F10';
                }else{
                  return '#F3AA19';
                }
         })
         .attr('stroke-width', 2)
         .attr('stroke', '#fff');
 
     arcSelection.append('text')
         .attr('transform', (d: any) => {
             d.innerRadius = innerRadius;
             d.outerRadius = outerRadius / 2;
             return "translate(" + ( (this.radius + 21) * Math.sin( ((d.endAngle - d.startAngle) / 2) + 
            d.startAngle ) ) + "," + ( -1 * (this.radius + 21) * Math.cos( ((d.endAngle - d.startAngle) / 2) +
             d.startAngle ) ) + ")";
         })
         .text((datum, index) => this.fullData.data[index].url )
         .attr('font-size', 11)
         .attr('fill', '#000')
         .attr("dy", ".35em")
         .style('text-anchor', 'middle');

         arcSelection.append('title')
         .text((datum, index) =>  'Success rate : ' + Math.round(this.fullData.data[index].success_rate) + '%' );
 
        // this.piechartUpdated.emit(arcSelection);
   }

}
