import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import {style, state, animate, transition, trigger} from '@angular/animations';
import { Login } from '../../interfaces/login/login';
import { LocalStorageService } from 'ngx-webstorage';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public login_c: Login;
  public errorMessage: string;
  public submitted: boolean;
  public password_error_flag: boolean;
  public email_error_flag: boolean;
  modalRef: BsModalRef;
  constructor(
    public router: Router,
    public localSt: LocalStorageService,
    private authService: AuthService,
    private bsModalService: BsModalService) { }
  ngOnInit() { }
  onSubmit(form: NgForm) {
    if (form.valid) {
      this.login_c = {
        email: form.value['email'],
        password: form.value['password']
      };
      
      this.authService.authenticate(this.login_c)
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.email_error_flag = false;
          this.password_error_flag = false;
          const user_details = res.userdetails;
          this.localSt.store('web_user_details', user_details);
          this.localSt.store('web_access_token', res.token);
          console.log(res.token)
          // redirect to dashbord
          this.router.navigate(['/']);
        } else {
          if (res.message === 'invalid_email' || res.message === 'Invalid user') {
            this.email_error_flag = true;
          } else if (res.message === 'invalid_password') {
            this.email_error_flag = false;
            this.password_error_flag = true;
          } else {
            this.errorMessage = res.message;
          }
        }
      });
    }
  }
  onLoadForgotPassword() {
    this.modalRef = this.bsModalService.show(ForgotPasswordComponent);
    this.modalRef.content.closeBtnName = 'Close';
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.bsModalService.show(template);
  }

  hideModal() {
    this.modalRef.hide();
  }
}
