import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  activeComponent: string;

  constructor(private route: ActivatedRoute, private loaderService:LoaderService) { }

  ngOnInit() {
    this.loaderService.display(true);
    this.route.data.subscribe(
      (data) => {
        this.loaderService.display(false);
        this.activeComponent = data['activeComponent'];
      }
    );
  }

}
