import { Directive, Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';

@Directive({
  selector: '[appGuagebar]'
})
export class GuagebarDirective {

  @Input () chartOption : {};

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private width: number;
  private height: number;
  private htmlElement: HTMLElement;
  public successRate: any;
  public innerRadius: any;
  public outerRadius: any;
  public arc1: any;
  public widthobj: any;
  public widtht: any;

  constructor(private el: ElementRef) { 
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
  }

  ngOnChanges(){
    this.successRate  =this.chartOption['data'];
    this.width = this.htmlElement.clientWidth;
      this.setup();
      this.buildSVG();
      this.buildGuage();
  }

  private setup(): void {
    this.innerRadius = this.chartOption['inner_radius'];
    this.outerRadius = this.chartOption['outer_radius'];
    this.successRate = this.chartOption['success_rate'];
   // this.width = this.htmlElement.clientWidth;
    this.width = this.chartOption['width'];
    this.height = this.chartOption['height'];
   }

   private buildSVG(): void {
    this.host.html('');
    this.svg = this.host.append("svg")
    .attr("width", this.width)
    .attr("height", this.height)
    .attr('viewBox', '0 0 '+this.width+' '+this.height);
  }

  private buildGuage(): void {
    var fields = [
      {value: this.successRate},
    ];
    const width = this.width;
    if(width > 150 && width <240){ 
      this.height = this.height -10 ;
      this.widtht = this.width/2.2;
    }else{
      this.height = this.height
      this.widtht = this.width/2;
    }
     const height = this.height;
     const widtht = this.widtht;
     this.arc1 = D3.arc()
          .innerRadius(0)
          .outerRadius(this.outerRadius)
          .startAngle(0)
          .endAngle(Math.PI);

      const arc = D3.arc()
          .innerRadius(this.innerRadius)
          .outerRadius(this.outerRadius)
          .startAngle(0)
          .endAngle(function(d) { return (d['value'] *0.01) * Math.PI; });
      
      var field = this.svg.selectAll(".field")
          .data(fields)
        .enter().append("g")
          .attr("transform", function(d, i) { return "translate(" + (widtht) + "," + height + ")"; })
          .attr("class", "field");

      field.append("path")
          .attr("d", this.arc1)
          .attr('fill', '#DCDCDC')
          .attr('transform', 'rotate(-90)');

      var path = field.append("path")
          .attr("class", "path path--foreground")
          .attr('transform', 'rotate(-90)');

      path.transition()
          .duration(1750)
          .attrTween("d", (b) => { const i = D3.interpolate({value: 0}, b); return (t) => {return arc(<any>i(t));}})
          .attr('fill', '#7CA56C');
    if(width > 150){
      var label = field.append("text")
                      .attr('text-anchor', 'middle')
                      .attr('x', 0)
                      .attr('y', -30)
                      .attr('font-size', 36)
                      .attr('fill', '#73879c');

      label.append('tspan')
          .attr('x', 0)
          .attr('font-size', 28)
          .attr('font-family', 'HelveticaNeue-Bold')
          .text('' + this.successRate + '%');
        label.append('tspan')
          .attr('x', 0)
          .attr('dy', 20)
          .attr('font-size', 12)
          .attr('font-weight', 'normal')
          .attr('font-family', 'HelveticaNeue')
          .text('Success Rate');
    }
  }

}
