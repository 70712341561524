import { Injectable } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) { 
    this.headers = new Headers();

		this.headers.append('Content-Type', 'application/json;charset=UTF-8');
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
  }
  
  //get delete data request for the brand
  getDeleteDataRequests(id: number){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get( AppSettings.API_ENDPOINT + '/brand/web-data-delete-request?brand='+id+'',options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
  }

  //send delete data request
  deleteDataRequests(body: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.post( AppSettings.API_ENDPOINT + '/brand/web-data-delete-request',body,options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      });
  }

  //cancel delete request
  cancelDeleteRequest(request_id: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.put( AppSettings.API_ENDPOINT + '/brand/web-data-delete-request',request_id,options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      });
  }

  //change company logo
  changeCompanyImage(body: any){ 
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.put( AppSettings.API_ENDPOINT + '/user/client-logo',body,options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      });
  }
  //recover deleted data
  recoverDeletedData(request: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.put( AppSettings.API_ENDPOINT + '/brand/web-data-delete-request-recover',request,options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      });
  }
}
