import { Injectable } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) { 
    this.headers = new Headers();

		this.headers.append('Content-Type', 'application/json;charset=UTF-8');
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));

  }
  
  //get user detatils
  getUserDataProfile(user:any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get( AppSettings.API_ENDPOINT + '/user/web-user-profile?brand='+user.brand+'&user_id='+user.user+'',options)
    .map(function (res: any) {
			return res || {
				status: "failed",
				message: "Something went wrong"
			};
		}).catch((error:any) => {
			//return Observable.throw(error.json().error || 'Server error occured')
			return Observable.of({status:"error",message:"Server error occured"})
		});
  }

  //change user image
  changeUserImage(body: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.put( AppSettings.API_ENDPOINT + '/user/profile-pic',body,options)
    .map(function(res:any){
			return res || {status: "failed", message: "Somthing went wrong"};
		});;
  }
}
