import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRoute} from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private router: Router, private localSt: LocalStorageService) {
    }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.localSt.retrieve('web_access_token') == null) {
            this.router.navigate(['/login']);
            return false;
        } else {
          return true;
        }
    }
}
