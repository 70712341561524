import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
declare var $:any;
import * as D3 from 'd3';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { MappingService } from '../../services/mapping/mapping.service';
import { LoaderService } from '../../services/loader/loader.service';
import { BrandService } from '../../services/brand/brand.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';
import { AppSettings } from '../../settings.global';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})



export class MappingComponent implements OnInit {

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };
  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
    
  };
  searchBase: {
    brandId: number,
    reportedLink: boolean,
    region: string | number,
    fromDate: string,
    toDate: string
  };
  public lsData:any;
  public map: any;
  public searchDetails: any;
  public localData: any;
  public searchKey: any;
  public chartOption: any;
  public data: any;
  public mapButtonShow: any;
  public dataRequired: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public selectedDateRangeLabel : any;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  count:any=0;

  @ViewChild("printsection") printSectionRef: ElementRef;

  constructor(private lsService: LocalStorageService,
  private mappingService: MappingService,
  private brandService: BrandService,
  private toastr: ToastrService,
  private authServiceObj:AuthService,
  private loaderService:LoaderService) {
    this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel'); 
    this.map = false;
    this.searchKey = '';
    this.localData = JSON.parse(this.lsService.retrieve('search_base'));
    this.lsData = JSON.parse(this.lsService.retrieve('search_base'));
    this.searchBase = {
      brandId: this.lsService.retrieve('active_brand_id'),
      reportedLink: this.lsData.reportedLink,
      region: this.lsData.region,
      fromDate: this.lsData.fromDate,
      toDate: this.lsData.toDate
    };
    this.daterange.start = this.lsData.fromDate;
    this.daterange.end = this.lsData.toDate;
    this.searchDetails = {
      brand_id : this.lsService.retrieve('active_brand_id'),
      fromDate : this.daterange.start,
      toDate : this.daterange.end,
      key : ''
    };
    this.data = [];
    this.mapButtonShow = true;
    this.dataRequired = false;
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  this.lsData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.lsData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;
    this.datepickerOptions.startDate =  new Date(this.lsData.fromDate);
    this.datepickerOptions.endDate = new Date(this.lsData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate)  
   }

  ngOnInit() {
    this.count++;
    console.log(this.count,"Mapping")
    //this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
    //   //this.loaderService.display(false);
    //   this.searchDetails.brand_id = id;
    //   this.getMap();
    //   this.mapButtonShow = true;
    //   this.map = false;
    //   this.dataRequired = false;
    // });
    //this.getMap();
    this.mapButtonShow = true;
    this.map = false;
    this.dataRequired = false;
    
  }

  selectedDate(value: any) {
    
    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel); 
         
    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

    this.searchDetails.fromDate =  this.datepickerOptions.startDate;
    this.searchDetails.toDate =  this.datepickerOptions.endDate;

    this.searchBase.fromDate = this.datepickerOptions.startDate ;
    this.searchBase.toDate = this.datepickerOptions.endDate;
    
    this.getMap();
  }

  getMap(){
    console.log("map")
    this.lsService.store('search_base', JSON.stringify(this.searchBase));
    this.mapButtonShow = false;
    if(this.searchKey == '' || this.searchKey == ' '){
      this.dataRequired = true;
    }else{
      this.dataRequired = false;
      this.searchDetails.key = this.searchKey;
      this.loaderService.display(true);
      this.mappingService.getMappingData(this.searchDetails).subscribe( (res) => {
        this.loaderService.display(false);
        if(res.status == 'success'){
        this.data = res.data;
        this.map = true;
        }else{
          this.data = null;
          this.map = true;
          if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }      
      })
    }
  }

  focusOn(){
    this.mapButtonShow  = true;
    this.map = false;
    this.dataRequired = false;
  }


  print(){

    let printContents = this.printSectionRef.nativeElement.innerHTML;
    let popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            <title>Mapping</title>
            <style>
            //........Customized style.......
            </style>
          </head>
          <body onload="window.print();window.close()">${printContents}</body>
        </html>`
      );
      popupWin.document.close();


  }

// exportPNG() {

//     /*
//     Based off  gustavohenke's svg2png.js
//     gist.github.com/gustavohenke/9073132
//     */
 
//     var svg = document.querySelector( "svg" );
//     var svgData = new XMLSerializer().serializeToString( svg );
 
//     var canvas = document.createElement( "canvas" );
//     var ctx = canvas.getContext( "2d" );
   
 
//     var dataUri = '';
//     dataUri = 'data:image/svg+xml;base64,' + btoa(svgData);
//     // try {
//     //     dataUri = 'data:image/svg+xml;base64,' + btoa(svgData);
//     // } catch (ex) {
 
//     //     // For browsers that don't have a btoa() method, send the text off to a webservice for encoding
//     //     // /* Uncomment if needed (requires jQuery)
//     //     // $.ajax({
//     //     //     url: "http://www.mysite.com/webservice/encodeString",
//     //     //     data: { svg: svgData },
//     //     //     type: "POST",
//     //     //     async: false,
//     //     //     success: function(encodedSVG) {
//     //     //         dataUri = 'data:image/svg+xml;base64,' + encodedSVG;
//     //     //     }
//     //     // })
//     //     // */
 
//     // }
//     var img = document.createElement( "img" );

    
 
//     img.onload = function() {
//         ctx.drawImage( img, 0, 0 );
 
//         try {
 
//             // Try to initiate a download of the image
//             var a = document.createElement("a");
//             a.download = "MDMS_Graph_Export.png";
//             a.href = canvas.toDataURL("image/png");
//             document.querySelector("body").appendChild(a);
//             a.click();
//             document.querySelector("body").removeChild(a);
 
//         } catch (ex) {
 
//             // If downloading not possible (as in IE due to canvas.toDataURL() security issue)
//             // then display image for saving via right-click
 
//             var imgPreview = document.createElement("div");
//             imgPreview.appendChild(img);
//             document.querySelector("body").appendChild(imgPreview);
 
//         }
//     };
 
//     img.src = dataUri;
 
// }
}
