import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../services/auth/auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  public resSucess: boolean;
  public email_error_flag: boolean;
  public errorMessage: any;

  constructor( private authService: AuthService, private modalRef: BsModalRef) {
    this.email_error_flag = false;
    this.errorMessage = '';
  }

  closeModal() {
    this.modalRef.hide();
  }

  onSubmit(form: NgForm) {

    if (form.valid) {
      const request_params: any = {
        email: form.value['user-email'],
      };

      this.authService.forgotPassword(request_params).subscribe((res: any) => {
        if (res.status === 'success') {
          this.email_error_flag = false;
          this.errorMessage = '';
          this.resSucess = true;

        } else {
          if (res.message === 'invalid_email') {
            this.email_error_flag = true;
          } else {
            this.errorMessage = res.message;
          }
        }
      });
    }
  }

  ngOnInit() {
  }

}
