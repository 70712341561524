import { Directive, Input , Output , EventEmitter} from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';
import * as _ from 'lodash';
import { TitleCasePipe } from '@angular/common';

@Directive({
  selector: '[appPiechart]',
  providers : [TitleCasePipe]
})
export class PiechartDirective {

  @Input () chartOption : {};
  @Output() piechartUpdated = new EventEmitter<D3.Selection<any, any, any, any>>();  

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private width: number;
  private height: number;
  private radius: number;
  private htmlElement: HTMLElement;
  private pieData: number[];
  private pieLabel: any[];
  private sum: number;
  public colors:any;

  constructor(private el: ElementRef, private titleCasePipe:TitleCasePipe) { 
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
    this.pieData = [];
    this.sum = 0;
  }

  ngOnChanges(){          
        this.pieData=this.chartOption['data'];        
        this.pieLabel=this.chartOption['labels']; 
        console.log('^^^^this.pieLabel--',this.pieLabel)               
        this.colors=this.chartOption['colors'];
    if(this.pieData) {      
        if(this.pieData.length !== 0){
          this.setup();
          this.buildSVG();
          this.buildPie();
        }else{
          this.host.html('');
         // this.buildErrorMsg();
        }
    }
  }

  private setup(): void {
   this.radius = this.chartOption['outer_radius'];
   this.width = (this.radius *2);
   this.height = (this.radius *2);
  }

  private buildErrorMsg(): void {
    this.host.html('');
    this.host.html('<h2 class="no_data"> No data found! </h2>');
  }

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host.append('svg')
        .attr('height', this.height+40)
        .attr('width', this.width+40)
        .attr('viewBox', '0 0 '+(this.width+30)+' '+(this.height+30))
        .append('g')
        .attr('transform', `translate(${(this.width / 2)+7},${(this.height / 2)+ 20})`);
  }

  private buildPie(): void {
    const pie = D3.pie()
    .startAngle(1.1*Math.PI)
    .endAngle(3.1*Math.PI);
    //const values: number[] = <number[]>this.pieData.map( val => val.percent );
    this.sum = this.pieData.reduce((a, b) => a + b, 0);
    const arcSelection = this.svg.selectAll('.arc')
        .data(pie(this.pieData))
        .enter()
        .append('g')
        .attr('class', 'arc');
    this.populatePie(arcSelection);
  }

  private populatePie(arcSelection: D3.Selection<any, any, any, any>): void {
    const innerRadius = this.chartOption['inner_radius'];
    const outerRadius = this.radius;
    const arc = D3.arc()
        .outerRadius(outerRadius)
        .innerRadius(innerRadius);
    arcSelection.append('path')
        .attr('d', arc)
        .attr('fill', (datum, index) => {
            return this.colors[index];
        })
        .transition().delay(function(d,i) {
          return i * 10; }).duration(1500)
          .attrTween('d', function(d) {
            var i = D3.interpolate(d.startAngle+0.1, d.endAngle);
            return function(t) {
              d.endAngle = i(t); 
              return arc(d)
              }
            }); 
      
      //----edited--------
      //Add title when hoven on the arc    
      arcSelection.append("title").text((datum,index) => {               
        let per = _.round(this.pieData[index] * 100 / this.sum,1);
        if(this.pieLabel[index].hasOwnProperty('name')) {
          return ''+this.titleCasePipe.transform(this.pieLabel[index].name)+' : '+this.pieData[index] +' ('+ per + '%)'
        }         
        if(this.pieLabel[index].hasOwnProperty('url')) {
          return ''+this.titleCasePipe.transform(this.pieLabel[index].url)+' : '+this.pieData[index] +' ('+ per + '%)'  
        }
        if(this.pieLabel[index].hasOwnProperty('social_media_name')) {
          return ''+this.titleCasePipe.transform(this.pieLabel[index].social_media_name)+' : '+this.pieData[index] +' ('+ per + '%)'  
        }
        if(this.pieLabel[index].hasOwnProperty('country')) {
          return ''+this.titleCasePipe.transform(this.pieLabel[index].country)+' : '+this.pieData[index] +' ('+ per + '%)'  
        } 
        if(this.pieLabel[index].hasOwnProperty('type_of_infringement')) {
          return ''+this.titleCasePipe.transform(this.pieLabel[index].type_of_infringement)+' : '+this.pieData[index] +' ('+ per + '%)'  
        }                       
      });
      //----edited--------

    arcSelection.append('text')
        .text((datum, index) =>  { let per = _.round(this.pieData[index] * 100 / this.sum,1);
          if(per > 7){
            return '' + per + '%'
          }
        })
        .attr('font-size', 12)
        .transition()
	      .delay(1000)
        .attr('transform', (d: any,index) => {let per = _.round(this.pieData[index] * 100 / this.sum,1);
          // Don't show percentage on arc if percentage less than 7%
          if(per < 7){
       //   if(innerRadius || per < 10 ){
            // return "translate(" + ( (this.radius + 6) * Math.sin( ((d.endAngle - d.startAngle) / 2) + 
            // d.startAngle ) ) + "," + ( -1 * (this.radius + 6) * Math.cos( ((d.endAngle - d.startAngle) / 2) +
            //  d.startAngle ) ) + ")";
         
        }else{
          d.innerRadius = innerRadius;
          d.outerRadius = outerRadius / 2;
          return 'translate(' + arc.centroid(d) + ')';
        }         
        })
        .attr('fill', (datum, index) =>  {let per = _.round(this.pieData[index] * 100 / this.sum,1); if(per < 7){
          return 'black';
        }else{ return 'white';} } )
        .attr("dy", ".35em")
        .style('text-anchor',(d: any,index) =>{ let per = _.round(this.pieData[index] * 100 / this.sum,1);
          if(per < 7){
            var rads = ((d.endAngle - d.startAngle) / 2) + d.startAngle;
        if ( (rads > 7 * Math.PI / 4 && rads < Math.PI / 4) || (rads > 3 * Math.PI / 4 && rads < 5 * Math.PI / 4) ) {
          return "middle";
        } else if (rads >= Math.PI / 4 && rads <= 3 * Math.PI / 4) {
          return "start";
        } else if (rads >= 5 * Math.PI / 4 && rads <= 7 * Math.PI / 4) {
          return "end";
        } else {
          return "middle";
        }
          }else{
            return "middle";
          }
        });

        this.piechartUpdated.emit(arcSelection);        
  }
  

}
