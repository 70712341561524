import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../settings.global';
import {Observable} from 'rxjs/Rx';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DataAnalysisService {

  public headers: Headers;

  constructor( private http: Http, private localSt: LocalStorageService) { 
    this.headers = new Headers();

		this.headers.append('Content-Type', 'application/json;charset=UTF-8');
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
  }

  getDataAnalysis(body: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: this.headers});
    let queryString: string;
    queryString = 'brand_id=' + body.brand_id + '&fromDate=' + body.fromDate +'&toDate='+body.toDate+ '';
     return this.http.get( AppSettings.API_ENDPOINT + '/analysis/data?'+ queryString,options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });
  }

  getScreenShotData(body: any){   
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: this.headers});
    let queryString: string;
    queryString = 'type='+body.type+'&key_value=' + encodeURIComponent(body.seller_name) + '&brand_id=' + body.brand_id + '&fromDate=' + body.fromDate +'&toDate='+body.toDate+ ''; 
    return this.http.get( AppSettings.API_ENDPOINT + '/analysis/seller?'+ queryString,options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });

  }

  //sort the  data
  sortUrlData(requestData, orderBy, order) {
		return _.orderBy(requestData, [orderBy], [order]);
   }
}
