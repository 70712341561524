import { Injectable } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public headers: Headers;

  mpDataChanged = new Subject<{data: any[], successRate: number}>();

  smDataChanged = new Subject<{data: any[], successRate: number}>();

  swDataChanged = new Subject<{data: any[], successRate: number}>();

  // tslint:disable-next-line:max-line-length
  mapDataChanged = new Subject<{country: string, code: string, source_counts: { market_place: number, social_media: number, stand_alone: number, total: number}}[]>();

  constructor(private http: Http, private localSt: LocalStorageService) {
    this.headers = new Headers();

		this.headers.append('Content-Type', 'application/json;charset=UTF-8');
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
  }

  getCountries() {
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
     return this.http.get( AppSettings.API_ENDPOINT + '/dashboard/country',options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });
  }

  getDashboardData(searchBase: {
    brandId: string,
    reportedLink: boolean,
    region: string | number,
    fromDate: string,
    toDate: string
  }) {

    let queryString: string;

    if (typeof searchBase.region === 'number') {

      // tslint:disable-next-line:max-line-length
      queryString = 'brand=' + searchBase.brandId + '&reportedLink=' + searchBase.reportedLink + '&country_id=' + searchBase.region + '&fromDate=' + searchBase.fromDate + '&toDate=' + searchBase.toDate + '';

    } else if ( typeof searchBase.region === 'string' && searchBase.region !== 'all' ) {

      // tslint:disable-next-line:max-line-length
      queryString = 'brand=' + searchBase.brandId + '&reportedLink=' + searchBase.reportedLink + '&region="' + searchBase.region.trim().replace('&', '%26') + '"&fromDate=' + searchBase.fromDate + '&toDate=' + searchBase.toDate + '';

    } else {

      // tslint:disable-next-line:max-line-length
      queryString = 'brand=' + searchBase.brandId + '&reportedLink=' + searchBase.reportedLink + '&fromDate=' + searchBase.fromDate + '&toDate=' + searchBase.toDate + '';

    }

    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});

    return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/all?' + queryString, options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });

  }
  //get all the mothercompanies
  getMotherCompany(mtype:any,brand:any,_fromDate:any,_toDate:any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
     return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/mother-company-with-data?type='+mtype+'&brand='+brand+'&fromDate='+_fromDate+'&toDate='+_toDate, options)
    //return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/mother-company?type='+mtype+'&brand='+brand, options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });
  }
  
  //get the marketplace according to mothercompany
  getMotherCompanyExt(mCompany:any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: this.headers});
    
    let metype='';

    if(mCompany.is_mcommerce)
    {
      metype='m_commerce';
    }
    else if (mCompany.is_ecommerce)
    {
      metype='e_commerce';
    }
    else
    {
      metype='market_place';
    }
    //return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/sub-company?type=' + mCompany.type + '&mother_company='+mCompany.id+'&is_mcommerce='+mCompany.is_mcommerce + '&is_ecommerce='+ mCompany.is_ecommerce, options)
     return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/sub-company-with-data?type=' 
     + mCompany.type + '&mother_company='
     + mCompany.id+'&is_mcommerce='
    // + mCompany.is_mcommerce+'&is_ecommerce='
     + metype +'&from_date='
     + mCompany.from_date+'&to_date='
     + mCompany.to_date +'&brand_ids='
     + mCompany.brand_ids
     , options
     )
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });
  }

  //get the data for evolution graph
  getEvolutionGraph(brandId:any,fromDate:any,toDate:any,motherComapanyId:any,marketplacesList:any){   
  //  console.log('***Service--brandId',brandId)
  //  console.log('***Service--fromDate',fromDate)
  //  console.log('***Service--toDate',toDate)
  //  console.log('***Service--motherComapanyId',motherComapanyId)
  //  console.log('***Service--marketplacesList',marketplacesList)
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/evolution-successrate?brand_id=' + brandId + '&from_date='+fromDate+'&to_date='+toDate+'&mother_company_id='+motherComapanyId+'&marketplaces_list='+marketplacesList, options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });
  }
  
  //get the marketplace data
  getMarketPlaceData(data:any){


    let queryString: string;
    if(data.market_place){
      if(data.mother_company){
        queryString = 'brand=' + data.brand + '&mother_company=' + data.mother_company + '&market_place=' + data.market_place + '&fromDate=' + data.fromDate + '&toDate=' +
         data.toDate +'&searchkey=' + data.searchkey + '&limit=' + data.limit + '&offset=' + data.offset+'';
      }else{
        queryString = 'brand=' + data.brand + '&market_place=' + data.market_place + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate +
        '&searchkey=' + data.searchkey + '&limit=' + data.limit + '&offset=' + data.offset+ '';
      }      
    }else{
      if(data.mother_company ||data.mother_company==0 ){
        queryString = 'brand=' + data.brand + '&mother_company=' + data.mother_company + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate
        +'&searchkey=' + data.searchkey + '&limit=' + data.limit + '&offset=' + data.offset + '';
      }else{
        queryString = 'brand=' + data.brand  + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate
        +'&searchkey=' + data.searchkey + '&limit=' + data.limit + '&offset=' + data.offset + '';
      }      
    }

    queryString = queryString+'&type='+data.dash_board_data_type;

    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/market-place?' + queryString, options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });

  }
  //sort the  data
  sortListData(requestData, orderBy, order) {
		return _.orderBy(requestData, [orderBy], [order]);
   }
  
  //get social media data
  getSocialMediaData(data:any){
    let queryString: string;
    if(data.social_media){
      queryString = 'brand=' + data.brand + '&mother_company=' + data.mother_company + '&social_media=' + data.social_media +
        '&fromDate=' + data.fromDate + '&toDate=' +data.toDate +'&searchkey=' + data.searchkey +
        '&limit=' + data.limit + '&offset=' + data.offset+'';      
    }else{
        queryString = 'brand=' + data.brand + '&mother_company=' + data.mother_company + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate
        +'&searchkey=' + data.searchkey + '&limit=' + data.limit + '&offset=' + data.offset + '';  
    }
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get( AppSettings.API_ENDPOINT + '/dashboard/social-media?' + queryString, options )
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
    }

  //get standalone data
  getStandaloneData(data:any){
    let queryString: string;
    queryString = 'brand=' + data.brand + '&country_id=' + data.country_id + '&fromDate=' + data.fromDate + 
    '&toDate=' +data.toDate +'&searchkey=' + data.searchkey +'&limit=' + data.limit + '&offset=' + 
    data.offset+''; 
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});     
    return this.http.get( AppSettings.API_ENDPOINT + '/dashboard/standalone-website?' + queryString, options )
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
  }
  getRelevantCountries(brand:any,from_date,to_date) {
    console.log(brand)
    let headers = new Headers();
    this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
    this.headers.append('Accept', 'application/json');
    let options = new RequestOptions({headers: this.headers});
     return this.http.get( AppSettings.API_ENDPOINT + '/dashboard/relevant-country?brand='+brand+'&from_date='+from_date+'&to_date='+to_date,options)
        .map(function (res: any) {
          return res || {
            status: "failed",
            message: "Something went wrong"
          };
        }).catch((error:any) => {
          //return Observable.throw(error.json().error || 'Server error occured')
          return Observable.of({status:"error",message:"Server error occured"})
        });
  }
    //Update highlight status
    updateHighlightStatus(body:any) {    
      let headers = new Headers();
      this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
      this.headers.append('Accept', 'application/json');
      let options = new RequestOptions({headers: this.headers});    
      return this.http.post(AppSettings.API_ENDPOINT +'/dashboard/highlight',body, options)
          .map(function (res: any) {
            return res || {
              status: "failed",
              message: "Something went wrong"
            };
          }).catch((error:any) => {
            //return Observable.throw(error.json().error || 'Server error occured')
            return Observable.of({status:"error",message:"Server error occured"})
          });
    }
     

    //get all marketplaces
    getAllmarketplaces(body:any) {    
      let headers = new Headers();
      this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
      this.headers.append('Accept', 'application/json');
      let options = new RequestOptions({headers: this.headers});  
      // let body = {type:'market_place'};  
      return this.http.post(AppSettings.API_ENDPOINT +'/dashboard/allmarketplaces',body, options)
          .map(function (res: any) {
            return res || {
              status: "failed",
              message: "Something went wrong"
            };
          }).catch((error:any) => {
            //return Observable.throw(error.json().error || 'Server error occured')
            return Observable.of({status:"error",message:"Server error occured"})
          });
    }

}
