import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class SuccessrateService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) {
    this.headers = new Headers();

    this.headers.append('Content-Type', 'application/json;charset=UTF-8');
    this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));

   }
 
   //Market place successrate data
   getData(body: { brandId: number,
    id: number,
    region: string | number,
    fromDate: string,
    toDate: string
    orderBy: string}) {
    let queryString: string;
    
    //  if (typeof body.region === 'number'){
    //   queryString = 'brand=' + body.id + '&country='+body.region+'&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
       
    //  }else if( typeof body.region === 'string' && body.region !== 'all' ){
    //   queryString = 'brand=' + body.id + '&region=' + body.region +'&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';

    //  }else{
    //   queryString = 'brand=' + body.id + '&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
    //  }

     queryString = 'brand=' + body.id + '&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
    
     let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers}); 
    return this.http.get(AppSettings.API_ENDPOINT + '/success-rate/market-place?'+queryString, options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
  }

  //Social media successrate data
  getSocialMediaData(body: { brandId: number,
    id: number,
    region: string | number,
    fromDate: string,
    toDate: string
    orderBy: string}) {
    let queryString: string;
    //  if (typeof body.region === 'number'){
    //   queryString = 'brand=' + body.id + '&country='+body.region+'&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
       
    //  }else if( typeof body.region === 'string' && body.region !== 'all' ){
    //   queryString = 'brand=' + body.id + '&region=' + body.region +'&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';

    //  }else{
    //   queryString = 'brand=' + body.id + '&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
    //  }

     queryString = 'brand=' + body.id + '&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';

     let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers}); 
   
    return this.http.get(AppSettings.API_ENDPOINT + '/success-rate/social-media?'+queryString, options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
  }

  //Standalone successrate data
  getStandaloneData(body: { brandId: number,
    id: number,
    region: string | number,
    fromDate: string,
    toDate: string
    orderBy: string}) {
    let queryString: string;
    //  if (typeof body.region === 'number'){
    //   queryString = 'brand=' + body.id + '&country='+body.region+'&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
       
    //  }else if( typeof body.region === 'string' && body.region !== 'all' ){
    //   queryString = 'brand=' + body.id + '&region=' + body.region +'&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';

    //  }else{
    //   queryString = 'brand=' + body.id + '&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
    //  }

    queryString = 'brand=' + body.id + '&orderBy='+body.orderBy+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '';
   
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get(AppSettings.API_ENDPOINT + '/success-rate/standalone-website?'+queryString, options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
  }

}
