import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SuccessrateService } from '../../services/successrate/successrate.service';
import * as moment from 'moment';
import * as D3 from 'd3';
import { LocalStorageService } from 'ngx-webstorage';
import { BrandService } from '../../services/brand/brand.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../../settings.global';

@Component({
  selector: 'app-success-rate-standalone-websites',
  templateUrl: './success-rate-standalone-websites.component.html',
  styleUrls: ['./success-rate-standalone-websites.component.scss']
})
export class SuccessRateStandaloneWebsitesComponent implements OnInit {

  @ViewChild('mapContainer') mapContainer: ElementRef;

  public barchartOption:object;
  public data:any;
  public details:any;
  public lsData:any;
  public order:any;
  public mapData: any;
  public standAloneData: any;
  private parentNativeElement: any;
  public success: any;
  public top: any;
  public left:any;
  public chartOption: any;
  public successRate: any;
  public show: boolean;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public selectedDateRangeLabel : any;

  constructor(private successrateService: SuccessrateService,
    private lsService: LocalStorageService,
    private element: ElementRef,
    private loaderService:LoaderService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private brandService: BrandService) { 
      this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel'); 
      this.parentNativeElement = element.nativeElement;
      this.data = [];
      this.details = [];
      // this.order = 'ASC'
      this.order = 'DESC'
      this.lsData = JSON.parse(this.lsService.retrieve('search_base'));
      this.details = {
        id:this.lsService.retrieve('active_brand_id'),
        region:this.lsData.region,
        fromDate:this.lsData.fromDate,
        toDate:this.lsData.toDate,
        orderBy:this.order  
      }
      
      this.daterange.start = this.lsData.fromDate;
      this.daterange.end = this.lsData.toDate;
      this.successRate = 0;

      // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]

    // let fromDateLocal =  this.lsData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.lsData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;
    this.datepickerOptions.startDate =  new Date(this.lsData.fromDate);
    this.datepickerOptions.endDate = new Date(this.lsData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate) 
  }

  ngOnInit() {
    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
    //   this.loaderService.display(false);
    //   this.details.id = id;
    //   this.getStandaloneData(this.details);
    //   this.drawMap(this.mapData,this.details);
    // });
    this.getStandaloneData(this.details);
    console.log('**this.mapData-',this.mapData)
      this.drawMap(this.mapData,this.details);
  }

  getStandaloneData(details){
    this.lsService. store('search_base', JSON.stringify(this.lsData));
    this.loaderService.display(true);
    this.successrateService.getStandaloneData(details).subscribe((res:any)=>{
      
      this.loaderService.display(false);
      if(res.json().status == "success"){
        /* Sort list based on the success rate, if same success-rate occurs then
           sort it based on total links
        */

        // this.data = res.json().success_rate_per_country_with_orderby;
        let graphData = res.json().success_rate_per_country_with_orderby;
        graphData.forEach(element => {           
          console.log(element)
          element.success_rate = Math.round(element.success_rate);
         });
         let sortBy = []; 
         if(details.orderBy == 'DESC') {
          sortBy = [{
            prop:'success_rate',
            direction: -1 //descending
          },{
            prop:'total_count',
            direction: -1
          }];
       } 
       if(details.orderBy == 'ASC') {
          sortBy = [{
            prop:'success_rate',
            direction: 1 //ascending
          },{
            prop:'total_count',
            direction: -1
          }];
       }
       graphData.sort(function(a,b) {
        let i = 0, result = 0;
        while(i < sortBy.length && result === 0) {
          // result = sortBy[i].direction*(a[ sortBy[i].prop ].toString() < b[ sortBy[i].prop ].toString() ? -1 : (a[ sortBy[i].prop ].toString() > b[ sortBy[i].prop ].toString() ? 1 : 0));
          result = sortBy[i].direction*(a[ sortBy[i].prop ] < b[ sortBy[i].prop ] ? -1 : (a[ sortBy[i].prop ] > b[ sortBy[i].prop ] ? 1 : 0));
          i++;
        }
        return result;
      })
      console.log('@@@Sorted--data--',graphData)
      this.data = graphData;    
        console.log(this.data)
        if(this.data.length == 0){
          this.data = null;
        }
        this.success = res.json().success_rate;
        if(this.success[0].disabled == 0 && this.success[0].reported ==0){
          //this.drawGuage(0);
          this.successRate = 0;

        }else{
        // this.drawGuage(Math.round((this.success[0].disabled/this.success[0].reported) * 100));
        this.successRate = Math.round((this.success[0].disabled/this.success[0].reported) * 100);
        } 
       }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }
           
    });
  }

  onChange(order){
    this.details.orderBy = order;
    this.getStandaloneData(this.details);
  }

  //Change date range
  selectedDate(value: any) {
    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel); 

    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

    this.details.fromDate = this.datepickerOptions.startDate;
    this.details.toDate = this.datepickerOptions.endDate;

    this.lsData.fromDate = this.datepickerOptions.startDate;
    this.lsData.toDate = this.datepickerOptions.endDate;

    // this.searchBase.fromDate = this.datepickerOptions.startDate ;
    // this.searchBase.toDate = this.datepickerOptions.endDate;


    this.getStandaloneData(this.details);
    this.drawMap(this.mapData,this.details);
    

    // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
    // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');

    // this.details.fromDate = this.daterange.start;
    // this.details.toDate = this.daterange.end;

    // this.lsData.fromDate = this.daterange.start;
    // this.lsData.toDate = this.daterange.end;

    // this.getStandaloneData(this.details);
    // this.drawMap(this.mapData,this.details);    
  }

  onMapInit(selection) {
    this.mapData = selection.countries;
    console.log('***-',this.mapData)

    this.drawMap(this.mapData,this.details);
    const svg = selection.svg;
    const svgHeight = svg.attr('height');
    const svgWidth = svg.attr('width')
    const zoom = D3.zoom()
      .scaleExtent([1, 1.5])
      .translateExtent([[-100, -100], [svgWidth + 100, svgHeight + 100]])
      .on('zoom', zoomed);

    function zoomed() {
      svg.attr('transform', D3.event.transform);
    }
    svg.call(zoom);
  }

  drawMap(country,details){
    //const con = selection.countries;
    var tooltip = D3.select("div.tooltip");
    this.loaderService.display(true);
    this.successrateService.getStandaloneData(details).subscribe((res: any) => {
      this.loaderService.display(false);
      this.standAloneData=res.json().success_rate_per_country;
      function getSuccess(code,data){
        if(data.length){
          for(let i=0; i < data.length; i++){
            if(data[i].code == code){
              return Math.round(data[i].success_rate) +'%';
            }
          }
          return 'No data';
        }else{
          return 'No data';
        }
        
      }
      country.attr('fill', (d) => {
        return this.getColor(d['properties'].iso_a2,this.standAloneData);
        })
        .on("mouseover",(d) =>{
          this.show = true;
           return tooltip.style("hidden", false).html('<strong>'+d['properties'].name+': </strong>'+ getSuccess(d['properties'].iso_a2,this.standAloneData));
         })
         .on("mousemove",(d) =>{
           this.show = true;
          this.top = this.mapContainer.nativeElement.getBoundingClientRect().top;
          this.left = this.mapContainer.nativeElement.getBoundingClientRect().left;
          if(D3.event.pageY < 1000){
            this.top = 100;
          }else{
          this.top =  this.top + 900;
          this.left = this.left + 5;
          }
          if (this.standAloneData.some((item) => item.code == d['properties'].iso_a2)) {
            tooltip.classed("hidden", false)
                .style("top", (D3.event.pageY - this.top ) + "px")
                .style("left", (D3.event.pageX - this.left) + "px")
                .html('<strong>'+d['properties'].name+': </strong>'+ getSuccess(d['properties'].iso_a2,this.standAloneData));
            
        }
         
         })
       .on("mouseout",function(d,i){
         this.show = false;
         tooltip.classed("hidden", true);
         });          
    });        
  }

  getColor(code,standAlone){  
    if(standAlone){
      for(let i = 0; i < standAlone.length; i++){
        if(standAlone[i].code == code){
          if(standAlone[i].success_rate <= 100 && standAlone[i].success_rate > 90){
           // return '#ed3b2b';  
            return '#006400'; 
          }else if(standAlone[i].success_rate <= 90 && standAlone[i].success_rate > 75){
           // return '#fc7e34';
            return '#32CD32';
          }else if(standAlone[i].success_rate <= 75 && standAlone[i].success_rate > 50){
           // return '#fdca3f';
           return '#90EE90';
          }else if(standAlone[i].success_rate <= 50 && standAlone[i].success_rate > 25){
            //return '#fbe844';
          //  return '#FFFF00';
          return '#ffd700';
          }else if(standAlone[i].success_rate <= 25 && standAlone[i].success_rate > 0){
            return '#F1681F';
          }else if(standAlone[i].success_rate == 0){
            return '#F1291F';
          }
          else{
            return '#d4d4d4';
          }            
        }     
      }
      return '#d4d4d4';
    }else{
      return '#d4d4d4';
    }
 }

//  drawGuage(successRate) {

//   let d3ParentElement: D3.Selection< any, any, any, any>;

//   if (this.parentNativeElement !== null) {

//     d3ParentElement = D3.select(this.parentNativeElement); // <-- use the D3 select method

//     const svg = d3ParentElement.select('svg');

//     const g = svg.append('svg:g')
//                //.attr("transform", function(d, i) { return "translate(" + (i * 2 + 1.25) / 6.5 * this.width + "," + this.height / 2 + ")"; })
    
//                  .attr('transform', 'translate(70, 110)');

//     //const arc = D3.arc();

//     const path = D3.arc()
//               .innerRadius(65)
//               .outerRadius(75)
//               .startAngle(0)
//               .endAngle(Math.PI);

//     const path2 = D3.arc()
//               .innerRadius(65)
//               .outerRadius(75)
//               .startAngle(0)
//               .endAngle(function(d) { return (successRate * 0.01 * Math.PI) });
//     // const path2 = arc({
//     //     startAngle: 0,
//     //     endAngle: successRate * 0.01 * Math.PI,
//     //     innerRadius: 65,
//     //     outerRadius: 75
//     //   });

//     g.append('path')
//           .attr('d', path)
//           .attr('fill', '#DCDCDC') 
//           .attr('transform', 'rotate(-90)');
   
//     g.append('path')    
//     //.attr('d', path2)
//     .transition()
//       .duration(3750)
//       .attrTween("d", (b) => { var i = D3.interpolate({value: 0}, b); return function(t) {return path2(i(t));}})
//       .attr('fill', '#7CA56C')
//       .attr('class', 'guage-value')
//       .attr('transform', 'rotate(-90)');
    
//       // .attrTween("d", function (b) { 
//       //   var start = {startAngle: 0, endAngle: 0,innerRadius: 65,outerRadius: 75};
//       //   var interpolate = D3.interpolate(start, b);
//       //   return function (t) {
//       //     return arc(interpolate(t));
//       //   };
//       // }) 

//     // .transition()
//     //     .duration(750)
//     //     .attrTween('d', function arcTween(b) { var i = D3.interpolate({value: 50}, b); return function(t) {return arc(i(t));}} )
//     // .attr('fill', '#7CA56C')
//     // .attr('class', 'guage-value')
//     // .attr('transform', 'rotate(-90)');
  
//     const text = g.append('text')
//                   .attr('text-anchor', 'middle')
//                   .attr('x', 0)
//                   .attr('y', -30)
//                   .attr('font-size', 36)
//                   .attr('fill', '#73879c');
//     text.append('tspan')
//       .attr('x', 0)
//       .attr('font-size', 28)
//       .attr('font-family', 'HelveticaNeue-Bold')
//       .text('' + successRate + '%');
//     text.append('tspan')
//       .attr('x', 0)
//       .attr('dy', 20)
//       .attr('font-size', 12)
//       .attr('font-weight', 'normal')
//       .attr('font-family', 'HelveticaNeue')
//       .text('Success Rate');
//   }
// }



}
