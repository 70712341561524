import { Component, OnInit, ElementRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { D3Service, D3, Selection } from 'd3-ng2-service';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { HomeService } from '../../../services/home/home.service';
import { BrandService } from '../../../services/brand/brand.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../../../settings.global';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss'],
  animations: [
    trigger("reportedLinkTrigger", [
      // define states
      state("on", style({ opacity: 0.5 })),
      state("off", style({ opacity: 1 }))
    ]),
    trigger("successRateTrigger", [
      // define states
      state("on", style({ opacity: 0.5 })),
      state("off", style({ opacity: 1 }))
    ]),
    trigger("disabledLinkTrigger", [
      // define states
      state("on", style({ opacity: 0.5 })),
      state("off", style({ opacity: 1 }))
    ])
  ]
})
export class HomeDashboardComponent implements OnInit {
  d3: D3;
  private parentNativeElement: any;
  public successRate;
  public brandId;
  public totalReported;
  public totalDisabled;
  public reportedSm;
  public disabledSm;
  public reportedMp;
  public disabledMp;
  public reportedSw;
  public disabledSw;
  public mpReportedPercentage;
  public mpDisabledPercentage;
  public smReportedPercentage;
  public smDisabledPercentage;
  public swReportedPercentage;
  public swDisabledPercentage;
  public lsData:any;
  public reportedStatus: any;
  public successStatus: any;
  public disabledStatus: any;
  public reported:any;
  public disabled:any;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public selectedDateRangeLabel : any;

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'right',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };
  public searchBase: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;

  constructor(
    private element: ElementRef,
    private d3Service: D3Service,
    private lsService: LocalStorageService,
    private brandService: BrandService,
    private loaderService:LoaderService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private homeService: HomeService
  ) {
    this.d3 = d3Service.getD3(); // <-- obtain the d3 object from the D3 Service
    this.parentNativeElement = element.nativeElement;
    this.totalReported = 0;
    this.totalDisabled = 0;
    this.successRate = 0;
    this.reportedMp = 0;
    this.reportedSm = 0;
    this.reportedSw = 0;
    this.disabledMp = 0;
    this.disabledSm = 0;
    this.disabledSw = 0;
    this.smReportedPercentage = 0;
    this.mpReportedPercentage = 0;
    this.swReportedPercentage = 0;
    this.mpDisabledPercentage = 0;
    this.smDisabledPercentage = 0;
    this.mpDisabledPercentage = 0;
    this.lsData = JSON.parse(this.lsService.retrieve('search_base'));

    this.searchBase = {
      brandId: this.lsService.retrieve('active_brand_Id'),
      reportedLink: (this.lsData)?this.lsData.reportedLink:true,
      region: (this.lsData)?this.lsData.region:'all',
      fromDate: (this.lsData)?this.lsData.fromDate:this.daterange.start,
      toDate: (this.lsData)?this.lsData.toDate:this.daterange.end
    };
    console.log("The search base is :**************************",this.searchBase);
    this.daterange.start =(this.lsData)?this.lsData.fromDate:this.daterange.start;
    this.daterange.end = (this.lsData)?this.lsData.toDate:this.daterange.end;

    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  (this.lsData)?this.lsData.fromDate.split('-'):moment().startOf('year');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  (this.lsData)?this.lsData.toDate.split('-'):moment();
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
  
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal; 


    let fromDateLocal =  (this.lsData)?new Date(this.lsData.fromDate):moment().startOf('year');
    let toDateLocal =  (this.lsData)?new Date(this.lsData.toDate):moment();
    this.datepickerOptions.startDate =  fromDateLocal;
    this.datepickerOptions.endDate   =  toDateLocal;     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate)  
  }

  getPercent( count: number, base: number ) {
    return Math.round((count / base) * 100);
  }

  selectedDate(value: any) {
    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);
    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');
    this.searchBase.fromDate = this.datepickerOptions.startDate ;
    this.searchBase.toDate = this.datepickerOptions.endDate;
    this.getDashboardData();
  }

  ngOnInit() {

    // if(this.lsData.reportedLink == 'false'){
    //   this.reportedStatus = 'on';
    //   this.successStatus = 'on';
    //   this.disabledStatus = 'off';
    //   this.reported = false;
    //   this.disabled = true;
    // }else{
    //   this.reportedStatus = 'off';
    //   this.successStatus = 'off';
    //   this.disabledStatus = 'on';
    //   this.reported = true;
    //   this.disabled = false;
    // }
    
    // if(this.lsData.reportedLink == 'false'){
    //   this.reportedStatus = 'on';
    //   this.successStatus = 'on';
    //   this.disabledStatus = 'off';
    //   this.reported = false;
    //   this.disabled = true;
     
    // }else{
    //   this.reportedStatus = 'off';
    //   this.successStatus = 'off';
    //   this.disabledStatus = 'on';
    //   this.reported = true;
    //   this.disabled = false;
    // }

    this.loaderService.display(true);
    this.brandService.brandChanged
      .subscribe(
        (id) => {
          // console.log("The search id is: ",id);
          this.loaderService.display(false);
          this.searchBase.brandId = id;
          //this.getDashboardData();
        }
      );
    this.getDashboardData();
  }

  getDashboardData(){
   
    this.lsService.store('search_base', JSON.stringify(this.searchBase));
    console.log("getDashboardData: ", this.lsService.retrieve('search_base'));
    this.loaderService.display(true);
    this.homeService.getDashBoardData(this.searchBase)
            .subscribe(
              (res) => {
                console.log("The response is: ",res);
                console.log("**************************************");
                this.loaderService.display(false);
                if(res.json().status == "success"){                  
                  const data = res.json();
                  if (data.dashboard != null) {

                    if ( data.dashboard.total_reported_links == null ) {

                      this.totalReported = 0;
                      this.totalDisabled = 0;

                      this.successRate = 0;

                    // this.drawGuage();

                      this.reportedMp = 0;
                      this.reportedSm = 0;
                      this.reportedSw = 0;

                      this.disabledMp = 0;
                      this.disabledSm = 0;
                      this.disabledSw = 0;

                      this.smReportedPercentage = 0;
                      this.mpReportedPercentage = 0;
                      this.swReportedPercentage = 0;

                      this.mpDisabledPercentage = 0;
                      this.smDisabledPercentage = 0;
                      this.mpDisabledPercentage = 0;


                    } else if ( data.dashboard.total_disabled_links == null ) {

                      this.totalReported = data.dashboard.total_reported_links.total_count;

                      this.totalDisabled = 0;

                      this.successRate = 0;

                    // this.drawGuage();

                      this.reportedMp = data.dashboard.total_reported_links.market_place;
                      
                      this.reportedSm = data.dashboard.total_reported_links.social_media;
                      this.reportedSw = data.dashboard.total_reported_links.standalone;

                      this.disabledMp = 0;
                      this.disabledSm = 0;
                      this.disabledSw = 0;

                      this.smReportedPercentage = this.getPercent(this.reportedSm, this.totalReported);
                      this.mpReportedPercentage = this.getPercent(this.reportedMp, this.totalReported);
                      this.swReportedPercentage = this.getPercent(this.reportedSw, this.totalReported);

                      this.mpDisabledPercentage = 0;
                      this.smDisabledPercentage = 0;
                      this.mpDisabledPercentage = 0;

                    } else {
                      this.totalReported = data.dashboard.total_reported_links.total_count;
                      this.totalDisabled = data.dashboard.total_disabled_links.total_count;
                      if(this.totalReported == 0){
                        this.successRate = 0;
                      }else{
                        this.successRate = this.getPercent(this.totalDisabled, this.totalReported);
                      }
                    //  this.drawGuage();

                      this.reportedMp = data.dashboard.total_reported_links.market_place;
                      this.reportedSm = data.dashboard.total_reported_links.social_media;
                      this.reportedSw = data.dashboard.total_reported_links.standalone;
                    
                      this.disabledMp = data.dashboard.total_disabled_links.market_place;
                      this.disabledSm = data.dashboard.total_disabled_links.social_media;
                      this.disabledSw = data.dashboard.total_disabled_links.standalone;

                      this.smReportedPercentage = this.getPercent(this.reportedSm, this.totalReported);
                      this.mpReportedPercentage = this.getPercent(this.reportedMp, this.totalReported);
                      this.swReportedPercentage = this.getPercent(this.reportedSw, this.totalReported);
                      this.mpDisabledPercentage = this.getPercent(this.disabledMp, this.totalDisabled);
                      this.smDisabledPercentage = this.getPercent(this.disabledSm, this.totalDisabled);
                      this.swDisabledPercentage = this.getPercent(this.disabledSw, this.totalDisabled);

                    }
                  }
                }else{
                  if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
                    //this.toastr.error(this.errorDscrp,this.errorMsg);
                    this.authServiceObj.logout(true);
                  }
                }
                
              }
            );
  }

  // drawGuage() {

  //   const d3 = this.d3; // <-- for convenience use a block scope variable
  //   let d3ParentElement: Selection< any, any, any, any>;

  //   if (this.parentNativeElement !== null) {

  //     d3ParentElement = d3.select(this.parentNativeElement); // <-- use the D3 select method

  //     const svg = d3ParentElement.select('svg');

  //     const g = svg.append('svg:g')
  //                  .attr('transform', 'translate(45, 90)');

  //     const arc = d3.arc();

  //     const path = arc({
  //         startAngle: 0,
  //         endAngle: Math.PI,
  //         innerRadius: 0,
  //         outerRadius: 90
  //       });

  //     const path2 = arc({
  //         startAngle: 0,
  //         endAngle: this.successRate * 0.01 * Math.PI,
  //         innerRadius: 75,
  //         outerRadius: 90
  //       });

  //     g.append('path')
  //     .attr('d', path)
  //     .attr('fill', '#DCDCDC')
  //     .attr('transform', 'rotate(-90)');
  //     g.append('path')
  //     .attr('d', path2)
  //     .attr('fill', '#7CA56C')
  //     .attr('class', 'guage-value')
  //     .attr('transform', 'rotate(-90)');

  //     const text = g.append('text')
  //                   .attr('text-anchor', 'middle')
  //                   .attr('x', 0)
  //                   .attr('y', -30)
  //                   .attr('font-size', 36)
  //                   .attr('fill', '#73879c');
  //     text.append('tspan')
  //       .attr('x', 0)
  //       .attr('font-size', 40)
  //       .attr('font-family', 'HelveticaNeue-Bold')
  //       .text('' + this.successRate + '%');
  //     text.append('tspan')
  //       .attr('x', 0)
  //       .attr('dy', 20)
  //       .attr('font-size', 15)
  //       .attr('font-weight', 'normal')
  //       .attr('font-family', 'HelveticaNeue')
  //       .text('Success Rate');
  //   }
  // }
}
