import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) {
    this.headers = new Headers();

    this.headers.append('Content-Type', 'application/json;charset=UTF-8');
    this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
   }

   getMappingData(body: any){
    const headers = new Headers();
    this.headers.set('x-access-token', this.localSt.retrieve('web_access_token'));
    this.headers.append('Accept', 'application/json');
    const options = new RequestOptions({ headers: this.headers });
  //  const bodyString: string = 'brand_id=' + body.brand_id+ '&fromDate=' + body.fromDate + '&toDate=' + body.toDate + '&key=' + body.key + '';

    return this.http.post(AppSettings.API_ENDPOINT + '/mapping', body, options)
      .map(function (res: any) {
        return res.json() || { status: 'faild', message: 'Somthing went wrong' };
      });

   }
}
