import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) {
    this.headers = new Headers();

		this.headers.append('Content-Type', 'application/json;charset=UTF-8');
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
   }

  getDashBoardData(data: any) {
    let queryString: string;
    queryString = 'brand=' + data.brandId  + '&fromDate=' + data.fromDate + '&toDate=' + data.toDate + '';
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers}); 
    return this.http.get( AppSettings.API_ENDPOINT + '/dashboard/home?' + queryString, options )
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error:any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({status:"error",message:"Server error occured"})
      });
  }
}
