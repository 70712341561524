import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../services/report/report.service';
import { LocalStorageService } from 'ngx-webstorage';
import { BrandService } from '../../../services/brand/brand.service';
import { AppSettings } from '../../../settings.global';
import { LoaderService } from '../../../services/loader/loader.service';
import { AuthService } from '../../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-report',
  templateUrl: './home-report.component.html',
  styleUrls: ['./home-report.component.scss']
})
export class HomeReportComponent implements OnInit {

  public latestReport : any;
  public details : any;
  public localDataBrand : any;
  public brandId : any;
  public file_url = AppSettings.IMAGE_BASE_URL;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public clientID:any;

  constructor(private reportService : ReportService,
    private brandService: BrandService,
    private loaderService:LoaderService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private localStorage : LocalStorageService) { 
    this.latestReport = [];
    this.localDataBrand = this.localStorage.retrieve('web_user_details');
    this.brandId = this.localStorage.retrieve('active_brand_id');
    this.clientID=this.localStorage.retrieve('active_client_id');
    this.details = {
      brand : this.brandId,
      client : this.clientID
    }
  }

  ngOnInit() {
    ///this.loaderService.display(true);
    this.brandService.brandChanged.subscribe((id) => {
      //this.loaderService.display(false);
      this.details.brand = id;
      //this.loaderService.display(true);
      //this.getReport();

    });
    this.getReport();
    
  }
  getReport(){

    this.reportService.getReport(this.details).subscribe( (res) => {
        this.loaderService.display(false);

        if(res.json().status == "success"){         
          this.latestReport = res.json().data_found.latest_report;
        }else{
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(false);
          }
        }
        
      })
  }

}
