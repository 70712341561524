import { Directive, Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';

@Directive({
  selector: '[appBarchart]'
})
export class BarchartDirective {

  @Input () barchartOption : {};

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private width: number;
  private height: number;
  private htmlElement: HTMLElement;
  private barData: any;
  private data: any;
  private margin: any;
  private widthobj: any;

  constructor(private el: ElementRef) { 
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
   // this.width = this.htmlElement.clientWidth;
   // this.height = this.htmlElement.clientHeight;
  }

  private setup(): void {
    this.margin = {
      top: 25,
      right: 25,
      bottom: 15,
      left: 0
    };      
    this.width = this.widthobj - this.margin.left - this.margin.right,
    this.height = 200 - this.margin.top - this.margin.bottom;
   }

   private buildSVG(): void {
    
  }

   private buildErrorMsg(): void {
    this.host.html('');
  //  this.host.html('<h2> No data found! </h2>');
  }

  getBar(){
    this.data=this.barData
    this.host.html('');
    const svg =   this.host.append("svg")
                     .attr("width", this.width + this.margin.left + this.margin.right)
                     .attr("height", (95*this.data.length) + this.margin.top + this.margin.bottom);

    var chart = svg.append("g")
                    .classed("display", true);
                   // .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
        
        const data = this.data;

        let x = D3.scaleLinear()
                    .domain([0, 100])
                    .range([0, this.widthobj]);

        const y = D3.scaleBand()
                    .domain(data.map(function(d){
                        return d.name;
                    }))
                    .range([0, 95*this.data.length])
                    .padding(.5);
        
        function plot(params){
            this.selectAll(".bar")
                .data(params.data)
                .enter()
                    .append('rect')
                    .classed("bar1", true)
                    .attr('x', 0)
                    .attr('y', function(d,i){
                    return y(d.name);
                    })
                    .attr('fill', '#DCDCDC')
                    .attr("height",27)
                    .attr('width',params.widthobj);
            this.selectAll(".bar")
                    .data(params.data)
                    .enter()
                    .append("rect")
                    .classed("bar", true)
                    .attr("y", function(d,i){
                        return y(d.name);
                    })
                    .attr("height", 27)
                    .attr('fill','rgb(124, 165, 108)')
                    .attr("width", function(d){
                        return x(d.success_rate);
                    }); 
            this.selectAll(".chart")
                .data(params.data)
                .enter()
                    .append("text")
                    .attr("class", "label")
                    //  y position of the label is halfway down the bar
                    .attr("y", function (d) {
                        return y(d.name) + 17 ;
                    })
                    //x position is 3 pixels to the right of the bar
                    .attr("x", function (d) { if(d.success_rate<90){
                        return x(d.success_rate) + 4;}else{ return x(d.success_rate)-(x(d.success_rate)/7)}
                    })
                    .attr("fill","#6d6d6d")
                    .attr("style","font-size:13px")
                    .text(function (d) { 
                        return Math.round(d.success_rate)+'%';
                    });
            this.selectAll(".chart")
                    .data(params.data)
                    .enter()
                        .append("text")
                        .attr("class", "heading")
                        //  y position of the label is halfway down the bar
                        .attr("y", function(d,i){
                        return y(d.name)-10;
                        })
                        //x position is 3 pixels to the right of the bar
                        .attr("x",0)
                        .attr("fill","#8b8f93")
                        .attr("style","font-size:15px")
                        .text(function (d) {
                            return d.name+' - '+d.total_count+' links';
                        });
        }

        plot.call(chart, {data: data, widthobj: this.widthobj});

  }

  ngOnChanges(){
    this.barData = this.barchartOption['data'];
    //this.widthobj = this.barchartOption['width'];
    this.widthobj = this.htmlElement.clientWidth -40;
    if(this.barData.length !== 0){
      this.setup();
      this.buildSVG();
      this.getBar();
    }else{
      this.buildErrorMsg();
    }
  }

 
}
