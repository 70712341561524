// import { Directive } from '@angular/core';
import { Directive, Input, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';
import { TitleCasePipe } from '@angular/common';

@Directive({
  selector: '[appMultilinechart]'
})
export class MultilinechartDirective {

  @Input () multiLineChartOption : {};

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private width: number;
  private height: number;
  private htmlElement: HTMLElement;
  private data: any;
  private margin: any;
  private duration: any; 
  private lineOpacity : any;
  private lineOpacityHover: any;
  private otherLinesOpacityHover : any;
  private lineStroke : any;
  private lineStrokeHover : any;
  private circleOpacity : any;
  private circleOpacityOnLineHover : any;
  private circleRadius : any;
  private circleRadiusHover : any;
  private parseDate : any;
  private xScale : any;
  private yScale : any;
  private extent : any;
  private extent2 : any;
  private color : any;
  private graphColors : any;

  public multiLineChartOptionData: any;  
  public multiLineChartAxisData: any;
    
  constructor(private el: ElementRef) {
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);  
  }

  ngOnChanges(){
    // console.log('*****this.multiLineChartOption--',this.multiLineChartOption)
    this.multiLineChartOptionData = this.multiLineChartOption['data'];  
    this.multiLineChartAxisData = this.multiLineChartOption['axisdata']; 
    this.graphColors = this.multiLineChartOption['colors']; 
    console.log('*****this.multiLineChartOptionData--',this.multiLineChartOptionData)
    console.log('*****this.multiLineChartOptionData.testtt--')
    console.log('*****this.multiLineChartOptionData.length--',this.multiLineChartOptionData.length)
    console.log('*****this.multiLineChartAxisData--',this.multiLineChartAxisData)
    console.log('*****this.graphColors--',this.graphColors)
    if(this.multiLineChartOptionData.length !== 0){     
      this.setup();
      this.createChart();       
    }else{
      this.host.html('');
     // this.buildErrorMsg();
    }
  }

  private setup(): void {
    // set the dimensions and margins of the graph
    // this.margin = 50;
    this.margin = 50;
    // this.width = 540;
    // this.height = 300;
    this.width = 540;
    this.height = 420;
    this.duration = 250;
    this.lineOpacity = "0.25";
    this.lineOpacityHover = "0.85";
    this.otherLinesOpacityHover = "0.1";
    this.lineStroke = "1.5px";
    this.lineStrokeHover = "2.5px";
    this.circleOpacity = '0.85';
    this.circleOpacityOnLineHover = "0.25"
    this.circleRadius = 3;
    this.circleRadiusHover = 6;    
  }

  /* Format Data */
  private createChart(): void {
    // this.parseDate = D3.timeParse("%Y");
    var parseDate = D3.timeFormat("%Y");
    this.multiLineChartOptionData.forEach(function(d) { 
      // console.log('@@d--',d)
        d.values.forEach(function(d) {
        // d.date = parseDate(d.date);
        d.date = new Date(d.date);
        // d.date = +d.date;    
        d.price = +d.price;    
      });
    });
 
    // this.extent = D3.extent(this.multiLineChartOptionData[0].values,  d=>d.date)  

  /* Scale */  
    // console.log('***this.multiLineChartOptionData[0].values--',this.multiLineChartOptionData[0].values)
    // console.log('***multiLineChartOptionData[0].values.date--',this.multiLineChartOptionData[0].values.date)
    
    // let min= Math.min.apply(Math, this.multiLineChartOptionData[0].values.map(function(o) { return o.date; }))
    // let max= Math.max.apply(Math, this.multiLineChartOptionData[0].values.map(function(o) { return o.date; }))
    
    this.extent = [this.multiLineChartAxisData.startDate,this.multiLineChartAxisData.endDate];
    // this.extent = [min,max];

    // console.log('***maxx--',max)    
    // console.log('***maxx--',min)   
    // console.log('***extent--',this.extent)
    var xScale = D3.scaleTime()     
    // .domain(this.extent)    
    .domain(this.extent)
    .range([0, this.width-this.margin]);
    // console.log('***xScale--',this.xScale)
    // // function(d) { return (d.data.product); }
    // mini = Math.min.apply(Math, this.multiLineChartOptionData[0].values.map(function(o) { return o.price; }))
    // max = Math.max.apply(Math, this.multiLineChartOptionData[0].values.map(function(o) { return o.price; }))    
    // this.extent2 = [mini,maxi];/
    // console.log('***extent2--',this.extent2)
    // this.extent2 = D3.max(this.multiLineChartOptionData[0].values,  d => d.price)
    var yScale = D3.scaleLinear()
    // .domain([0, max])    
    
    .domain([0, 100])    
    .range([this.height-this.margin, 0]);
    // console.log('***yScale--',this.yScale)

    // this.color = D3.scaleOrdinal(D3.schemeCategory10);
    // var color = D3.scaleOrdinal(D3.schemeCategory10);
    var color = this.graphColors;
    // var color = ['#f0d128','#6c7279','#3397d8'];
    // console.log('**colors-',this.color)
  
  /* Add SVG to the body of the page */
    this.host.html('');
    this.svg = this.host.append('svg')
    .attr("width", (this.width+this.margin)+"px")
    // .attr("height", (this.height+this.margin)+"px") 
    .attr("height","475px")     
    .style("font-family"," Sans-Serif, Arial")
    //.data([this.multiLineChartOptionData[0].values])
    .append('g')
    .attr("transform", `translate(${this.margin}, ${this.margin})`);     
    // /* Add line into SVG */
    var line = D3.line()   
    .x(d => xScale(d['date']))
    .y(d => yScale(d['success']));
    // 
    // .y(d => yScale(d.price));
    // ---------------------------------------------------------------------------------    
    /* Add Axis into SVG */
    var xAxis = D3.axisBottom(xScale).ticks(this.multiLineChartAxisData.quartersCount);
    var yAxis = D3.axisLeft(yScale).ticks(10);
    // Horizontal line
    var yAxis = D3.axisLeft(yScale)
                .ticks(10)
                // .tickSize(-this.width);
                .tickSize(-490);
    
    this.svg.append("g")
    .attr("class", "x axis")    
    .attr("transform", `translate(0, ${this.height-this.margin})`)    
    .style("font-size","14px") 
    .style('font-family','HelveticaNeue')    

    
    // .call(xAxis);

    .call(xAxis)
    //--------edited----------
    .selectAll("text")	
    .style("text-anchor", "end")  
    .attr("dx","-0.5em") 
    .attr("y","0") 
    .attr("transform", "translate(8, 0)")     
    .attr("transform", "rotate(-65)");
    //--------edited----------
    this.svg.append("g")
    .attr("class", "x axis")   
    .append('text')
    .attr("x", 438)
    .attr("y", 365)
    .attr("transform", "rotate(0)")
    .attr("fill", "#000")
    .text("Quarters")    
    .style("fill", "#0000ff");

    //--------edited----------
    // .append('text')
    // .attr("x", 455)
    // .attr("y", -8)
    // .attr("transform", "rotate(0)")
    // .attr("fill", "#000")
    // .text("Quarters")    
    // .style("fill", "#0000ff"); 
      
    this.svg.append("g")
    .attr("class", "y axis")
    .style("font-size","14px") 
    .style('font-family','HelveticaNeue')  
    //--------------
    //@
    .attr("class", "axis axis--y")
    // .attr("transform", "translate(8,0)")
    //--------------

    // .attr("transform", "translate(8,0)")
    .call(yAxis)    
 

    .append('text')
    .attr("y", 15)
    .attr("transform", "rotate(-90)")
    .attr("fill", "#000")
    .text("Success Rate")
    .style("fill", "#0000ff");  
    //--------------
    //@
    // this.svg.selectAll('.axis--y path')
    // .attr('d', 'M550,227.77272727272725H0.5V0.5H0');    
    // ---------------------------------------------------------------------------------  

    let lines = this.svg.append('g')
    .attr('class', 'lines');

    lines.selectAll('.line-group')
    .data(this.multiLineChartOptionData).enter()
    .append('g')
    .attr('class', 'line-group')  
    // ---------------------------------------------------------------------------------
    //In progress
    .on("mouseover", function(d,i) {          
          // this.svg.append("text")
          lines.append("text")
          .attr("class", "title-text")           
          // .style("fill", "black")    
          .style("fill", color[i])                 
          .text(d['name'])
          .attr("text-anchor", "middle")
          // .attr("x", (this.width-this.margin)/2)
          .attr("x", (500-50)/2)
          .attr("y", -25);
      })
    .on("mouseout", function(d) {
        // this.svg.select(".title-text").remove();
        lines.selectAll(".title-text").remove();
      })
    // ---------------------------------------------------------------------------------
    //Completed
    .append('path')
    .attr('class', 'line') 
    // .style('stroke-width', 2)
    .style('stroke-width', 3)
    .style('fill', 'none')   

     .attr('d', d => line(d['values']))
   // .attr('d', d => line(this.multiLineChartOptionData.values))
    .style('stroke', (d, i) => color[i])
    .style('opacity', this.lineOpacity)
    .on("mouseover", function(d) {
        D3.selectAll('.line')
            // .style('opacity', this.otherLinesOpacityHover);
            .style('opacity', 0.1);
        D3.selectAll('.circle')
            // .style('opacity', this.circleOpacityOnLineHover);
            .style('opacity', 0.25);
        D3.select(this)
          // .style('opacity', this.lineOpacityHover)
          .style('opacity', 0.85)
          // .style("stroke-width", this.lineStrokeHover)
          .style("stroke-width", 3.0)
          .style("cursor", "pointer");
      })
    .on("mouseout", function(d) {
        D3.selectAll(".line")
            // .style('opacity', this.lineOpacity);
            .style('opacity', 0.25);
        D3.selectAll('.circle')
            // .style('opacity', circleOpacity);
            .style('opacity', 0.85);
        D3.select(this)
          // .style("stroke-width", lineStroke)
          // .style("stroke-width", 1.5)
          .style("stroke-width", 3)
          .style("cursor", "none");
      });
    
     // ---------------------------------------------------------------------------------
    //In progress
     /* Add circles in the line */
    lines.selectAll("circle-group")
    .data(this.multiLineChartOptionData).enter()
    .append("g")
    .style("fill", (d, i) => color[i])
    // .style("fill", "green")
    .selectAll("circle")
    .data(d => d['values']).enter()
    // .data(d => this.multiLineChartOptionData.values).enter()
    .append("g")
    .attr("class", "circle")  
    .on("mouseover", function(d) {
        D3.select(this)     
          .style("cursor", "pointer")
          .append("text")
          .attr("class", "text")
          .text(`${d['success']}`)
          // .text(`${function(d){return d['price']}}`)
          .attr("x", d => xScale(d['date']) + 5)
          // .attr("x", d => v4.xScale(function(d){ return d['date']}) + 5)
          .attr("y", d => yScale(d['success']) - 10);
          // .attr("y", d => v4.yScale(function(d){ return d['price']}) - 10);
      })
    .on("mouseout", function(d) {
        D3.select(this)
          .style("cursor", "none")  
          .transition()
          // .duration(this.duration)
          .duration(250)
          .selectAll(".text").remove();
      })
    .append("circle")
    .attr("cx", d => xScale(d['date']))
    // .attr("cx", d => v4.xScale(function(d){ return d['date']}))
    .attr("cy", d => yScale(d['success']))
    // .attr("cy", d => v4.yScale(function(d){ return d['price']}))
    .attr("r", this.circleRadius)
    .style('opacity', this.circleOpacity)
    .on("mouseover", function(d) {
          D3.select(this)
            .transition()
            // .duration(this.duration)
            .duration(250)
            // .attr("r", this.circleRadiusHover);
            .attr("r", 6);
        })
      .on("mouseout", function(d) {
          D3.select(this) 
            .transition()
            // .duration(this.duration)
            .duration(250)
            // .attr("r", this.circleRadius);  
            .attr("r", 3);  
        });
        
    // // ---------------------------------------------------------------------------------    
    // /* Add Axis into SVG */
    // var xAxis = D3.axisBottom(xScale).ticks(this.multiLineChartAxisData.quartersCount);
    // var yAxis = D3.axisLeft(yScale).ticks(10);
    // // Horizontal line
    // var yAxis = D3.axisLeft(yScale)
    //             .ticks(10)
    //             // .tickSize(-this.width);
    //             .tickSize(-490);
      
    // this.svg.append("g")
    // .attr("class", "x axis")
    // .attr("transform", `translate(0, ${this.height-this.margin})`)
    // // .call(xAxis);

    // .call(xAxis)
    // .append('text')
    // .attr("x", 455)
    // .attr("y", -8)
    // .attr("transform", "rotate(0)")
    // .attr("fill", "#000")
    // .text("Quarters")
    // // .style("fill", (d,i)=>color[i]); 
    // .style("fill", "#0000ff"); 
      
    // this.svg.append("g")
    // .attr("class", "y axis") 
    // //--------------
    // //@
    // .attr("class", "axis axis--y")
    // // .attr("transform", "translate(8,0)")
    // //--------------

    // // .attr("transform", "translate(8,0)")
    // .call(yAxis)    
 

    // .append('text')
    // .attr("y", 15)
    // .attr("transform", "rotate(-90)")
    // .attr("fill", "#000")
    // .text("Success Rate")
    // .style("fill", "#0000ff");  
    // //--------------
    // //@
    // // this.svg.selectAll('.axis--y path')
    // // .attr('d', 'M550,227.77272727272725H0.5V0.5H0');    
    // // ---------------------------------------------------------------------------------    
    


  }
}
