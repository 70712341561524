import { Directive , Input} from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';
//import {TooltipModule} from "ngx-tooltip";
//import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { group } from '@angular/animations';

@Directive({
  selector: '[appScatterPlot]'
})
export class ScatterPlotDirective {

  @Input () chartOption : {};

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private htmlElement: HTMLElement;
  private width: number;
  private height: number;
  public data: any;
  public isp: any;

  constructor(private el: ElementRef) {
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
    this.data = [];

  }

  // ngOnInit(){
  //   this.setup();
  //   this.buildSVG();
  //   this.scatter();
  //  }
  ngOnChanges(){
      this.setup();
      this.buildSVG();
      if(this.data.length !== 0){
        this.scatter();
      }else{}          
  }

  private setup(): void {
    this.width = this.chartOption['width'];
    this.height = this.chartOption['height'];
    this.isp =this.chartOption['isp'];
    this.data = this.chartOption['data'];
  }

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host.append('svg')
      .attr('height', this.height + 13)
      .attr('width', this.width)
      //.attr('viewBox', '10 40')
      // .append('g')
       .attr('transform', `translate(0,-18)`);        
  }

  scatter(){
    const svg = this.svg;
    const color = D3.scaleOrdinal(D3.schemeCategory10);
    const x = D3.scaleLinear().range([0, this.width-100]),
          y =   D3.scaleLinear().range([this.height - 60, 0]);
    const margin = {t:30, r:20, b:20, l:40 };

    // set axes, as well as details on their ticks
    const xAxis = D3.axisBottom(x)
      .ticks(20);
    //	.tickSubdivide(true)
    //	.tickSize(6, 3, 0)
    //	.orient("bottom");

    const yAxis = D3.axisLeft(y)
      .ticks(10);
    //	.tickSubdivide(true)
    //	.tickSize(6, 3, 0);
    //	.orient("left");

    // group that will contain all of the plots
    var groups = this.svg.append("g").attr("transform", "translate(" + margin.l + "," + margin.t + ")")
                          .attr("class","groups");
   
   // bring in the data, and do everything that is data-driven
    this.data.sort(function(a, b) { return D3.ascending(a.region, b.region); })
   
    if(this.isp == false){
      var x0 = D3.max(this.data.map(function(d) { return d.social_media; }));
      x.domain([0,  <any>D3.max(this.data.map(function(d) { return d.social_media; }))+10]);
      y.domain([0, <any>D3.max(this.data.map(function(d) { return d.market_place; }))]);

      // style the circles, set their locations based on data
      var circles =
      groups.selectAll("circle")
        .data(this.data)
        .enter().append("circle")
        .attr("class", "circles")
        .attr('cx',function(d) { return x(+d['social_media']);})
        .attr('cy',function(d) { return y(+d['market_place']); })
        .attr('r',6)
        .attr('id',function(d) { return d['name']; })
          .style("fill", function(d) { const sum = d['social_media']+d['market_place']; if(sum <= x0 && sum > <any>x0/3*2){
            return '#CA8EEF';
            }else if(sum <= <any>x0/3*2  && sum > <any>x0/3 ){
              return '#228B22';
            }else{
              return '#F0EC18';
            }
          });
    }else{
      // var x0 = Math.max(-D3.min(this.data, function(d) { return d['volume']; }), <any> D3.max(this.data, (d) => { return d['trust']; }));
          //isp
      x.domain([0, <any>D3.max(this.data.map(function(d) { return d.volume; }))+10]);
      y.domain([0, <any>D3.max(this.data.map(function(d) { return d.success_rate; }))]);

        // style the circles, set their locations based on data
      var circles =
      groups.selectAll("circle")
        .data(this.data)
        .enter().append("circle")
        .attr("class", "circles")
        .attr('cx',function(d) { return x(+d['volume']);})
        .attr('cy',function(d) { return y(+d['success_rate']); })
        .attr('r',6)
        .attr('id',function(d) { return d['isp']; })
        .style("fill", function(d) { return 'rgb(188, 128, 189)'; });
    } 
 
    const side = 2 * 28 * Math.cos(Math.PI / 4),
    dx = 26 - side / 3;

    var g = groups.append('g')
      .attr('transform', 'translate(' + [dx, dx] + ')');
        
    // what to do when we mouse over a bubble
    var mouseOn = function() { 
      var circle = D3.select(this);
  
      // transition to increase size/opacity of bubble
      circle.transition()
      .duration(800).style("opacity", 1)
      .attr("r", 12);
      //  .ease("elastic");
  
      // append lines to bubbles that will be used to show the precise data points.
      // translate their location based on margins
      
      svg.append("g")
        .attr("class", "guide")
        .append("line")
          .attr("x1", circle.attr("cx"))
          .attr("x2", circle.attr("cx"))
          .attr("y1", +circle.attr("cy") + 26)
          // .attr("y2", this.height - margin.t - margin.b)
          .attr("y2", 300)
          //ISP
        //  .attr("y2",  510)
          .attr("transform", "translate(40,20)")
          .style("stroke", circle.style("fill"))
          .transition().delay(200).duration(400).styleTween("opacity", 
              <any>  function() { return D3.interpolate(0, .5); })
  
      svg.append("g")
        .attr("class", "guide")
        .append("line")
          .attr("x1", +circle.attr("cx") - 16)
          .attr("x2", 0)
          .attr("y1", circle.attr("cy"))
          .attr("y2", circle.attr("cy"))
          .attr("transform", "translate(40,30)")
          .style("stroke", circle.style("fill"))
          .transition().delay(200).duration(400).styleTween("opacity", 
              <any>  function() { return D3.interpolate(0, .5); });
  
      // function to move mouseover item to front of SVG stage, in case
      // another bubble overlaps it
      D3.selection.prototype.moveToFront = function() { 
        return this.each(function() { 
        this.parentNode.appendChild(this); 
        }); 
      };
    }
    var mouseOff = function() {
      var circle = D3.select(this);
 
      // go back to original size and opacity
      circle.transition()
      .duration(800)
      //.style("opacity", .5)
      .attr("r", 6).ease(D3.easeLinear);
  
      // fade out guide lines, then remove them
      D3.selectAll(".guide")
      //  .transition().duration(100).styleTween("opacity", 
      //          () => { return D3.interpolate(.5, 0); })
        .remove();
    };

    var clickOn = function() {
      var circle = D3.select(this);
   //   $('[data-toggle="tooltip"]').tooltip()
    }
 
    // run the mouseon/out functions
    circles.on("mouseover", mouseOn);
    circles.on("mouseout", mouseOff);
    circles.on("click", clickOn);
 
    // tooltips (using jQuery plugin tipsy)
    if(this.isp == false){
      circles.append("title")
      .text(function(d) { const m = d['volume'];
      const s = d['success_rate'];
      // const total = m+s; 
      return ''+d['name']+'\nTotal: '+d['total']+'\nMarketplace: '+d['market_place']+'\nSocial Media: '+d['social_media']; })

    }else{
      //isp
      circles.append("title")
      .text(function(d) {  
      return ''+d['isp']+'\nVolume: '+d['volume']+'\nSuccess Rate: '+d['success_rate'] + '%'; });

    }      
 
    // gridlines in y axis function
    function make_y_gridlines() {		
      return D3.axisLeft(y)
          .ticks(3.5)
    }
     // add the Y gridlines
    svg.append("g")			
    .attr("class", "grid")
    .style("stroke-opacity",0.2)
    .attr("transform", "translate(" + margin.l + "," + margin.t + ")")
    .call(make_y_gridlines()
        .tickSize(-(this.width - 110))
        .tickFormat((d) => { return ''})
    )
 
    // draw axes and axis labels
    this.svg.append("g")
      .attr("class", "x axis")
      .attr("font-size",9)
      .attr("transform", "translate(" + margin.l + "," + (this.height - 60 + margin.t) + ")")
      .call(xAxis);
  
    this.svg.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + margin.l + "," + margin.t + ")")
      .call(yAxis);
  
    this.svg.append("text")
      .attr("class", "x label")
      .attr("text-anchor", "end")    
      .attr("y", this.height - margin.t + 29)
      //  .attr("x", this.width )
      //  .text("Number on Social Media");
      //isp
      .attr("x", this.width - 60 )
      .text( (d) => {if(this.isp == false){ 
        return "Number on Social Media"; 
        }else{
        return "Volume";
        }});
  
    this.svg.append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("x", -20)
      .attr("y", 45)
      .attr("dy", "-3.3em")
      .attr("dx", "-1em")
      .attr("transform", "rotate(-90)")
      //.text("Number on Marketplaces");
      // isp
      .text((d) => {if(this.isp == false){ 
        return "Number on Marketplaces"; 
        }else{
        return "Success Rate";
        }});

        // groups.selectAll("circle").each(function () {
        //   var node = D3.select(this);    
        //   var tooltipText = function (d) {
        //       return ''+d['name']+'\nTotal: '+d['total']+'\nMarketplace: '+d['market_place']+'\nSocial Media: '+d['social_media'];
        //   };                            
        //   if (tooltipText) {
            
        //       // node.select(".circles")
        //        node.attr("data-html", "true")
        //         .attr("title",tooltipText );
        //         console.log("tip")
        //   }
          
        //   });
  }

}
