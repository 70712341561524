import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions} from '@angular/http';
import { AppSettings } from '../../settings.global';
import { LocalStorageService } from 'ngx-webstorage';
import {Observable} from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) {

    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json;charset=UTF-8');
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
   }

  getReport(body: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    let queryString: string;
    		queryString = 'brand=' + body.brand + '&client=' + body.client + '';
    return this.http.get(AppSettings.API_ENDPOINT + '/report/home?' + queryString, options )
    .map(function (res: any) {
			return res || {
				status: "failed",
				message: "Something went wrong"
			};
		}).catch((error:any) => {
			//return Observable.throw(error.json().error || 'Server error occured')
			return Observable.of({status:"error",message:"Server error occured"})
		});
  }

  getEachYearReports(body: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    let queryString: string;
    queryString = 'year=' + body.year + '&brand=' + body.brand + '&client=' + body.client + '';
    return this.http.get(AppSettings.API_ENDPOINT + '/report/year-wise?' + queryString, options)
    .map(function (res: any) {
			return res || {
				status: "failed",
				message: "Something went wrong"
			};
		}).catch((error:any) => {
			//return Observable.throw(error.json().error || 'Server error occured')
			return Observable.of({status:"error",message:"Server error occured"})
		});
  }

  deleteClientFile(body: any){
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.put(AppSettings.API_ENDPOINT + '/report/client-file',body,options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      });
	}

  public uploadReport(body:any,file:any): Promise<any> {
		return new Promise((resolve, reject) => {
			let token=this.localSt.retrieve("web_access_token")
			let formData:FormData = new FormData(),
			xhr: XMLHttpRequest = new XMLHttpRequest();
			if(file){
				formData.append('client_file', file, file.name);
			}
			
			for(let index in body){
				formData.append(index,body[index]);
			}
			xhr.onreadystatechange = () => {

				if (xhr.readyState === 4) {
					
					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.response));
					} else {
						reject(xhr.response);
					}
				}
			};
			xhr.open('POST', AppSettings.API_ENDPOINT+'/report/client-file', true);
			xhr.setRequestHeader('x-access-token',token);
			xhr.send(formData);

		})
	}

	// downloadFile(url:any){
	// 	let headers = new Headers();
	// 	this.headers.set('x-access-token', this.localSt.retrieve("access_token"));
	// 	this.headers.append('Accept', 'application/json');
		
	// }
}
