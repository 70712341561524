
import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { LoggedInGuard } from './providers/auth/auth.providers';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ReportComponent} from './components/report/report.component';
import {DataAnalysisComponent} from './components/data-analysis/data-analysis.component';
import {HomeComponent} from './components/home/home.component';
import { SuccessRateMarketplaceComponent } from './components/success-rate-marketplace/success-rate-marketplace.component';
import { SuccessRateSocialMediaComponent } from './components/success-rate-social-media/success-rate-social-media.component';
// tslint:disable-next-line:max-line-length
import { SuccessRateStandaloneWebsitesComponent } from './components/success-rate-standalone-websites/success-rate-standalone-websites.component';
import { MarketplaceDashboardComponent } from './components/marketplace-dashboard/marketplace-dashboard.component';
import { SocialmediaDashboardComponent } from './components/socialmedia-dashboard/socialmedia-dashboard.component';
import { StandaloneWebsitesDashboardComponent } from './components/standalone-websites-dashboard/standalone-websites-dashboard.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MappingComponent } from './components/mapping/mapping.component';



const appRoutes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    data: {'activeComponent': 'LoginComponent'}
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [LoggedInGuard],
    pathMatch: 'full',
    data: {'activeComponent': 'HomeComponent'}
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoggedInGuard],   
    data: {'activeComponent': 'DashboardComponent'}
  },
  {
    path: 'dashboard/successrate-marketplace',
    component: SuccessRateMarketplaceComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'SuccessRateMarketplaceComponent'}
  },
  {
    path: 'dashboard/successrate-socialmedia',
    component: SuccessRateSocialMediaComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'SuccessRateSocialMediaComponent'}
  },
  {
    path: 'dashboard/successrate-standalone-websites',
    component: SuccessRateStandaloneWebsitesComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'SuccessRateStandaloneWebsitesComponent'}
  },
  {
    path: 'dashboard/marketplaces',
    component: MarketplaceDashboardComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'MarketplaceDashboardComponent'}
  },
  {
    path: 'dashboard/socialmedia',
    component: SocialmediaDashboardComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'SocialmediaDashboardComponent'}
  },
  {
    path: 'dashboard/standalone-websites',
    component: StandaloneWebsitesDashboardComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'StandaloneWebsitesDashboardComponent'}
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'ProfileComponent'}
  },
  {
    path: 'report',
    component: ReportComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'ReportComponent'}
  },
  {
    path: 'data-analysis',
    component: DataAnalysisComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'DataAnalysisComponent'}
  },
  {
    path: 'data-analysis/:type',
    component: DataAnalysisComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'DataAnalysisComponent'}
  },
  {
    path: 'mapping',
    component: MappingComponent,
    canActivate: [LoggedInGuard],
    data: {'activeComponent': 'MappingComponent'}
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes,{ useHash: true });
