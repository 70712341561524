import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../settings.global';
import { BrandService } from '../../services/brand/brand.service';
import { Observable, fromEvent } from 'rxjs/';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';

import {DatePipe} from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-standalone-websites-dashboard',
  templateUrl: './standalone-websites-dashboard.component.html',
  styleUrls: ['./standalone-websites-dashboard.component.scss'],
  providers: [DatePipe,TitleCasePipe]
})
export class StandaloneWebsitesDashboardComponent implements OnInit {

  @ViewChild('scroller') scroller: ElementRef; 
 @ViewChild('printSection') table: ElementRef;

  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };  
  // public chartOption: object;
  public countries : any;
  public countryId : any;
  public dashData : any;
  public localData : any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public offSet: number = 0;
  public apiCallCount: number = 0;
  public successData : any;
  public reported : any;
  public disabled : any;
  public successRate: any;
  public breakdown : any;
  public pieData1 : any;
  public countryName : any;
  public status : any;
  public pieData2 : any;
  public pager:any;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public img_url = AppSettings.IMAGE_BASE_URL;
  public screenshot_url = AppSettings.SCREENSHOT_BASE_URL;
  public listData : any;
  public colors : any;
  public numberOfPages: any;
  public start: any;
  public end: any;
  public no_of_entry: any;
  public searchInputObservable$: any;
  public copyValue: any;
  public screenShots: any;
  public activeIndex: any;
  public countryArray: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public country_exists:boolean=false;
  public isSingleClick : boolean;  
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public pageBeforeSearch : any;
  public selectedDateRangeLabel : any;
  public selected_brand_list_data: any;
  public breakdown_selected_brand_list_data :any;
  public status_selected_brand_list_data :any;
  public statusLabel:any;

  constructor(private dashboardService: DashboardService,
    private lsService: LocalStorageService,
    public datepipe: DatePipe,
    private brandService: BrandService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private loaderService:LoaderService,
    private modalService: BsModalService,
    private titleCasePipe:TitleCasePipe) {
    this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel'); 
    this.countries = [];
    this.localData = JSON.parse(this.lsService.retrieve('search_base'));
    this.dashData = {
      brand : this.lsService.retrieve('active_brand_id'),
      country_id : '',
      fromDate : this.localData.fromDate,
      toDate : this.localData.toDate,
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    };
    var selectedBrandsDetails = [];
    // selectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));
    var unsortedSelectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));  
    //Sort the list of brands selected
    selectedBrandsDetails = unsortedSelectedBrandsDetails.sort((a, b) => (a.name > b.name) ? 1 : -1) 
    
    if(!selectedBrandsDetails || selectedBrandsDetails == null)
    {
      selectedBrandsDetails = [];
    }


    // var selectedSummaryObj = {id:0, name:'All Selected',spcialClass:'active-brands-tab'};
    // if(selectedBrandsDetails.length > 1)
    // {
    //   selectedBrandsDetails.unshift(selectedSummaryObj);
    // }
    // if(selectedBrandsDetails.length == 1)
    // {
    //   selectedBrandsDetails[0].spcialClass = 'active-brands-tab';
    // }
    // this.selected_brand_list_data = selectedBrandsDetails;

    //-----------------------Edited------------------------
    var breakdown_selectedSummaryObj = {id:0, name:'All Breakdown',spcialClass:'active-brands-tab '};
    var status_selectedSummaryObj = {id:0, name:'Status',spcialClass:'active-brands-tab '};
    // Deep clone
    var breakdown_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));
    var status_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));

    if(selectedBrandsDetails.length >= 1)
    {
      breakdown_selectedBrandsDetails.unshift(breakdown_selectedSummaryObj);
      status_selectedBrandsDetails.unshift(status_selectedSummaryObj);
    }    
    this.breakdown_selected_brand_list_data = breakdown_selectedBrandsDetails;
    this.status_selected_brand_list_data = status_selectedBrandsDetails;

    //-----------------------Edited------------------------

    this.daterange.start = this.localData.fromDate;
    this.daterange.end = this.localData.toDate;
    this.successData = [];
    this.reported = 0;
    this.disabled = 0;
    this.successRate = 0;
    this.breakdown = [];
    this.pieData1 = [];
    this.statusLabel = [{name:'ON'},{name:'OFF'}];
    this.status = [];
    this.pieData2 = [];
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    };
    this.listData = [];
   // this.colors = ['#196699', '#2083c5', '#3a9cdf', '#66b2e6', '#92c8ed', '#bddef4']; 
   this.colors = ['#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];
    this.no_of_entry = 50;
    this.screenShots = [];
    this.activeIndex = 0;
    this.countryArray = [];
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  this.localData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.localData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;
    this.datepickerOptions.startDate =  new Date(this.localData.fromDate);
    this.datepickerOptions.endDate = new Date(this.localData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate) 
  }

  ngOnInit() {
    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
      //   this.loaderService.display(false);
      //   this.dashData.brand = id;
      //   this.apiCallCount = 0;
      //   this.pager["total_count"] = 0;
      //   this.pager["current_page"] = 1;
      //   this.pager["offset"] = 0;
      //   this.offSet = 0;
      //   this.listData = [];
      //   this.getStandaloneData(this.dashData,1);
      // });
      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];
      //this.getStandaloneData(this.dashData,1);
      this.loaderService.display(true);
      this.dashboardService.getRelevantCountries(this.dashData.brand,this.localData.fromDate,this.localData.toDate).subscribe( (res) =>{
        console.log(res)
        if(res.json().status == "success"){
          this.countries = res.json().data_found;
          for(let country of this.countries){
            if(country.id==this.lsService.retrieve('top_standalone_website')){             
              this.country_exists=true;
            }
          }
          if(this.country_exists){            
            this.countryName =(this.lsService.retrieve('top_standalone_website_name'))?this.lsService.retrieve('top_standalone_website_name'):'All'
            this.countryId = (this.lsService.retrieve('top_standalone_website'))?this.lsService.retrieve('top_standalone_website'):0;
          }else{            
            this.countryName ='All';
            this.countryId =0;
          }
          this.lsService.store('top_standalone_website', this.countryId);
          this.lsService.store('top_standalone_website_name', this.countryName);
          this.dashData['country_id'] = this.countryId;
          this.getStandaloneData(this.dashData,1);
        }else{
          this.countryId=0;
          this.pieData1=null;
          this.pieData2=null;
          this.countryName ='All'
          this.lsService.store('top_standalone_website', this.countryId);
          this.lsService.store('top_standalone_website_name', this.countryName);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }

      });

      //Scrolling the top scroll bar while bottom one is scrolling and vice vrersa.
      $(function(){
        $(".scroll-main").scroll(function() {              
            $(".scrollDummy").scrollLeft($(".scroll-main").scrollLeft());
        });

        $(".scrollDummy").scroll(function(){                
            $(".scroll-main").scrollLeft($(".scrollDummy").scrollLeft());
        });
      });
      setTimeout(function(){      
        var coverWidth = document.getElementById('sm_brndListCoverMain').offsetWidth; 
        var contentWidth = document.getElementById('ListingBrands').offsetWidth;
        if(contentWidth <= coverWidth)
        {
          var all = document.getElementsByClassName('brnadlist-slider-icon');
          for (var i = 0; i < all.length; i++) {
            all[i].className += " hide-content"; 
          }
        }
      }, 500);


    }

    countryChange(id){
      this.lsService.store('top_standalone_website', id);
      this.dashData['country_id'] = id; 
      if(id!=0){
        this.countryArray = this.countries.filter(x => x.id === id);    
        this.countryName = this.countryArray[0].name;
        this.lsService.store('top_standalone_website_name', this.countryArray[0].name)
      }else{
        this.countryName = 'All';
        this.lsService.store('top_standalone_website_name', 'All')
      }
      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];  
      this.getStandaloneData(this.dashData,1);
    }

    getStandaloneData(data,page: number){
      this.lsService. store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true);      
      this.dashboardService.getStandaloneData(this.dashData).subscribe( (res) => {

        this.apiCallCount++;      
        this.successData = res.json().success_rate;
        if(this.successData.length != 0){
          this.reported = this.successData.reported_link;
          this.disabled = this.successData.disabled_link;
          this.successRate = Math.round(this.successData.success_rate);
        }else{
          this.reported = 0;
          this.disabled = 0;
          this.successRate = 0;
        }       
        this.breakdown = res.json().standalone_breakdown;
        this.statusLabel = res.json().standalone_status;
        if(res.json().standalone_list.status == 'success' && !_.isNull(res.json().standalone_list.data)){
          this.pager["total_count"] = this.listData.length + res.json().standalone_list.data.length;
          this.pager["current_page"] = page;
          this.listData = res.json().standalone_list.data; 
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages =this.pager["current_page"];
          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          if(!_.isNil(res.json().standalone_list.max_possible_res_length) && !_.isNull(res.json().standalone_list.data) && res.json().standalone_list.max_possible_res_length > this.listData.length+1) {
            this.offSet = this.apiCallCount * this.queryLimit;
            this.getStandaloneData(this.dashData,1);
          } else{
            this.loaderService.display(false);
            if(this.breakdown.length != 0){
              this.countryName = this.lsService.retrieve('top_standalone_website_name');
              this.pieData1 = res.json().standalone_breakdown.map(val => val.reported_link);
            }else{
              //  this.countryName = '';
              this.pieData1 = null;
            }
            if(res.json().standalone_status){
              this.status = res.json().standalone_status.map(val => val.name);;
              this.pieData2 = res.json().standalone_status.map(val => val.count); 
              this.statusLabel = res.json().standalone_status;
              if(this.pieData2.length == 0){
                this.pieData2 = null;
              }
            }  
          }   
        }else{
          this.loaderService.display(false);
          if(this.listData.length === 0 || res.json().standalone_list.max_possible_res_length === 0) {
            this.pieData1 = null;
            this.pieData2 = null;
            this.listData = [];
            this.pager["total_count"] = 0;
            this.pager["current_page"] = page;
            const totalItemsCount = this.pager["total_count"];
            const numberOfItemsPerPage = this.pager["limit"];
            const pages =this.pager["current_page"];
            this.numberOfPages = totalItemsCount;
            this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
            this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          }
          if(res.json().standalone_list.message == "invalid_accesstoken" || res.json().standalone_list.message == "un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }        
      })
    }

    // function for scroll brands tab
    //Added By : Arif
    slideBrand(position:any, type:any)
    {
      //alert(type);
      const conent = document.querySelector('#'+type+'brndListCoverMain');
      if(position == 'right') conent.scrollLeft += 100;
      if(position == 'left') conent.scrollLeft -= 100;
      event.preventDefault();
      //alert(position);
    }
    // function to display chart for markrt places based on tabs selected
    //Added By : Arif
    viewBrandsTabData(index:any, type:string){
      var previousSelectedId = document.querySelector("."+type+"active-brands-tab").id;
      //alert(type+'selectedBrand_'+index);
      document.getElementById(previousSelectedId).classList.remove(type+"active-brands-tab","active-brands-tab");
      document.getElementById(type+'selectedBrand_'+index).classList.add(type+"active-brands-tab","active-brands-tab");
      
      // Section to change chart content
      this.lsService.store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true); 
      
      var apiRequestData = this.dashData;
      if(index != 0)
      {
        apiRequestData.brand = [index];
      }
      else{
        apiRequestData.brand = this.lsService.retrieve('active_brand_id');
      }
      //console.log('Dash Data', apiRequestData);
      this.dashboardService.getStandaloneData(this.dashData).subscribe( (res) => {
        // this.successData = res.json().success_rate;
        // if(this.successData.length != 0){
        //   this.reported = this.successData.reported_link;
        //   this.disabled = this.successData.disabled_link;
        //   this.successRate = Math.round(this.successData.success_rate);
        // }else{
        //   this.reported = 0;
        //   this.disabled = 0;
        //   this.successRate = 0;
        // }       
        // this.breakdown = res.json().standalone_breakdown;
        this.loaderService.display(false);
        if(type == 'sa_')
        {
          // if(this.breakdown.length != 0){
            this.countryName = this.lsService.retrieve('top_standalone_website_name');
            this.pieData1 = res.json().standalone_breakdown.map(val => val.reported_link);
            this.breakdown = res.json().standalone_breakdown;
            if(this.pieData1.length == 0){
              this.pieData1 = null;
            }
          // }
          // else{
          //   //  this.countryName = '';
          //   this.pieData1 = null;
          // }
        }
        if(type == 'st_')
        {
          if(res.json().standalone_status){
            this.status = res.json().standalone_status.map(val => val.name);;
            this.pieData2 = res.json().standalone_status.map(val => val.count); 
            this.statusLabel = res.json().standalone_status;
            if(this.pieData2.length == 0){
              this.pieData2 = null;
            }
          }
        }
             
      })
    
    }
    
    onPageChange(page: number) {
      this.pager["current_page"] = page;
      this.pager["offset"] = this.itemPerPageCount * (page - 1);
      const totalItemsCount = this.pager["total_count"];
      const numberOfItemsPerPage = this.pager["limit"];
      const pages =this.pager["current_page"];
      this.numberOfPages = totalItemsCount;
      this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
      this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
    }

    onSearch(){
      this.pageBeforeSearch = this.pager["current_page"];   
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;   
      this.getStandaloneData(this.dashData,1);
    }

    clearSearch() {      
      this.dashData.searchkey='';
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;   
      // this.getStandaloneData(this.dashData,1); 
      this.getStandaloneData(this.dashData,this.pageBeforeSearch);     
    }

    ngAfterContentInit() {
      //Input search observable - to avoid the  onInputChange() firing whenever the input changes
      const searchInputObj = document.querySelector('#search-table');
      this.searchInputObservable$ = fromEvent(searchInputObj, 'keyup').throttleTime(1000);
      this.loaderService.display(true);
      // this.searchInputObservable$.subscribe((event: any) => {   
      //   this.loaderService.display(false);    
      //   this.onInputChange(event.target.value);
      // });  	
 
    }

    // Highlight legent and corresponding count in breakdown chart
    customizeBreakdownChart( selection,  pieData ) {   

      selection.select('path')
                .attr('stroke-width', 2)
                .attr('stroke', '#fff');
      selection.append('title')
        // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
        .text((datum, index) => this.titleCasePipe.transform(pieData[index].country) + ' : ' + pieData[index].reported_link);          
    }
    // Highlight legent and corresponding count in status chart
    customizeStatusChart( selection, pieLabel,pieData ) {   

      selection.select('path')
                .attr('stroke-width', 2)
                .attr('stroke', '#fff');
      selection.append('title')
        // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
        .text((datum, index) => this.titleCasePipe.transform(pieLabel[index]) + ' : ' + pieData[index]);          
    }
    onBreakDownChartUpdate(selection) {               
      this.customizeBreakdownChart(selection, this.breakdown);    
    }
    onStatusChartUpdate(selection) {         
      this.customizeStatusChart(selection,this.status,this.pieData2);    
    }

    ngAfterViewChecked() {     
    let mpTableWidth = $('.sw-table').width();
    // console.log('ngAfterViewChecked-',mpTableWidth);
    $('.scrollDummy div').width(mpTableWidth);   
    }

    onInputChange(text) {
      this.onSearch();
    }

    entryNoChange(value:number){
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      // this.dashData.limit = value;
      this.pager["limit"] = value;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.getStandaloneData(this.dashData,1);     
    }

    onSort(orderby: string, id: any) {
      if (this.dashData["orderby"] === orderby) {
        if (this.dashData["order"] === "asc") {
          this.dashData["order"] = "desc";
        } else {
          this.dashData["order"] = "asc";
        }
      } else {
        this.dashData["orderby"] = orderby;
        this.dashData["order"] = "desc"
      }
      //Reset the page to 1, offset to 0
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.listData = this.dashboardService.sortListData(this.listData, this.dashData["orderby"], this.dashData["order"]);
    }

    //Change date range
    selectedDate(value: any) {
      this.selectedDateRangeLabel = value.label;   
      this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);
      
      this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
      this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

      this.dashData.fromDate = this.datepickerOptions.startDate;
      this.dashData.toDate = this.datepickerOptions.endDate;

      this.localData.fromDate =   this.dashData.fromDate;
      this.localData.toDate = this.dashData.toDate;

      // this.searchBase.fromDate = this.datepickerOptions.startDate ;
      // this.searchBase.toDate = this.datepickerOptions.endDate;

      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];
      this.getStandaloneData(this.dashData,1);

      // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
      // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');
      // this.dashData.fromDate = this.daterange.start;
      // this.dashData.toDate = this.daterange.end;
      // this.localData.fromDate = this.daterange.start;
      // this.localData.toDate = this.daterange.end;
      // this.apiCallCount = 0;
      // this.pager["total_count"] = 0;
      // this.pager["current_page"] = 1;
      // this.pager["offset"] = 0;
      // this.offSet = 0;
      // this.listData = [];
      // this.getStandaloneData(this.dashData,1);     
    }

    //-----------------------------Single and double click handling----------------------------
    setHighlightRow(index:number,id:number,highlightStatus:boolean) {
      console.log(index+'-'+id+'-'+highlightStatus)
      this.isSingleClick = true;        
      setTimeout(()=>{                            
               if(this.isSingleClick){                                         
                  this.setHighlight(index,id,highlightStatus)
               }              
      },500)
    }
    denyHighlight(){
            this.isSingleClick = false;                                          
    }    
     
    //----------------------------------------Highlight Update function----------------------------------------
    setHighlight(index:number,id:number,highlightStatus:boolean) {              
      let updatedStatus:boolean;     
      let updateData = {}; 

      if(highlightStatus) 
      updatedStatus = false;
      else
      updatedStatus = true;           

      updateData['platform'] = 'stand_alone';
      updateData['id'] = id;
      updateData['updatedStatus'] = updatedStatus;
      
        //Upadte highlight status       
        this.loaderService.display(true);          
        this.dashboardService.updateHighlightStatus(updateData).subscribe((res:any)=>{            

          // if(JSON.parse(res._body).status == "success") {             
          if(res.status == 200) {          
            let highlightRowIndex = this.listData.findIndex(x => x.id ===id); 
            console.log('**RowIndex:',highlightRowIndex)                           
            this.listData[highlightRowIndex].highlight_status = updatedStatus;                
            this.loaderService.display(false);         
          }
          else {             
            this.loaderService.display(false);    
            // if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
              if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
              //this.toastr.error(this.errorDscrp,this.errorMsg);
              this.authServiceObj.logout(true);
            }
          }               
        });
     }       
    
    //-----------------------------------------------------------------------------------------------

    exportToCsv(){
      //Copy to variable to avoid original data mutation
      const swData = this.listData;

      //Format the data to avoid unwanted fields
      var keyMap = {
        // subdomain_date : 'SubdomainDate',
        // detection_date : 'DetectionDate',
        // language_area : 'LanguageArea',
        // market : 'Market',
        // search_engine : 'SearchEngine',
        // subdomain : 'Subdomain',
        // host : 'Host',
        // host_email : 'HostEmail',
        // country : 'Country',
        // status : 'Status',
        // notices_sent : 'NoticesSent',
        // ip_changed : 'IpChanged',
        // seller_id: 'SellerId',
        // seller_name: 'SellerName',
        // phone_number_1: 'PhoneNumber1',
        // phone_number_2: 'PhoneNumber2',
        // email_address_1: 'EmailAddress1',
        // email_address_2: 'EmailAddress2',
        // address: 'Address'
        subdomain_date : 'SubdomainDate',
        detection_date : 'DetectionDate',
        language_area : 'LanguageArea',
        market : 'Market',
        keyword : 'Keyword',
        search_engine : 'SearchEngine',
        subdomain : 'Subdomain',
        status : 'Status',
        host : 'Host',        
        host_email : 'HostEmail',
        country : 'Country',
        // status : 'Status',
        notices_sent : 'NoticesSent',
        ip_changed : 'IpChanged',
        registrar: 'Registrar',
        owner_name: 'OwnerName',
        phone_number_1: 'PhoneNumber1',
        phone_number_2: 'PhoneNumber2',
        email_address_1: 'EmailAddress1',
        email_address_2: 'EmailAddress2',
        address: 'Address',
        social_network_link: 'SocialMediaLink',
        marketplace_link: 'MarketplaceLink',
        // social_network_link: 'SocialMediaLink',
        standalone_website_link: 'StandaloneWebsiteLink'
      };

      var excelData = swData.map((obj) => {
        return _.mapKeys(obj, (value, key) => {
          return keyMap[key];
        });
      });

      const excelDataModified = excelData.map((datum, i) => {
        
        return {
          // Subdomain_Date: datum.SubdomainDate,
          // Detection_Date: datum.DetectionDate,
          // Language_Area: datum.LanguageArea,
          // Market: datum.Market,
          // Search_Engine: datum.SearchEngine,
          // Subdomain: datum.Subdomain,
          // Host: datum.Host,
          // Host_Email: datum.HostEmail,
          // Country: datum.Country,
          // Status: datum.Status,
          // Notices_Sent: datum.NoticesSent,
          // Ip_Changed: datum.IpChanged,
          // Seller_Id : datum.SellerId ? datum.SellerId:'',
          // Seller_Name : datum.SellerName ? datum.SellerName:'',
          // Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
          // Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
          // Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
          // Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
          // Address : datum.Address ? datum.Address:''
          // Subdomain_Date: datum.SubdomainDate,
          // Subdomain_Date: this.datepipe.transform(datum.SubdomainDate, 'yyyy/dd/MM'),
          Subdomain_Date: this.datepipe.transform(datum.SubdomainDate, 'dd/MM/yyyy'),
          // Detection_Date: datum.DetectionDate,
          Notice_Date: this.datepipe.transform(datum.DetectionDate, 'dd/MM/yyyy'),   
          // Notice_Date: this.datepipe.transform(datum.DetectionDate, 'yyyy/dd/MM'),          
          Language_Area: datum.LanguageArea ? datum.LanguageArea:'',
          Market: datum.Market ? datum.Market:'',
          Keyword: datum.Keyword ? datum.Keyword:'',
          Search_Engine: datum.SearchEngine ? datum.SearchEngine:'',
          Subdomain: datum.Subdomain ? datum.Subdomain: '',
          Status: datum.Status ? datum.Status:'',
          Host: datum.Host ? datum.Host:'',          
          Host_Email: datum.HostEmail ? datum.HostEmail:'',
          Country: datum.Country ? datum.Country:'',
          // Status: datum.Status,
          Notices_Sent: datum.NoticesSent ? datum.NoticesSent:'',
          Ip_Changed: datum.IpChanged ? datum.IpChanged:'',
          Registrar : datum.Registrar ? datum.Registrar:'',
          Owner_Name : datum.OwnerName ? datum.OwnerName:'',
          Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
          Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
          Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
          Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
          Address : datum.Address ? datum.Address:'',
          Social_Network_Link : datum.SocialMediaLink ? datum.SocialMediaLink:'',
          Marketplace_Link : datum.MarketplaceLink ? datum.MarketplaceLink:'',
          // Social_Media_Link : datum.SocialMediaLink ? datum.SocailMediaLink:'',
          Standalone_Website_Link : datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink:''
        }
      });
      const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.localData.toDate)
          let title_month = months[current_datetime.getMonth()];
          let title_year  = current_datetime.getFullYear();          
      //Export
      // new Angular2Csv(excelDataModified, 'Standalone_'+this.countryName+'Report',{ headers: Object.keys(excelDataModified[0])});
      // new Angular2Csv(excelDataModified, this.listData[0].brand_name +'-Standalone Websites-'+title_month+' '+title_year,{ headers: Object.keys(excelDataModified[0])});
      //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
      var activeBrands = this.lsService.retrieve('active_brand_id');
      if((activeBrands.length)>1) {
        new Angular2Csv(excelDataModified, 'All-Standalone Websites-'+title_month+' '+title_year,{ headers: Object.keys(excelDataModified[0])});
      } else {
        new Angular2Csv(excelDataModified, this.listData[0].brand_name +'-Standalone Websites-'+title_month+' '+title_year,{ headers: Object.keys(excelDataModified[0])});
      }
      
    }

    print() {
      const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let current_datetime = new Date(this.localData.toDate)
      let title_month = months[current_datetime.getMonth()];
      let title_year  = current_datetime.getFullYear();
      
      //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
      var activeBrands = this.lsService.retrieve('active_brand_id');
      if((activeBrands.length)>1) {
        var printTitle = 'All-Standalone Websites-'+title_month+' '+title_year;
      } else {
        var printTitle = this.listData[0].brand_name +'-Standalone Websites-'+title_month+' '+title_year;
      }

      let newWindow = window.open('', '', 'width=800, height=500'),
      document = newWindow.document.open(),
      pageContent =
      '<!DOCTYPE html>' +
      '<html>' +
      '<head>' +
      '<meta charset="utf-8" />' +
      // '<title>Social-Media-'+this.countryName+'</title>' +
      // '<title>'+this.listData[0].brand_name +'-Standalone Websites-'+title_month+' '+title_year+'</title>' +      
      '<title>'+printTitle+'</title>' +      
      '<style>table {border-collapse: collapse;} table, td, th { border: 1px solid black;}</style>'+
      '</head>' +
      '<body>' + '<table>'+
      '<thead>'+
      '<tr>'+
      '<th class="text-center">Subdomain Date </th>'+
      '<th class="text-center">Detection Date </th>'+
      '<th >Language Area </th>'+
      '<th >Market </th>'+
      '<th >Keyword </th>'+
      '<th >Search Engine </th>'+
      '<th >Subdomain </th>'+      
      '<th >Host </th>'+
      '<th >Host Email </th>'+
      '<th >Country </th>'+
      '<th >Status </th>'+
      '<th >Notices Sent </th>'+
      '<th >IP Changed</th>'+
      '<th > Seller Id </th>'+
      '<th > Seller Name </th>'+
      '<th > Phone Number #1 </th>'+
      '<th > Phone Number #2 </th>'+
      '<th > Email Address #1 </th>'+
      '<th > Email Address #2 </th>'+
      '<th > Address </th>'+
      '</tr>'+
      '</thead>'+
      '<tbody>';
      for(let i = 0; i< this.listData.length; i++){
        pageContent = pageContent + '<tr>'+
        '<td>'+this.datepipe.transform(this.listData[i].subdomain_date, 'dd/MM/yyyy')+'</td>'+
        '<td>'+this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy')+'</td>'+
        '<td>'+this.listData[i].language_area+'</td>'+
        '<td>'+this.listData[i].market+'</td>'+
        '<td>'+this.listData[i].keyword+'</td>'+
        '<td>'+this.listData[i].search_engine+'</td>'+
        '<td>'+this.listData[i].subdomain+'</td>'+        
        '<td>'+this.listData[i].host+'</td>'+
        '<td>'+this.listData[i].host_email+'</td>'+
        '<td>'+this.listData[i].country+'</td>'+
        '<td>'+this.listData[i].status+'</td>'+
        '<td class="text-right">'+this.listData[i].notices_sent+'</td>'+
        '<td class="text-right">'+this.listData[i].ip_changed+'</td>'+
        '<td>'+this.listData[i].seller_id+'</td>'+
        '<td>'+this.listData[i].seller_name+'</td>'+
        '<td>'+this.listData[i].phone_number_1+'</td>'+
        '<td>'+this.listData[i].phone_number_2+'</td>'+
        '<td>'+this.listData[i].email_address_1+'</td>'+
        '<td>'+this.listData[i].email_address_2+'</td>'+
        '<td>'+this.listData[i].address+'</td>'+
        '</tr>'

      }

      pageContent = pageContent+ '</tbody>'+
      '</table>' + 
      '</body></html>';
      try {
        document.write(pageContent);
        document.close();
        newWindow.print();
        newWindow.close();
      }
      catch (error) {
      }
    }

    copyToClipboard(){
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      this.copyValue = 'Subdomain date, Detection date, Language Area, Market, Subdomain, Host, Host Email, Country, Status, Notices Sent, IP Changed, Seller Id, Seller Name, Phone Number #1, Phone Number #2, Email Address #1, Email Address #2, Address  \n';
      for( let i = 0; i < this.listData.length; i++ ){
        this.copyValue = this.copyValue + ( this.datepipe.transform(this.listData[i].subdomain_date, 'dd/MM/yyyy') + ', ' + this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy')
          + ', ' + this.listData[i].language_area + ', ' + this.listData[i].market + ', ' + this.listData[i].search_engine + ', ' +
          this.listData[i].subdomain + ', ' + this.listData[i].host + ', ' + this.listData[i].host_email + ', ' + 
          this.listData[i].country + ', ' + this.listData[i].status +  this.listData[i].notices_sent + ', ' +
          this.listData[i].ip_changed + ', ' + 
          this.listData[i].seller_id + ', '+this.listData[i].seller_name + ', '+this.listData[i].phone_number_1 + ', '+
          this.listData[i].phone_number_2 + ', '+this.listData[i].email_address_1 + ', '+this.listData[i].email_address_2 + ', '+
          this.listData[i].address + ', '+' \n') ;            
      }
      selBox.value = this.copyValue;
      document.body.appendChild(selBox);    
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    openModal(template: TemplateRef<any>, data) {
      this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
      this.screenShots = data;
    }
    openModal2(template: TemplateRef<any>, id: number) {
      setTimeout(() => { this.activeIndex = (id); }, 0);
      this.modalRef2 = this.modalService.show(template, { class: 'second' });
    }

    //two scroll

    // scroll() {
    //   this.table.nativeElement.scrollTo(this.scroller.nativeElement.scrollLeft,0)
    //  }

    //  scroll1() {
    //    this.scroller.nativeElement.scrollTo(this.table.nativeElement.scrollLeft,0)
    //   }

      //export to xlsx

      fireEvent()
      {
         //Copy to variable to avoid original data mutation
      const swData = this.listData;

      //Format the data to avoid unwanted fields
      var keyMap = {
        subdomain_date : 'SubdomainDate',
        detection_date : 'DetectionDate',
        language_area : 'LanguageArea',
        market : 'Market',
        keyword : 'Keyword',
        search_engine : 'SearchEngine',
        subdomain : 'Subdomain',
        status : 'Status',        
        host : 'Host',        
        host_email : 'HostEmail',
        country : 'Country',
        // status : 'Status',
        notices_sent : 'NoticesSent',
        ip_changed : 'IpChanged',
        registrar: 'Registrar',
        owner_name: 'OwnerName',
        phone_number_1: 'PhoneNumber1',
        phone_number_2: 'PhoneNumber2',
        email_address_1: 'EmailAddress1',
        email_address_2: 'EmailAddress2',
        address: 'Address',
        social_network_link: 'SocialMediaLink',
        marketplace_link: 'MarketplaceLink',
        // social_network_link: 'SocialMediaLink',
        standalone_website_link: 'StandaloneWebsiteLink'
      };

      var excelData = swData.map((obj) => {
        return _.mapKeys(obj, (value, key) => {
          return keyMap[key];
        });
      });

      const excelDataModified = excelData.map((datum, i) => {       
        return {
          // Subdomain_Date: datum.SubdomainDate,
          // Subdomain_Date: this.datepipe.transform(datum.SubdomainDate, 'yyyy/dd/MM'),
          Subdomain_Date: this.datepipe.transform(datum.SubdomainDate, 'dd/MM/yyyy'),
          // Detection_Date: datum.DetectionDate,
          Notice_Date: this.datepipe.transform(datum.DetectionDate, 'dd/MM/yyyy'),   
          // Notice_Date: this.datepipe.transform(datum.DetectionDate, 'yyyy/dd/MM'),          
          Language_Area: datum.LanguageArea ? datum.LanguageArea:'',
          Market: datum.Market ? datum.Market:'',
          Keyword: datum.Keyword ? datum.Keyword:'',
          Search_Engine: datum.SearchEngine ? datum.SearchEngine:'',
          Subdomain: datum.Subdomain ? datum.Subdomain:'',
          Status: datum.Status ? datum.Status:'',          
          Host: datum.Host ? datum.Host:'',          
          Host_Email: datum.HostEmail ? datum.HostEmail:'',
          Country: datum.Country ? datum.Country:'',
          // Status: datum.Status,
          Notices_Sent: datum.NoticesSent ? datum.NoticesSent:'',
          Ip_Changed: datum.IpChanged ? datum.IpChanged:'',
          Registrar : datum.Registrar ? datum.Registrar:'',
          Owner_Name : datum.OwnerName ? datum.OwnerName:'',
          Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
          Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
          Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
          Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
          Address : datum.Address ? datum.Address:'',
          Social_Network_Link : datum.SocialMediaLink ? datum.SocailMediaLink:'',
          Marketplace_Link : datum.MarketplaceLink ? datum.MarketplaceLink:'',
          // Social_Media_Link : datum.SocialMediaLink ? datum.SocailMediaLink:'',
          Standalone_Website_Link : datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink:''
        }
      });     
        // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
        // /* save to file */
        // XLSX.writeFile(wb,'Standalone_'+this.countryName+'Report.xlsx');

        // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified,{ dateNF :'dd/MM/yyyy',cellDates : true});
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified,{ cellDates:true, });
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        // XLSX.writeFile(workbook, 'Standalone_'+this.countryName+'Report.xlsx', { bookType: 'xlsx', type: 'buffer' });
        // XLSX.writeFile(workbook,'Standalone-'+this.localData.toDate+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
        
        const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.localData.toDate)
          let title_month = months[current_datetime.getMonth()];
          let title_year  = current_datetime.getFullYear();
        // XLSX.writeFile(workbook, this.listData[0].brand_name +'-Standalone Websites-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer',cellDates: false });
        //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
        var activeBrands = this.lsService.retrieve('active_brand_id');
        if((activeBrands.length)>1) {
          XLSX.writeFile(workbook, 'All-Standalone Websites-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer',cellDates: false });
        } else {
          XLSX.writeFile(workbook, this.listData[0].brand_name +'-Standalone Websites-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer',cellDates: false });
        }
      }




  }
