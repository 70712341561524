import { Component, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { UserService } from '../../services/user/user.service';
import { AppSettings } from '../../settings.global';
import * as moment from 'moment';
declare var $:any;
import { CompanyService } from '../../services/company/company.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ImageCropperComponent, CropperSettings, Bounds} from 'ng2-img-cropper';

import { MessageServiceService } from '../../services/message-service/message-service.service';
import { BrandService } from '../../services/brand/brand.service';
import {LoaderService} from '../../services/loader/loader.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Output() refreshHeader: EventEmitter<any> = new EventEmitter();

  modalRef: BsModalRef;

  public localDataUser: any;
  public localDatabrand: any;
  public user: any;
  public companyDetails: any;
  public userDetails: any;
 // public companyImage: any;
  cropperSettings1:CropperSettings;
  public img_url = AppSettings.IMAGE_BASE_URL;
  daterange: any = {
     start: '',
     end : ''
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()]
    },
    opens: 'right',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    }
  };

  public requests: any;
  public deleteRequest: any;
  public requestId: any;
  public old_password: any;
  public new_password: any;
  public cnf_password: any;
  public submitted: any;
  public company_image: any;
  public user_image: any;
  public brandCount: number=0;
  public imageData:any;
  public imageChanged: any;
  public recoverRequest: any;
  public dateRangeError: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  modalRef2: BsModalRef;
  

  constructor(private lsService: LocalStorageService,
  private userService: UserService,
  private toastr: ToastrService,
  private companyService: CompanyService,
  private loaderService:LoaderService,
  private modalService: BsModalService,
  private brandService: BrandService,  
  private MsgService: MessageServiceService,
  private authServiceObj: AuthService ) { 
    this.localDataUser = this.lsService.retrieve('web_user_details');
    this.localDatabrand = JSON.parse(this.lsService.retrieve('search_base'));
    this.user = {
      brand : this.lsService.retrieve('active_brand_id'),
      user : this.localDataUser.id
    };
    this.companyDetails = [];
    this.userDetails = [];
    this.requests = [];
    this.requestId = [];
    this.old_password = '';
    this.new_password = '';
    this.cnf_password = '';
    this.submitted = false;
    this.imageData = {};
    this.company_image = {
      client_id : this.localDataUser.client_id,
      user_id : this.localDataUser.id,
      client_logo : ''
    };
    this.user_image = {
      user_id : this.localDataUser.id,
      profile_pic : ''
    };
    this.recoverRequest = {
      request_id : '',
      sender_id : this.localDataUser.id
    };
    this.dateRangeError = false;    

    //Image cropper
    this.cropperSettings1 = new CropperSettings();
    this.cropperSettings1.width = 200;
    this.cropperSettings1.height = 200;
    this.cropperSettings1.keepAspect = false;

    this.cropperSettings1.croppedWidth = 400;
    this.cropperSettings1.croppedHeight = 400;

    this.cropperSettings1.canvasWidth = 330;
    this.cropperSettings1.canvasHeight = 200;

    this.cropperSettings1.minWidth = 100;
    this.cropperSettings1.minHeight = 100;

    this.cropperSettings1.rounded = false;
    this.cropperSettings1.minWithRelativeToResolution = false;

    this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;

    this.imageChanged = false;
    
  }

  ngOnInit() {
    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
    //   this.loaderService.display(false);
    //   this.user.brand = id;
    //   this.getUserData(this.user);
    //   this.getDeleteDataRequest(this.user.brand);
    // });
    this.getUserData(this.user);
    this.getDeleteDataRequest(this.user.brand);
  }

  getUserData(user:any){
    this.loaderService.display(true);
    this.userService.getUserDataProfile(user).subscribe( (res) => {
      this.loaderService.display(false);
      this.companyDetails = res.json().company_profile;
      this.userDetails = res.json().user_profile;
      this.brandCount =(this.lsService.retrieve('active_brand_id')).length;
      if(this.imageChanged == true){
        this.localDataUser.logo = this.companyDetails.logo_url;
        this.localDataUser.profile_pic = this.userDetails.profile_pic;
        this.lsService.store('web_user_details', this.localDataUser);
        this.MsgService.sendMessage('Change Logo');
        this.imageChanged == false;
      }

    })
  }

  getDeleteDataRequest(brandId:any){
    this.loaderService.display(true);
    this.companyService.getDeleteDataRequests(brandId).subscribe( (res) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        this.requests = res.json().request_result;
       }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }
      
    })
  }

  selectedDate(value: any) {
    this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
    this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');
    this.dateRangeError = false;
  }

  deleteDataRequest(){
    if(this.daterange.start != '' && this.daterange.end != ''){
        this.deleteRequest = {
        brand : this.user.brand,
        user_id : this.user.user,
        fromDate : this.daterange.start,
        toDate : this.daterange.end
        };
        this.loaderService.display(true);
        this.companyService.deleteDataRequests(this.deleteRequest).subscribe( (res) => {
          this.loaderService.display(false);
            if(res.statusText == 'OK'){
              this.toastr.success('Delete Request Submitted');
            }
            this.getDeleteDataRequest(this.deleteRequest.brand);
        });
        
    }else{
      this.dateRangeError = true;
    }
    
  }

  cancelDeleteRequest(id:any){
    var cnf=confirm("Are you sure you want to cancel this Request?");
    if(cnf){
      this.requestId = {
        request_id : id
      };
      this.loaderService.display(true);
      this.companyService.cancelDeleteRequest(this.requestId).subscribe( (res) => {
        this.loaderService.display(false);
        if(res.statusText == 'OK'){
          this.toastr.success('Delete Request Cancelled');
        }
        this.getDeleteDataRequest(this.user.brand);     
      });
    }   
  }

  changePassword(valid:any){
    this.submitted=true;
    if(valid){
      if(this.new_password!==this.cnf_password){
        this.toastr.error('New password and confirm password do not match')
       // this.modalErrorMessage="New password and confirm password do not match";
        return false;
      }
    }
    let request_params: any = {
			cur_password: this.old_password,
			new_password: this.new_password,
			id: this.user.user
    }
    this.loaderService.display(true);
    this.authServiceObj.changePassword(request_params).subscribe((res: any) => {
      this.loaderService.display(false);
      if (res.status == "success") {
        this.submitted = false;
        this.toastr.success("Password Changed Successfully");
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }

    });

  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    $("#userLogo canvas").hide();
    $("#clientLogo canvas").hide();
  }
  logoCroppedClient(event:any){
    $("#clientLogo canvas").show();
    this.company_image.client_logo = this.imageData.image;
  }
  changeCompanyImage(){
    this.loaderService.display(true);
    this.companyService.changeCompanyImage(this.company_image).subscribe((res: any) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        this.modalRef.hide();
        this.imageData = {}; 
        this.getUserData(this.user);
        this.imageChanged = true;
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }      
    });
    //remove
    this.modalRef.hide();
    this.imageData = {};
  }
  logoCroppedUser(event:any){
    $("#userLogo canvas").show();
    this.user_image.profile_pic = this.imageData.image;
  }

  changeUserImage(){
    this.loaderService.display(true);
    this.userService.changeUserImage(this.user_image).subscribe( (res) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        this.modalRef.hide();
        this.imageData = {};
        this.getUserData(this.user);
        this.imageChanged = true;
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }       
    })
    this.modalRef.hide();
    this.imageData = {};
  }

  recoverDeletedData(id:any){
    var cnf=confirm("Are you sure you want to recover the deleted file?");
    if(cnf){
      this.recoverRequest.request_id = id;
      this.loaderService.display(true);
      this.companyService.recoverDeletedData(this.recoverRequest).subscribe( (res) => {
        this.loaderService.display(false);
        if(res.statusText == 'OK'){
          this.toastr.success('Deleted Data has been Recovered');
        }
        this.getDeleteDataRequest(this.user.brand);     
      });
    }   

  }

  openModalvedio(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  hideModal() {
    this.modalRef.hide();
  }

}
