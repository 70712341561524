import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class SocialmediaService {

  public headers: Headers;

  constructor(private http: Http, private localSt: LocalStorageService) { 
    this.headers = new Headers();
  }

  getMotherCompanies() {
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/mother-company/social_media',options);
  }

  getSubCompanies(id) {
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/sub-company?type=social_media&mother_company=' + id + '',options);
  }

  getSocialMediaData(searchBase: {
    brandId: Number,
    motherCompanyId: number,
    socialMediaId: number,
    fromDate: string,
    toDate: string
  } ) {
    let headers = new Headers();
		this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
		this.headers.append('Accept', 'application/json');
		let options = new RequestOptions({headers: this.headers});
    // tslint:disable-next-line:max-line-length
    return this.http.get(AppSettings.API_ENDPOINT + `/dashboard/social-media?brand=${searchBase.brandId}&mother_company=${searchBase.motherCompanyId}&social_media=${searchBase.socialMediaId}&fromDate=${searchBase.fromDate}&toDate=${searchBase.toDate}`,options);
  }

}
