import { Component, OnInit, ElementRef, TemplateRef, ViewChild, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import * as moment from 'moment';
import * as D3 from 'd3';
import * as _ from 'lodash';
import { Observable, fromEvent, interval } from 'rxjs/';
import { map } from 'rxjs/operators'
import { NgxPaginationModule } from 'ngx-pagination';//importing ng2-pagination
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../settings.global';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BrandService } from '../../services/brand/brand.service';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import {DatePipe} from '@angular/common';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';
import { TitleCasePipe } from '@angular/common';
import { BootstrapSelectDirective } from 'src/app/directives/bootstrap-select/bootstrap-select.directive';


@Component({
  selector: 'app-marketplace-dashboard',
  templateUrl: './marketplace-dashboard.component.html',
  styleUrls: ['./marketplace-dashboard.component.scss'],
  providers: [DatePipe,TitleCasePipe],
  
})

export class MarketplaceDashboardComponent implements OnInit {

  @ViewChild('printSection') myChart: ElementRef;

  @ViewChild('scroller') scroller: ElementRef; 
  //@ViewChild('printSection') table: ElementRef;

  @ViewChild(BootstrapSelectDirective) motherCompanyDropDown:BootstrapSelectDirective;

  modalRef: BsModalRef;
  modalRef2: BsModalRef;

  public motherCompany : any;
  public mCompanyId : any;
  public mCompanySelector : any;
  public marketPlace : any;
  public localData : any;
  public dashData : any;
  public successData : any;
  public reported : any;
  public disabled : any;
  public breakdown : any;
  public pieData1 : any;
  public colors : any;
  public marketPlaceId : any;
  public chartOption: object;
  public labelData: any;
  public pieData2 : any;
  public status : any;
  public listData : any;
  public successRate: any;
  public no_of_entry: any;
  public numberOfPages: any;
  public start: any;
  public end: any;
  public screenShots: any;
  public copyValue: any;
  public top: any;
  public isSingleClick : boolean;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public pageBeforeSearch:any;
  public statusLabel:any;

  public breakdown_selected_brand_list_data :any;
  public status_selected_brand_list_data :any;

   timer: any = 0;
   delay:any = 200;
   prevent:boolean = false;

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,      
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()]        
    },
   
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };
  public request_params:any;
  public pager:any;
  public queryLimit: number = AppSettings.DEFAULT_QUERY_LIMIT;
  public itemPerPageCount: number = AppSettings.DEFAULT_PAGER_COUNT;
  public img_url = AppSettings.IMAGE_BASE_URL;
  public screenshot_url = AppSettings.SCREENSHOT_BASE_URL
  public apiCallCount: number = 0;
  public offSet: number = 0;
  public searchInputObservable$: any;
  public activeIndex: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public mcompany_exists:boolean=false;
  public selectedDateRangeLabel : any;
  public selected_brand_list_data: any;

  constructor(private dashboardService: DashboardService,
    private element: ElementRef,
    private lsService: LocalStorageService,
    public datepipe: DatePipe,
    private loaderService:LoaderService,
    private brandService: BrandService,
    private toastr: ToastrService,
    private authServiceObj:AuthService,
    private modalService: BsModalService,
    private titleCasePipe:TitleCasePipe,
    private cd:ChangeDetectorRef) {
    this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel');    
    this.motherCompany = [];
    this.mCompanySelector ={
      id : '',
      type : 'market_place',
      is_mcommerce : false,
      is_ecommerce : false
    }
    this.marketPlace = [];

    var selectedBrandsDetails = [];
    
    // selectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));
    var unsortedSelectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));  
    //Sort the list of brands selected in Same order of dropdown selected
     selectedBrandsDetails = unsortedSelectedBrandsDetails.sort((a, b) => (a.name > b.name) ? 1 : -1) 
    
     if(!selectedBrandsDetails || selectedBrandsDetails == null)
    {
      selectedBrandsDetails = [];
    }

    // var selectedSummaryObj = {id:0, name:'All Selected',spcialClass:'active-brands-tab'};
    // if(selectedBrandsDetails.length > 1)
    // {
    //   selectedBrandsDetails.unshift(selectedSummaryObj);
    // }
    // if(selectedBrandsDetails.length == 1)
    // {
    //   selectedBrandsDetails[0].spcialClass = 'active-brands-tab';
    // }
    // this.selected_brand_list_data = selectedBrandsDetails;

    //--------------------Edited------------------------------
    var breakdown_selectedSummaryObj = {id:0, name:'All Breakdown',spcialClass:'active-brands-tab '};
    var status_selectedSummaryObj = {id:0, name:'Status',spcialClass:'active-brands-tab '};
    // Deep clone
    var breakdown_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));
    var status_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));

    if(selectedBrandsDetails.length >= 1)
    {
      breakdown_selectedBrandsDetails.unshift(breakdown_selectedSummaryObj);
      status_selectedBrandsDetails.unshift(status_selectedSummaryObj);
    }    
    this.breakdown_selected_brand_list_data = breakdown_selectedBrandsDetails;
    this.status_selected_brand_list_data = status_selectedBrandsDetails;
    //--------------------Edited------------------------------


    this.localData = JSON.parse(this.lsService.retrieve('search_base'));
    this.dashData = {
      brand : this.lsService.retrieve('active_brand_id'),
      mother_company : '',
      market_place : '',
      fromDate : this.localData.fromDate,
      toDate : this.localData.toDate,
      searchkey: "",
      orderby: "",
      order: "",
      limit: this.queryLimit,
      offset: 0
    }
    this.daterange.start = this.localData.fromDate;
    this.daterange.end = this.localData.toDate;
    this.successData = [];
    this.breakdown = [];
    this.pieData1 = [];
    //this.colors = ['#a78e0c', '#d7b70f', '#f0d028', '#f3da58', '#f7e588', '#faefb7']; 
    this.colors = ['#4d4d00','#808000','#b3b300','#cccc00','#d7b70f','#f0d028'];
    this.marketPlaceId = '';
    this.labelData = [];
    this.statusLabel = [{name:'ON'},{name:'OFF'}];         
    this.pieData2 = [];
    this.status = [];
    this.listData = [];
    this.pager = {
      limit: this.itemPerPageCount,
      offset: 0,
      total_count: 0,
      current_page: 1
    }
    this.reported = 0;
    this.disabled = 0;
    this.successRate = 0;
    this.no_of_entry = 50;
    this.screenShots = [];
    this.activeIndex = 0;
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  this.localData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.localData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;

    this.datepickerOptions.startDate =  new Date(this.localData.fromDate);
    this.datepickerOptions.endDate = new Date(this.localData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate)  
  }

setMotherAndSubCompanies()
{
  console.log(this.dashData);
  this.dashboardService.getMotherCompany('market_place',this.dashData.brand,this.dashData.fromDate,this.dashData.toDate).subscribe( (res) =>{
    if(res.json().status == "success"){
      this.motherCompany = res.json().data_found;
      this.top = JSON.parse(this.lsService.retrieve('top1'))
          for(let mcompany of this.motherCompany){
            if(mcompany.id==this.lsService.retrieve('top_market_place')){
              this.mcompany_exists=true;
            }
          }
          if(this.mcompany_exists){
            this.mCompanyId = (this.lsService.retrieve('top_market_place'))?this.lsService.retrieve('top_market_place'):0;
            this.mCompanySelector.id = (this.lsService.retrieve('top_market_place'))?this.lsService.retrieve('top_market_place'):0;
          }else{
            this.mCompanyId =0 
            this.mCompanySelector.id = 0
          }
          this.lsService.store('top_market_place', this.mCompanyId);
          if(this.mCompanySelector.id=='m_commerce'){
            this.mCompanySelector.is_mcommerce=true;
            this.mCompanySelector.id=0;
          }
          if(this.mCompanySelector.id=='e_commerce'){
            this.mCompanySelector.is_ecommerce=true;
            this.mCompanySelector.id=0;
          }

          this.mCompanySelector.from_date=this.localData.fromDate;
          this.mCompanySelector.to_date=this.localData.toDate;
          this.mCompanySelector.brand_ids=this.dashData.brand;
          this.dashboardService.getMotherCompanyExt(this.mCompanySelector).subscribe((res) => {
            this.marketPlace = res.json().data_found;
            if(this.mCompanyId!='0')
              this.marketPlaceId=(this.lsService.retrieve('top_market_place_sub'))?this.lsService.retrieve('top_market_place_sub'):'';
            this.dashData.market_place =this.marketPlaceId;
          });
          

          this.dashData['mother_company'] = (this.mCompanyId=='m_commerce' || this.mCompanyId=='e_commerce')?null:this.mCompanyId;
          this.dashData['dash_board_data_type'] = this.getDashboardDataType();
          this.getMarketPlaceData(this.dashData,1);
        }
        else{
          this.mCompanyId =0;
          this.lsService.store('top_market_place', this.mCompanyId);
          this.loaderService.display(false);
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            this.authServiceObj.logout(true);
          }
        }  
        
      });  

     
        
}

  ngOnInit() {
    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
      //   this.loaderService.display(false);
      //   this.dashData.brand = id;
      //  // this.dashData.market_place = '';
      //   this.apiCallCount = 0;
      //   this.pager["total_count"] = 0;
      //   this.pager["current_page"] = 1;
      //   this.pager["offset"] = 0;
      //   this.offSet = 0;
      //   this.listData = [];
      //   this.getMarketPlaceData(this.dashData,1);
      // });
      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];
      //this.getMarketPlaceData(this.dashData,1);
      //get mothercompanies
      this.loaderService.display(true);
      
          this.setMotherAndSubCompanies();
          
          //Scrolling the top scroll bar while bottom one is scrolling and vice vrersa.
          $(function(){
              $(".scroll-main").scroll(function() {              
                  $(".scrollDummy").scrollLeft($(".scroll-main").scrollLeft());
              });

              $(".scrollDummy").scroll(function(){                
                  $(".scroll-main").scrollLeft($(".scrollDummy").scrollLeft());
              });
          });

          setTimeout(function(){      
            var coverWidth = document.getElementById('sm_brndListCoverMain').offsetWidth; 
            var contentWidth = document.getElementById('ListingBrands').offsetWidth;
            if(contentWidth <= coverWidth)
            {
              var all = document.getElementsByClassName('brnadlist-slider-icon');
              for (var i = 0; i < all.length; i++) {
                all[i].className += " hide-content"; 
              }
            }
          }, 500);
    }

     getDashboardDataType()
      {
        let metype='';

        if(this.mCompanySelector.is_mcommerce)
        {
          metype='m_commerce';
        }
        else if (this.mCompanySelector.is_ecommerce)
        {
          metype='e_commerce';
        }
        else
        {
          metype='market_place';
        }

        return metype;
      }

    // function for scroll brands tab
    //Added By : Arif
    slideBrand(position:any, type:any)
    {
      //alert(type);
      const conent = document.querySelector('#'+type+'brndListCoverMain');
      if(position == 'right') conent.scrollLeft += 100;
      if(position == 'left') conent.scrollLeft -= 100;
      event.preventDefault();
      //alert(position);
    }
    // function to display chart for markrt places based on tabs selected
    //Added By : Arif
    viewBrandsTabData(index:any, type:string){
      var previousSelectedId = document.querySelector("."+type+"active-brands-tab").id;
      //alert(type+'selectedBrand_'+index);
      document.getElementById(previousSelectedId).classList.remove(type+"active-brands-tab","active-brands-tab");
      document.getElementById(type+'selectedBrand_'+index).classList.add(type+"active-brands-tab","active-brands-tab");
      
      // Section to change chart content
      this.lsService.store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true); 
      
      var apiRequestData = this.dashData;
      if(index != 0)
      {
        apiRequestData.brand = [index];
      }
      else{
        apiRequestData.brand = this.lsService.retrieve('active_brand_id');
      }
      this.dashData['dash_board_data_type'] = this.getDashboardDataType();
      this.dashboardService.getMarketPlaceData(apiRequestData).subscribe( (res) => {
        // this.successData = res.json().success_rate;
        // this.reported = this.successData.reported_link;
        // this.disabled = this.successData.disabled_link;
        let reportedCount = res.json().success_rate.reported_link;
        let disabledCount = res.json().success_rate.disabled_link;

        this.loaderService.display(false);
          if(type == 'sa_')
          {            
            this.pieData1 = res.json().market_place_breakdown.data.map(val => val.reported_link );
            this.labelData = res.json().market_place_breakdown.data;  
            if(this.pieData1.length == 0){
              this.pieData1 = null;
            }          
        }
        if(type == 'st_')
        {
          // this.labelData = res.json().market_place_breakdown.data;      
          // if(this.reported != 0 || this.disabled != 0){
            if(reportedCount != 0 || disabledCount != 0) {
            this.status = ['ON', 'OFF'];
            this.pieData2 = [(reportedCount - disabledCount), disabledCount];  
          } else {
            this.status = [];
            this.pieData2 = null;
            // this.reported = 0;
            // this.disabled = 0;
          }
        }
             
      })
    
    }
    mCompanyChange(id){
      this.lsService.store('top_market_place', id);
      if(id == 'm_commerce'){
        this.mCompanySelector ={
          id : 5,
          type : 'market_place',
          is_mcommerce : true,
          is_ecommerce : false
        }
        this.dashData['mother_company'] = '';
      }
      else if(id == 'e_commerce'){
        this.mCompanySelector ={
          id : 5,
          type : 'market_place',
          is_mcommerce : false,
          is_ecommerce : true
        }
        this.dashData['mother_company'] = '';
      }
      else{
        this.mCompanySelector ={
          id : id,
          type : 'market_place',
          is_mcommerce : false,
          is_ecommerce : false
        }
        this.dashData['mother_company'] = id;
      }

              this.mCompanySelector.from_date=this.localData.fromDate;
              this.mCompanySelector.to_date=this.localData.toDate;
              this.mCompanySelector.brand_ids=this.dashData.brand;

      this.loaderService.display(true);
      this.dashboardService.getMotherCompanyExt(this.mCompanySelector).subscribe((res) => {
        this.loaderService.display(false);
        this.marketPlaceId = '';  
        this.marketPlace = res.json().data_found;
        this.apiCallCount = 0;
        this.pager["total_count"] = 0;
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.offSet = 0;
        this.listData = [];  
        this.dashData['dash_board_data_type'] = this.getDashboardDataType();
        this.dashData.market_place ='';
        this.getMarketPlaceData(this.dashData,1);

      })
    }

    onPageChange(page: number) {
      this.pager["current_page"] = page;
      this.pager["offset"] = this.itemPerPageCount * (page - 1);
      const totalItemsCount = this.pager["total_count"];
      const numberOfItemsPerPage = this.pager["limit"];
      const pages =this.pager["current_page"];
      this.numberOfPages = totalItemsCount;
      this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
      this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
    }

    marketPlaceChange(id){
      this.lsService.store('top_market_place_sub', id);
      this.dashData.market_place = id;
      this.apiCallCount = 0;
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.offSet = 0;
      this.listData = [];
      this.dashData['dash_board_data_type'] = this.getDashboardDataType();
      this.getMarketPlaceData(this.dashData,1);
    }

    getMarketPlaceData(data,page: number){
      this.lsService. store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true);      
      this.dashboardService.getMarketPlaceData(this.dashData).subscribe( (res) => {
        
console.log(this.dashData);
        this.apiCallCount++;      
        this.successData = res.json().success_rate;
        this.reported = this.successData.reported_link;
        this.disabled = this.successData.disabled_link;
        this.successRate = Math.round(this.successData.success_rate);
        this.breakdown = res.json().market_place_breakdown;

        if(res.json().market_place_list.status == 'success' && !_.isNull(res.json().market_place_list.data)){
          this.pager["total_count"] = this.listData.length + res.json().market_place_list.data.length;
          this.pager["current_page"] = page;
          this.listData = res.json().market_place_list.data; 
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages =this.pager["current_page"];
          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, parseInt(totalItemsCount));
          if(!_.isNil(res.json().market_place_list.max_possible_res_length) && !_.isNull(res.json().market_place_list.data) && res.json().market_place_list.max_possible_res_length > this.listData.length+1) {
            this.offSet = this.apiCallCount * this.queryLimit;
            this.getMarketPlaceData(this.dashData,1);
          }else{            
            this.loaderService.display(false);
            this.pieData1 = res.json().market_place_breakdown.data.map(val => val.reported_link );
            if(this.pieData1.length == 0){
              this.pieData1 = null;
            }
            this.labelData = res.json().market_place_breakdown.data;      
            if(this.reported != 0 || this.disabled != 0){
              this.status = ['ON', 'OFF'];
              this.pieData2 = [(this.reported - this.disabled), this.disabled];  
            } else{
              this.status = [];
              this.pieData2 = null;
              this.reported = 0;
              this.disabled = 0;
            }
          }    
        }else{
          this.loaderService.display(false);
          if(this.listData.length === 0 || res.json().market_place_list.max_possible_res_length === 0) {
            this.pieData1 = null;
            this.status = [];
            this.pieData2 = null;
            this.reported = 0;
            this.disabled = 0; 
            this.listData = [];
            this.pager["total_count"] = 0;
            this.pager["current_page"] = page;
            const totalItemsCount = this.pager["total_count"];
            const numberOfItemsPerPage = this.pager["limit"];
            const pages =this.pager["current_page"];
            this.numberOfPages = totalItemsCount;
            this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
            this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          }
          if(res.json().market_place_list.message == "invalid_accesstoken" || res.json().market_place_list.message == "un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }       
      })
      setTimeout(() => {
        this.motherCompanyDropDown.refresh();
      }, 3000);
    }

    onSearch2() {
      let page = 1;
      this.pageBeforeSearch = this.pager["current_page"];    
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;   
      this.lsService. store('search_base', JSON.stringify(this.localData));
      this.dashData['offset'] = this.offSet;
      this.loaderService.display(true);    
      this.dashData['dash_board_data_type'] = this.getDashboardDataType();
      this.dashboardService.getMarketPlaceData(this.dashData).subscribe( (res) => {        
      this.apiCallCount++;      

        if(res.json().market_place_list.status == 'success' && !_.isNull(res.json().market_place_list.data)){
          this.pager["total_count"] = this.listData.length + res.json().market_place_list.data.length;
          this.pager["current_page"] = page;
          
          this.listData = res.json().market_place_list.data; 
          const totalItemsCount = this.pager["total_count"];
          const numberOfItemsPerPage = this.pager["limit"];
          const pages =this.pager["current_page"];
          this.numberOfPages = totalItemsCount;
          this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
          this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, parseInt(totalItemsCount));
          if(!_.isNil(res.json().market_place_list.max_possible_res_length) && !_.isNull(res.json().market_place_list.data) && res.json().market_place_list.max_possible_res_length > this.listData.length+1) {
            this.offSet = this.apiCallCount * this.queryLimit;
          }else{            
            this.loaderService.display(false);
    
          }    
        }//res.json().market_place_list.status == 'success'
        else{
          this.loaderService.display(false);
          if(this.listData.length === 0 || res.json().market_place_list.max_possible_res_length === 0) {
            
            this.listData = [];
            this.pager["total_count"] = 0;
            this.pager["current_page"] = page;
            const totalItemsCount = this.pager["total_count"];
            const numberOfItemsPerPage = this.pager["limit"];
            const pages =this.pager["current_page"];
            this.numberOfPages = totalItemsCount;
            this.start = (pages * parseInt(numberOfItemsPerPage)) - (parseInt(numberOfItemsPerPage) - 1);
            this.end = Math.min(this.start + parseInt(numberOfItemsPerPage) - 1, totalItemsCount);
          }//if(this.listData.length === 0 || 
          if(res.json().market_place_list.message == "invalid_accesstoken" || res.json().market_place_list.message == "un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }       
      })//end-getMarketPlaceData

    }
    onSearch(){          
      this.pageBeforeSearch = this.pager["current_page"];    
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0; 
      this.dashData['dash_board_data_type'] = this.getDashboardDataType();
      this.getMarketPlaceData(this.dashData,1);     
    }

    clearSearch() {      
      this.dashData.searchkey='';
      this.listData = [];
      this.apiCallCount = 0;
      this.offSet = 0; 
      this.pager["total_count"] = 0;
      this.pager["current_page"] = 1;
      this.pager["offset"] = 0;
      this.dashData['dash_board_data_type'] = this.getDashboardDataType();
      this.getMarketPlaceData(this.dashData,this.pageBeforeSearch);    
       
      // this.getMarketPlaceData(this.dashData,1);      
      // this.pager["current_page"] = this.pageBeforeSearch;
    }

  // Highlight legent and corresponding count in breakdown chart
  customizeBreakdownChart( selection,  pieData ) {   

    selection.select('path')
              .attr('stroke-width', 2)
              .attr('stroke', '#fff');
    selection.append('title')
      // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
      .text((datum, index) => this.titleCasePipe.transform(pieData[index].url) + ' : ' + pieData[index].reported_link);          
  }
  // Highlight legent and corresponding count in status chart
  customizeStatusChart( selection, pieLabel,pieData ) {   

    selection.select('path')
              .attr('stroke-width', 2)
              .attr('stroke', '#fff');
    selection.append('title')
      // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
      .text((datum, index) => this.titleCasePipe.transform(pieLabel[index]) + ' : ' + pieData[index]);          
  }
  onBreakDownChartUpdate(selection) {        
    this.customizeBreakdownChart(selection, this.labelData);    
  }
  onStatusChartUpdate(selection) {     
    this.customizeStatusChart(selection,this.status,this.pieData2);    
  }
  
  ngOnChanges()
  {
   
  }
    ngAfterViewInit() {    
      // var outerWidth = $(".scroll").css('width');
      // $(".scrollDummy").css('width', outerWidth);
      // var tableWidth = $(".scroll").find('table').css('width');
      // $(".scrollDummy").find('div').css('width', '4608px').css('height', '6px');   
    //   console.log( this.motherCompanyDropDown);
    console.log("------------------------");
    console.log(this.motherCompanyDropDown);
    //this.motherCompanyDropDown.refresh();
    }

    ngAfterViewChecked() {  

    //  let mpTableWidth = $('.mp-table').width();
    //  console.log('ngAfterViewChecked---',mpTableWidth);
    //  $('.scrollDummy div').width(4293);  
     
    //  let mpTableWidth2 = $('.mp-table').innerWidth();
    //  console.log('ngAfterViewChecked-innerWidth',$('.mp-table').width());
    //  $('.scrollDummy div').width(mpTableWidth2);      

    }

    ngAfterContentInit() {      

      //Input search observable - to avoid the  onInputChange() firing whenever the input changes
      const searchInputObj = document.querySelector('#search-table');
      this.searchInputObservable$ = fromEvent(searchInputObj, 'keyup').throttleTime(1000);
      // const secondsCounter = interval(15000);
      // secondsCounter.subscribe(n =>{
        // this.loaderService.display(true);
        // this.searchInputObservable$.subscribe((event: any) => {   
        //   //  this.loaderService.display(false);    
        //   this.onInputChange(event.target.value);
        // });
        // });  		
      }
      onInputChange(text) {
        this.onSearch();
      }

      entryNoChange(value:number){
        this.listData = [];
        this.apiCallCount = 0;
        this.offSet = 0; 
       // this.dashData.limit = value;
        this.pager["limit"] = value;
        this.pager["total_count"] = 0;
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.dashData['dash_board_data_type'] = this.getDashboardDataType();
        this.getMarketPlaceData(this.dashData,1);

      }

      onSort(orderby: string, id: any) {
        if (this.dashData["orderby"] === orderby) {
          if (this.dashData["order"] === "asc") {
            this.dashData["order"] = "desc";
          } else {
            this.dashData["order"] = "asc";
          }
        } else {
          this.dashData["orderby"] = orderby;
          this.dashData["order"] = "desc"
        }
        //Reset the page to 1, offset to 0
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.listData = this.dashboardService.sortListData(this.listData, this.dashData["orderby"], this.dashData["order"]);
      }

      //Change date range
      selectedDate(value: any) {        
        this.selectedDateRangeLabel = value.label;   
        this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);  

        this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
        this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

        this.dashData.fromDate = this.datepickerOptions.startDate;
        this.dashData.toDate = this.datepickerOptions.endDate;
        this.dashData['dash_board_data_type'] = this.getDashboardDataType();

        this.localData.fromDate =  this.dashData.fromDate;
        this.localData.toDate =  this.dashData.toDate;
        this.apiCallCount = 0;
        this.pager["total_count"] = 0;
        this.pager["current_page"] = 1;
        this.pager["offset"] = 0;
        this.offSet = 0;
        this.listData = [];

         // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
        // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD'); 

        // this.dashData.fromDate = this.daterange.start;
        // this.dashData.toDate = this.daterange.end;

        // this.localData.fromDate = this.daterange.start;
        // this.localData.toDate = this.daterange.end;

        // this.apiCallCount = 0;
        // this.pager["total_count"] = 0;
        // this.pager["current_page"] = 1;
        // this.pager["offset"] = 0;
        // this.offSet = 0;
        // this.listData = [];
        //this.getMarketPlaceData(this.dashData,1);
        this.setMotherAndSubCompanies();
      }

      openModal(template: TemplateRef<any>, data) {
        this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
        this.screenShots = data;
      }
      openModal2(template: TemplateRef<any>, id: number) {
        setTimeout(() => { this.activeIndex = (id); }, 0);
        this.modalRef2 = this.modalService.show(template, { class: 'second' });
      }   
     
      //-----------------------------Single and double click handling----------------------------
      setHighlightRow(index:number,id:number,highlightStatus:boolean) {
        console.log(index+'-'+id+'-'+highlightStatus)
        this.isSingleClick = true;        
        setTimeout(()=>{                            
                 if(this.isSingleClick){                                         
                    this.setHighlight(index,id,highlightStatus)
                 }              
        },500)
      }
      denyHighlight(){
              this.isSingleClick = false;                                          
      }     
      
      //----------------------------------------Highlight Update function----------------------------------------
      setHighlight(index:number,id:number,highlightStatus:boolean) {  
        console.log('index:'+index+'-id:'+id+'-status-'+highlightStatus)           
        let updatedStatus:boolean;     
        let updateData = {}; 

        if(highlightStatus) 
          updatedStatus = false;
        else
        updatedStatus = true;           

        updateData['platform'] = 'market_place';
        updateData['id'] = id;
        updateData['updatedStatus'] = updatedStatus;
        console.log('Bodyy:',updateData)
        
          //Upadte highlight status       
          this.loaderService.display(true);          
          this.dashboardService.updateHighlightStatus(updateData).subscribe((res:any)=>{            

            // if(JSON.parse(res._body).status == "success") {             
            if(res.status == 200) {          
              let highlightRowIndex = this.listData.findIndex(x => x.id ===id);                    
              this.listData[highlightRowIndex].highlight_status = updatedStatus;                
              this.loaderService.display(false);             
            }
            else {             
              this.loaderService.display(false);    
              // if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
                if(res.message=="invalid_accesstoken"||res.message=="un_authorised"){
                //this.toastr.error(this.errorDscrp,this.errorMsg);
                this.authServiceObj.logout(true);
              }
            }               
          });
       }       
      //-----------------------------------------------------------------------------------------------
      
      exportToCsv(){
        //Copy to variable to avoid original data mutation
        const mData = this.listData;

        //Format the data to avoid unwanted fields
        var keyMap = {
          // detection_date : 'DetectionDate',
          // product_name : 'ProductName',
          // keyword : 'Keyword',
          // product_category : 'ProductCategory',
          // market_place : 'MarketPlace',
          // country : 'Country',
          // price : 'Price',
          // currency : 'Currency',
          // item_number : 'ItemNumber',
          // url : 'URL',
          // status : 'Status',
          // in_stock : 'InStock',
          // type_of_infringement : 'TypeOfInfringement',
          // seller_id: 'SellerId',
          // seller_name: 'SellerName',
          // phone_number_1: 'PhoneNumber1',
          // phone_number_2: 'PhoneNumber2',
          // email_address_1: 'EmailAddress1',
          // email_address_2: 'EmailAddress2',
          // address: 'Address'
          detection_date : 'DetectionDate',
          brand : 'BrandName',
          product_category : 'ProductCategory',
          product_name : 'ProductName',
          keyword : 'Keyword',
          market_place : 'MarketPlace',
          mother_company: 'MotherCompany',
          country : 'Country',
          url : 'URL',
          status : 'Status',
          type_of_infringement : 'TypeOfInfringement',
          likes:'Likes',
          price : 'Price',
          currency : 'Currency',
          item_number : 'ItemNumber',
          sold_items:'SoldItems',
          in_stock : 'InStock',
          seller_id: 'SellerId',
          seller_name: 'SellerName',
          phone_number_1: 'PhoneNumber1',
          phone_number_2: 'PhoneNumber2',
          email_address_1: 'EmailAddress1',
          email_address_2: 'EmailAddress2',
          address: 'Address',
          social_network_link: 'SocialMediaLink',
          marketplace_link: 'MarketplaceLink',
          standalone_website_link: 'StandaloneWebsiteLink'         
        };

        var excelData = mData.map((obj) => {
          return _.mapKeys(obj, (value, key) => {
            return keyMap[key];
          });
        });

        const excelDataModified = excelData.map((datum, i) => {
          return {
            // Detection_Date: datum.DetectionDate,
            // Product_Name : datum.ProductName,
            // Keyword : datum.Keyword,
            // Product_Category : datum.ProductCategory,
            // MarketPlace: datum.MarketPlace,
            // Country : datum.Country,
            // Price : datum.Price,
            // Currency : datum.Currency,
            // Item_Number : datum.ItemNumber,
            // URL: datum.URL,
            // Status: datum.Status,
            // In_Stock : datum.InStock,
            // Type_Of_Infringement : datum.TypeOfInfringement,
            // Seller_Id : datum.SellerId ? datum.SellerId:'',
            // Seller_Name : datum.SellerName ? datum.SellerName:'',
            // Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
            // Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
            // Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
            // Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
            // Address : datum.Address ? datum.Address:''
            Detection_Date: this.datepipe.transform(datum.DetectionDate, 'dd/MM/yyyy'),
            Brand_Name : datum.BrandName ? datum.BrandName:'',
            Product_Category : datum.ProductCategory ? datum.ProductCategory:'',
            Product_Name : datum.ProductName ? datum.ProductName:'',
            Keyword : datum.Keyword ? datum.Keyword:'',
            Marketplaces: datum.MarketPlace ? datum.MarketPlace:'',
            Parent_Company: datum.MotherCompany ? datum.MotherCompany:'',
            Country : datum.Country ? datum.Country:'',
            URL: datum.URL ? datum.URL:'',
            Status: datum.Status ? datum.Status:'',
            Type_Of_Infringement : datum.TypeOfInfringement ? datum.TypeOfInfringement:'',
            Likes : datum.Likes ? datum.Likes:'',
            Price : datum.Price ? datum.Price:'',
            Currency : datum.Currency ? datum.Currency:'',
            Item_Number : datum.ItemNumber ? datum.ItemNumber:'',
            Sold_Items : datum.SoldItems ? datum.SoldItems:'',
            Item_Available : datum.InStock ? datum.InStock:'',
            Seller_Id : datum.SellerId ? datum.SellerId:'',
            Seller_Name : datum.SellerName ? datum.SellerName:'',
            Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
            Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
            Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
            Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
            Address : datum.Address ? datum.Address:'',
            Social_Network_Link : datum.SocialMediaLink ? datum.SocialMediaLink:'',
            Marketplace_Link : datum.MarketplaceLink ? datum.MarketplaceLink:'',
            Standalone_Website_Link : datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink:''                        
          }
        });
        const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.localData.toDate)
          let title_month = months[current_datetime.getMonth()];
          let title_year  = current_datetime.getFullYear();
        //Export
        // this.listData[0].brand+'-Marketplaces-'+title_month+' '+title_year+'
        // new Angular2Csv(excelDataModified, 'Market_Place_'+this.breakdown.company+'Report',{ headers: Object.keys(excelDataModified[0])});
        // new Angular2Csv(excelDataModified,this.listData[0].brand+'-Marketplaces-'+title_month+' '+title_year ,{ headers: Object.keys(excelDataModified[0])});
        
        //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
        var activeBrands = this.lsService.retrieve('active_brand_id');
        if((activeBrands.length)>1) {
          new Angular2Csv(excelDataModified,'All-Marketplaces-'+title_month+' '+title_year ,{ headers: Object.keys(excelDataModified[0])});
        } else {
          new Angular2Csv(excelDataModified,this.listData[0].brand+'-Marketplaces-'+title_month+' '+title_year ,{ headers: Object.keys(excelDataModified[0])});
        }
      }

      print() {
        const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let current_datetime = new Date(this.localData.toDate)
        let title_month = months[current_datetime.getMonth()];
        let title_year  = current_datetime.getFullYear();

        //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
        var activeBrands = this.lsService.retrieve('active_brand_id');
        if((activeBrands.length)>1) {
          var printTitle = 'All-Marketplaces-'+title_month+' '+title_year;
        } else {
          var printTitle = this.listData[0].brand+'-Marketplaces-'+title_month+' '+title_year;
        }

        let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
        '<!DOCTYPE html>' +
        '<html>' +
        '<head>' +
        '<meta charset="utf-8" />' +
        '<title>'+printTitle+ '</title>' +
        '<style>table {border-collapse: collapse;} table, td, th { border: 1px solid black;}</style>'+
        '</head>' +
        '<body>' + '<table>'+
        '<thead>'+
        '<tr>'+
        '<th colspan="14" >'+this.breakdown.company+
        '</th>'+
        '</tr>'+
        '<tr>'+
        '<th class="text-center"> Detection Date </th>'+
        '<th > Product Name </th>'+
        '<th > Keyword </th>'+
        '<th > Product Category </th>'+
        '<th > Market Place </th>'+
        '<th > Country </th>'+
        '<th > Price </th>'+
        '<th > Currency </th>'+
        '<th > Item Number </th>'+
        '<th > Sold Items </th>'+ 
        '<th > Url </th>'+
        '<th > Status </th>'+
        '<th > Item Available(in stock) </th>'+
        '<th > Type of infringement </th>'+
        '<th > Likes </th>'+
        '<th > Seller Id </th>'+
        '<th > Seller Name </th>'+
        '<th > Phone Number #1 </th>'+
        '<th > Phone Number #2 </th>'+
        '<th > Email Address #1 </th>'+
        '<th > Email Address #2 </th>'+
        '<th > Address </th>'+               
        '</tr>'+
        '</thead>'+
        '<tbody>';
        for(let i = 0; i< this.listData.length; i++){
          pageContent = pageContent + '<tr>'+
          '<td>'+(this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy'))+'</td>'+
          '<td>'+this.listData[i].product_name+'</td>'+
          '<td>'+this.listData[i].keyword+'</td>'+
          '<td>'+this.listData[i].product_category+'</td>'+
          '<td>'+this.listData[i].market_place+'</td>'+
          '<td>'+this.listData[i].country+'</td>'+
          '<td class="text-right">'+this.listData[i].price+'</td>'+
          '<td>'+this.listData[i].currency+'</td>'+
          '<td class="text-right">'+this.listData[i].item_number+'</td>'+
          '<td>'+this.listData[i].sold_items+'</td>'+
          '<td>'+this.listData[i].url+'</td>'+
          '<td>'+this.listData[i].status+'</td>'+
          '<td>'+this.listData[i].in_stock+'</td>'+
          '<td>'+this.listData[i].type_of_infringement+'</td>'+
          '<td>'+this.listData[i].likes+'</td>'+
          '<td>'+this.listData[i].seller_id+'</td>'+
          '<td>'+this.listData[i].seller_name+'</td>'+
          '<td>'+this.listData[i].phone_number_1+'</td>'+
          '<td>'+this.listData[i].phone_number_2+'</td>'+
          '<td>'+this.listData[i].email_address_1+'</td>'+
          '<td>'+this.listData[i].email_address_2+'</td>'+
          '<td>'+this.listData[i].address+'</td>'+                    
          '</tr>'

        }

        pageContent = pageContent+ '</tbody>'+
        '</table>' + 
        '</body></html>';
        try {
          document.write(pageContent);
          document.close();
          newWindow.print();
          newWindow.close();
        }
        catch (error) {
        }
      }

      // copyToClipboard(){
      //   let selBox = document.createElement('textarea');
      //   selBox.style.position = 'fixed';
      //   selBox.style.left = '0';
      //   selBox.style.top = '0';
      //   selBox.style.opacity = '0';
      //   this.copyValue = 'Detection date, Product Name, Keyword, Product Category, Market Place, Country, Price, Currency, Item Number, URL, Status, Item Available(in stock), Type of infringement, Seller Id, Seller Name, Phone Number #1, Phone Number #2, Email Address #1, Email Address #2, Address \n';
      //   for( let i = 0; i < this.listData.length; i++ ){
      //     this.copyValue = this.copyValue + ( (this.datepipe.transform(this.listData[i].detection_date, 'dd/MM/yyyy'))+', ' +
      //       this.listData[i].product_name + ', ' + this.listData[i].keyword + ', ' + this.listData[i].product_category + ', ' +
      //       this.listData[i].market_place + ', ' + this.listData[i].country + ', ' + this.listData[i].price + ', ' + 
      //       this.listData[i].currency + ', ' + this.listData[i].item_number + ', ' +    this.listData[i].url + ', ' + 
      //       this.listData[i].status + ' ,' + this.listData[i].in_stock + ', ' + this.listData[i].type_of_infringement + ', ' +
      //       this.listData[i].seller_id + ', '+this.listData[i].seller_name + ', '+this.listData[i].phone_number_1 + ', '+
      //       this.listData[i].phone_number_2 + ', '+this.listData[i].email_address_1 + ', '+this.listData[i].email_address_2 + ', '+
      //       this.listData[i].address + ', '+' \n') ;            
      //   }
      //   selBox.value = this.copyValue;
      //   document.body.appendChild(selBox);    
      //   selBox.focus();
      //   selBox.select();
      //   document.execCommand('copy');
      //   document.body.removeChild(selBox);
      //   this.toastr.success('Data copied to clipboard');
      // }

      //two scrolls

      // scroll() {
      //   this.myChart.nativeElement.scrollTo(this.scroller.nativeElement.scrollLeft,0)
      //  }
 
      //  scroll1() {
      //    this.scroller.nativeElement.scrollTo(this.myChart.nativeElement.scrollLeft,0)
      //   }


        //export to xlsx

        fireEvent()
        {
            //Copy to variable to avoid original data mutation
        const mData = this.listData;
        // console.log('Titleeeeeeee',this.rangeTitle2)

        //Format the data to avoid unwanted fields
        var keyMap = {
          detection_date : 'DetectionDate',
          brand : 'BrandName',
          product_category : 'ProductCategory',
          product_name : 'ProductName',
          keyword : 'Keyword',
          market_place : 'MarketPlace',
          mother_company: 'MotherCompany',          
          country : 'Country',
          url : 'URL',
          status : 'Status',
          type_of_infringement : 'TypeOfInfringement',
          likes: 'Likes',
          price : 'Price',
          currency : 'Currency',
          item_number : 'ItemNumber',
          sold_items:'SoldItems',
          in_stock : 'InStock',
          seller_id: 'SellerId',
          seller_name: 'SellerName',
          phone_number_1: 'PhoneNumber1',
          phone_number_2: 'PhoneNumber2',
          email_address_1: 'EmailAddress1',
          email_address_2: 'EmailAddress2',
          address: 'Address',
          social_network_link: 'SocialMediaLink',
          marketplace_link: 'MarketplaceLink',
          standalone_website_link: 'StandaloneWebsiteLink'              
        };

        var excelData = mData.map((obj) => {
          return _.mapKeys(obj, (value, key) => {
            return keyMap[key];
          });
        });

        const excelDataModified = excelData.map((datum, i) => {
          return {
            // Detection_Date: datum.DetectionDate,
            // Detection_Date: this.datepipe.transform(datum.DetectionDate, 'yyyy/dd/MM'),
            Detection_Date: this.datepipe.transform(datum.DetectionDate, 'dd/MM/yyyy'),
            Brand_Name : datum.BrandName ? datum.BrandName:'',
            Product_Category : datum.ProductCategory ? datum.ProductCategory:'',
            Product_Name : datum.ProductName ? datum.ProductName:'',
            Keyword : datum.Keyword ? datum.Keyword:'',
            Marketplaces: datum.MarketPlace ? datum.MarketPlace:'',            
            Parent_Company: datum.MotherCompany ? datum.MotherCompany:'',          
            Country : datum.Country ? datum.Country:'',
            URL: datum.URL ? datum.URL:'',
            Status: datum.Status ? datum.Status:'',
            Type_Of_Infringement : datum.TypeOfInfringement ? datum.TypeOfInfringement:'',
            Likes : datum.Likes ? datum.Likes:'',
            Price : datum.Price ? datum.Price:'',
            Currency : datum.Currency ? datum.Currency:'',
            Item_Number : datum.ItemNumber ? datum.ItemNumber:'',
            Sold_Items : datum.SoldItems ? datum.SoldItems:'',
            Item_Available : datum.InStock ? datum.ItemNumber:'',
            Seller_Id : datum.SellerId ? datum.SellerId:'',
            Seller_Name : datum.SellerName ? datum.SellerName:'',
            Phone_Number_1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
            Phone_Number_2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
            Email_Address_1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
            Email_Address_2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
            Address : datum.Address ? datum.Address:'',
            Social_Network_Link : datum.SocialMediaLink ? datum.SocialMediaLink:'',
            Marketplace_Link : datum.MarketplaceLink ? datum.MarketplaceLink:'',
            Standalone_Website_Link : datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink:''                        
          }
        });
          // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.myChart.nativeElement);
          // const wb: XLSX.WorkBook = XLSX.utils.book_new();
          // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          
          // /* save to file */
          // XLSX.writeFile(wb,'Market_Place_'+this.breakdown.company+'Report.xlsx');

          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelDataModified);
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          // XLSX.writeFile(workbook, 'Market_Place_'+this.breakdown.company+'Report.xlsx', { bookType: 'xlsx', type: 'buffer' });
          // XLSX.writeFile(workbook, this.listData[0].brand+'-Marketplaces-'+this.localData.toDate+'.xlsx', { bookType: 'xlsx', type: 'buffer' });

          const months = ["January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
          let current_datetime = new Date(this.localData.toDate)
          let title_month = months[current_datetime.getMonth()];
          let title_year  = current_datetime.getFullYear();          
          // XLSX.writeFile(workbook, this.listData[0].brand+'-Marketplaces-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
          
          //---File name must be 'All' when multiple files downloaded. Otherwise file name must be the brand name
          var activeBrands = this.lsService.retrieve('active_brand_id');
          if((activeBrands.length)>1) {          
            XLSX.writeFile(workbook,'All-Marketplaces-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
          } else {
            XLSX.writeFile(workbook, this.listData[0].brand+'-Marketplaces-'+title_month+' '+title_year+'.xlsx', { bookType: 'xlsx', type: 'buffer' });
          }
          
          
          
        }



    }
