import { Directive, Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';
import { group } from '@angular/animations';
import { tree } from 'd3';

@Directive({
  selector: '[appRelationgraph]'
})
export class RelationgraphDirective {

  @Input () chartOption : {};

  private host: D3.Selection<any, any, any, any>;
  public svg: D3.Selection<any, any, any, any>;
  private htmlElement: HTMLElement;
  public width: number;
  public height: number;

  constructor(private el: ElementRef) {
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
  }

  //  ngOnInit(){
  //   this.setup();
  //   this.buildSVG();
  //   this.relation();
  //  }

   ngOnChanges(){
    this.setup();
    this.buildSVG();
    if(this.chartOption['data']){
      this.relation();
    }
    
  }

  private setup(): void {
    this.width = 900;
    this.height = this.chartOption['data'].children.length * 80 ;
  }

  private buildSVG(): void {
    this.host.html('');
    // this.svg = this.host.append('svg')
    //   .attr('height', this.height)
    //   .attr('width', this.width);
      //.attr('viewBox', '10 40')
      // .append('g')
      // .attr('transform', `translate(10,40)`);        
  }

  private relation(){
   // append the svg object to the body of the page
// appends a 'group' element to 'svg'
// moves the 'group' element to the top left margin

var treeData = this.chartOption['data'];

// var treeData =
//   {
//     "name": "John Smith",
//     "children": [
//       { 
//         "name": "J2@gmail.com",
//         "children": [
//           { "name": "j112", "type" : "phone" },
//           { "name": "2shop.com" , "type" : "web" }
//         ],
//         "type" : "email"
//       },
//       { "name": "J1@gmail.com", "type" : "email" },
//       {"name": "555501226", "type" : "phone", "children":[
//         {"name" : "John Cole", "type":"name"}
//       ]},
//       {"name" : "Social Network Link", "type" : "social"},
//       {"name" : " Marketplace", "type" : "market"},
//       {"name" : "Standalone Website Link", "type": "website"},
//       {"name" : "23 Causeway, Bangkok" , "type" : "address"} 
//     ],
//     "type" : "name"
//   };

var i = 0,
duration = 750,
root;

this.svg = this.host.append('svg')
      .attr('height', this.height + 100)
      .attr('width', this.width)
      .append("g")
        .attr('transform', `translate(200,40)`);    

// declares a tree layout and assigns the size
var treemap = D3.tree().size([this.height, this.width]);

// Assigns parent, children, height, depth
root = D3.hierarchy(treeData,<any> function(d) { return d.children; });
root.x0 = this.height / 2;
root.y0 = 0;

// Collapse after the second level
root.children.forEach(collapse);

update(root,this.svg);

// Collapse the node and all it's children
function collapse(d) {
  if(d.children) {
  d._children = d.children
  d._children.forEach(collapse)
  d.children = null
  }
}

function update(source,svg) {
  

// Assigns the x and y position for the nodes
var treeData = treemap(root);

// Compute the new tree layout.
var nodes = treeData.descendants(),
  links = treeData.descendants().slice(1);

// Normalize for fixed-depth.
nodes.forEach(function(d){ d.y = d.depth * 220});



// ****************** Nodes section ***************************

// Update the nodes...
var node = svg.selectAll('g.node')
  .data(nodes, function(d) {return d.id || (d.id = ++i); });


// Enter any new modes at the parent's previous position.
var nodeEnter = node.enter().append('g')
  .attr('class', 'node')
  .attr("transform", function(d) {
    return "translate(" + source.y0 + "," + source.x0 + ")";
})
.on('click', click);

// Add Circle for the nodes
// nodeEnter.append('circle')
//     .attr('class', 'node')
//     .attr('r', 1e-6)
//     .style("fill", function(d) {
//         return d._children ? "lightsteelblue" : "#fff";
//     });

// Add labels for the nodes
nodeEnter.append('text')
  .attr("dy", "-1.95em")
  .attr("dx", "0.95em")
  .attr("x", function(d) {
      return d.children || d._children ? -23 : 23;
  })
  .attr("text-anchor", function(d) {
      return d.children || d._children ? "end" : "start";
  })
 .text(function(d) { return d.data.name; });
  // .attr("clip-path", function(d) { return "url(#clip-" + d.data.name.replace(/,/g,'') + ")"; })
  //       .selectAll("tspan")
  //         .data(function(d) { return d.data.name.split(/,/g); })
  //       //.data(function(d) { return d.data.class; })
  //       .enter().append("tspan")
  //         .attr("x", 0)
  //         .attr("y", function(d, i, nodes) { return 100 + (i - nodes.length / 2 - 0.5) * 14; })
  //         .text(function(d) { return d; })

var img = nodeEnter
// .append("svg:foreignObject")
// .attr("width", 20)
// .attr("height", 20)
// .attr("y", "-7px")
// .attr("x", "-7px")
// .append("xhtml:span")
// .attr("class", "control glyphicon glyphicon-envelope")
// .style("position","static");
.append('text')
    .attr("dx", ".15em")
    .attr("dy", "-.15em")
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .style('font-family','FontAwesome')
    .style('font-size','35px')
    .text(function (d) {
      if(d.data.type == "name"){
return '\uf007';
}else if(d.data.type == "phone") {
  return '\uf095';

}else if(d.data.type == "email"){
  return '\uf2b6';
}else if(d.data.type == "web"){
  return '\uf0ac';
}else if(d.data.type == "address"){
  return '\uf015';
}else if(d.data.type == "market"){
  return'\uf290';
}else if(d.data.type == "social"){
  return '\uf030';
}else if(d.data.type == "website"){
  return '\uf0ac';
}else if(d.data.type == "id"){
  return '\uf25d';
}
else{
      return '\uf0ac';
}
})
.style("cursor", (d) => {if (d.data.children){ return "pointer"; }} );

// UPDATE
var nodeUpdate = nodeEnter.merge(node);

// Transition to the proper position for the node
nodeUpdate.transition()
.duration(duration)
.attr("transform", function(d) { 
    return "translate(" + d.y + "," + d.x + ")";
 });

// Update the node attributes and style
// nodeUpdate.select('circle.node')
//   .attr('r', 10)
//   .style("fill", function(d) {
//       return d._children ? "lightsteelblue" : "#fff";
//   })
//   .attr('cursor', 'pointer');


// Remove any exiting nodes
var nodeExit = node.exit().transition()
  .duration(duration)
  .attr("transform", function(d) {
      return "translate(" + source.y + "," + source.x + ")";
  })
  .remove();

// On exit reduce the node circles size to 0
nodeExit.select('circle')
.attr('r', 1e-6);

// On exit reduce the opacity of text labels
nodeExit.select('text')
.style('fill-opacity', 1e-6);

// ****************** links section ***************************

// svg.append('defs').append('marker')
//       .attrs({'id':'arrowhead',
//           'viewBox':'-0 -5 10 10',
//           'refX':99,
//           'refY':0,
//           'orient':'auto',
//           'markerWidth':9,
//           'markerHeight':9,
//           'xoverflow':'visible'})
//       .append('svg:path')
//       .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
//       .attr('fill', '#999')
//       .style('stroke','none');

// Update the links...
var link = svg.selectAll('path.link')
  .data(links, function(d) { return d.id; })      

// Enter any new links at the parent's previous position.
var linkEnter = link.enter().insert('path', "g")
  .attr("class", "link")
  .attr('d', function(d){
    var o = {x: source.x0, y: source.y0}
    return diagonal(o, o)
  })
  .style("fill","none")
  .style("stroke", "#99e699")
  .style("stroke-width", "1px");

// UPDATE
var linkUpdate = linkEnter.merge(link);

// Transition back to the parent element position
linkUpdate.transition()
  .duration(duration)
  .attr('d', function(d){ return diagonal(d, d.parent) });

// Remove any exiting links
var linkExit = link.exit().transition()
  .duration(duration)
  .attr('d', function(d) {
    var o = {x: source.x, y: source.y}
    return diagonal(o, o)
  })
  .remove();

// Store the old positions for transition.
nodes.forEach(<any>function(d){
d.x0 = d.x;
d.y0 = d.y;
});

// Creates a curved (diagonal) path from parent to the child nodes
function diagonal(s, d) {

let path = `M ${s.y} ${s.x}
        C ${(s.y + d.y) / 2} ${s.x},
          ${(s.y + d.y) / 2} ${d.x},
          ${d.y} ${d.x}`

return path
}

// Toggle children on click.
      function click(d) {
          if (d.children) {
              d._children = d.children;
              d.children = null;

            } else {
              d.children = d._children;
              d._children = null;
              svg.selectAll('.link').style("stroke", "#ccc")
            }
          update(d,svg);
          }
      }

   
  }

  


}
