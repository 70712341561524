import { Directive, Input, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';

@Directive({
  selector: '[appBubbleChart]'
})
export class BubbleChartDirective {

  @Input () bubbleChartOption : {};
  @ViewChild('mapContainer') mapContainer: ElementRef;

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private htmlElement: HTMLElement;
  private width: number;
  private height: number;
  public data: any;
  public keyword: any;
  public top: any;
  public left:any;

  constructor(private el: ElementRef) {
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
    this.data = [];
    this.keyword = '';
   }

  //  ngOnInit(){
  //   this.setup();
  //   this.buildSVG();
  //  this.bubble();
  // }

  ngOnChanges(){
    this.setup();
    this.buildSVG();
    if(this.data.length !== 0){
      this.bubble();
    }
    
   }

  private setup(): void {
    // this.width = 600;
    this.width = 630;
    this.height = 490;
    this.data = this.bubbleChartOption['data'];
    this.keyword = this.bubbleChartOption['keyword'];
  }

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host.append('svg')
        .attr('height', this.height)
        .attr('width', this.width)
        // .attr('font-size','10')
        // .attr('font-family','HelveticaNeue')
        // .attr('font-style','bold')
        // .attr('text-align','center');
        // .attr('text-anchor','middle');
        // .attr('text-anchor','center');
        //.attr('viewBox', '10 40')
        // .append('g')
        //.attr('transform', `translate(10,40)`);
   }

  bubble(){
    // format function
    var format = D3.format(",d");
    D3.selection.prototype.moveToFront = function() {  
          return this.each(function(){
            this.parentNode.appendChild(this);
          });
    };

    // var color = D3.scaleOrdinal(D3.schemeCategory10);
    var color = D3.scaleOrdinal(D3.schemeSet2);

    var sizeScale = D3.scaleSqrt()
                      .range([10,16]);
    
    var pack = D3.pack()          
        .size([this.width, this.height])
       // .radius(function(d){ if(d.value <= 10){return Math.sqrt(d.value*160);}else{return Math.sqrt(d.value*30);}})
      // .radius(function(d){ return Math.sqrt((d.value*35))}) 
      //  .radius(d=>sizeScale(d.value)) 
       .padding(7.5);
    var chart = this.svg.append('g')

    //custom tooltip
    // var div = this.host.append("div")	
    //             .attr("class", "tooltip")	
    //             .style("padding","7px")
    //              .style("box-shadow","0px 0px 2px 0px #a6a6a6")	
    //             .style("background","white")
    //             .style("color","#000")
    //             .style("border-radius","3px")		
            //   .style("opacity", 0);
                    
    // .attr('transform', `translate(10,40)`);
 
    
    function plot(params){
      var root = D3.hierarchy({children: params.data})
          .sum(function(d) { return d['total']; });
          // .each(function(d) {
          //   if (id = d.data['product']) {
          //     var id, i = id;
          //     d.data['id'] = id;
          //     d.data['package'] = id;
          //     d.data['class'] = id;
          //   }
          // });

      var node = this.selectAll(".node")
        .data(pack(root).leaves())
        .enter().append("g")
          .attr("class", "node")
          .attr("transform", (d) => { return "translate(" + d.x + "," + d.y + ")"; });
    
      node.append("circle")
          .attr("id", function(d) { return d.data.product; })
          .attr("r", function(d) { return (d.r); })
          .style("fill", function(d) { return color(d.data.product); })
          .on('mouseover', function(d, i) {  
             // if(d['r']>100) 
            // console.log('**Above 100')
           // D3.select(this).moveToFront();                   
            D3.select(this).transition()
              .duration(1000)
              .attr("r", function(d) { return (d['r']+10); });           
              
            
          })
          .on('mouseout', function(d, i) {
            D3.select(this).transition()
              .style("opacity", 1)
              .attr("r",  function(d) { return (d['r']); })
            // div.transition()		
            //   .duration(500)		
            //   .style("opacity", 0);	
          });          

          D3.selectAll(".node").on("mouseover", function(d) {
            // D3.select(this).moveToFront();
            var circle = D3.select(this).select("circle");
            circle.transition().duration(500).attr("r", d => d['r']+6)                      
          })

      node.append("clipPath")
          .attr("id", function(d) { return "clip-" + d.data.product.replace(/ /g,''); })
        .append("use")
          .attr("xlink:href", function(d) { return "#" + d.data.product; });
      //-------Start--------
      node.append("text")
      // .attr("text-anchor", "middle")
          // .style("font-size", function(d){ if(d.data.total > 100){ return "11"; }else{ return "9"; }  } )
          // .style("font-size", function(d){  return "11"})          
          // .style("position","absolute") 
          // .style("z-index",function(d){ if(d['r']>20){return 10} else {return 9} })
          // .style("visibility","initial")     
        .attr("clip-path", function(d) { return "url(#clip-" + d.data.product + ")"; })
        .selectAll("tspan")        
        // .style('font-family','HelveticaNeue')
        // .style("position","absolute") 
        // .style("z-index",function(d){ if(d['r']>20){return 10} else {return 9} })
          // .style("white-space","nowrap")
          // .data(function(d) { if(d['r']>20){return d.data.product;}else{return " "} })
          .data(function(d) { let tempTitle=''; if(d['r']>20){return d.data.product;}else{return tempTitle} })
        //.data(function(d) { return d.data.class; })
        .enter().append("tspan")
        // .attr('text-anchor','end')
        .attr("text-anchor", "middle")
        .attr('white-space','normal')
        .style("font-size","11px")    
        .style('font-family','HelveticaNeue')      
        // .attr('padding-right',-2)
        
      // .style("white-space","pre")        
      // .attr("x",  function(d, i, nodes) { return 9 + (i) * 9; })
      // .attr("y", ".3em")
      // .attr("x",  function(d, i, nodes) { return  ((i - nodes.length / 2) - 50) * 14; })
      // .attr("x",  function(d, i, nodes) { return 9 + (i) * 9; })
        // .attr("x",  function(d, i, nodes) { return 13 + (i - nodes.length / 2 - 0.5) * 14; })
        // .attr("y",0)
        .text(function(d) {  return d; })
          .on('mouseover', function(d, i) {   
            d.onFocus();    
             
           })
           .on('mouseout', function(d, i) {
            D3.select(this).transition()
              .style("opacity", 1)
              .attr("r",  function(d) { return (d['r']); })            
          });
         // .text(function(d) { wrap(d, d['r']) });
         //-------End--------
         

      if(params.keyword == false){
        node.append("title")
        .text(function(d) { return 'Product: '+d.data.product + "\nURLs: " + format(d.data.total); });
      }else{
        node.append("title")
        .text(function(d) { return 'Keyword: '+d.data.product + "\nMarketplace: " +d.data.market_place + "\nSocial Media: " +d.data.social_media+ "\nStandalone Website: " +d.data.standalone_website + "\nTotal: " + format(d.data.total); });
      }

          // var mouseOn = function() { 
          //   var cirlce = D3.select(this);
        
          //   // transition to increase size/opacity of bubble
          //   cirlce.transition()
          //   .duration(800).style("opacity", .3)
          //   .attr("r", function(d) { return d['r']+10; });
          //  //  .ease("elastic");
        
          
        
          //  // function to move mouseover item to front of SVG stage, in case
            //  // another bubble overlaps it
            //    D3.selection.prototype.moveToFront = function() { 
            //      return this.each(function() { 
            //      this.parentNode.appendChild(this); 
            //      }); 
            //    };
            //   }

    }

    plot.call(chart,{data :this.data, keyword:this.keyword});
   }

}
