import { Component, OnInit, TemplateRef } from '@angular/core';
import { ReportService } from '../../services/report/report.service';
import { LocalStorageService } from 'ngx-webstorage';
import { AppSettings } from '../../settings.global';
import { ToastrService } from 'ngx-toastr';
import { BrandService } from '../../services/brand/brand.service';
declare var $:any;
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  modalRef: BsModalRef;

  public localDataBrand : any;
  public brandId : any;
  public reportdetails : any;
  public year : any;
  public latestReport : any;
  public file_url = AppSettings.IMAGE_BASE_URL;
  public reportEachDetails : any;
  public reportEach : any;
  public fileDeleteDetails : any;
  public fileToImport : any;
  public upload:any;
  public fileUploadDetails: any;
  public id: any;
  public back: any;
  public modalTitle: any;
  public pptUrl: any;
  public pdfUrl: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  count:number=0;
  public clientID:any;
  public brandCount:number=0;

  constructor(private reportService : ReportService, 
    private localStorage : LocalStorageService,
    private brandService: BrandService,
    private modalService: BsModalService,
    private authServiceObj:AuthService,
    private loaderService:LoaderService,
    private toastr: ToastrService) {
    this.localDataBrand = this.localStorage.retrieve('web_user_details');
    this.brandId = this.localStorage.retrieve('active_brand_id');
    this.clientID = this.localStorage.retrieve('active_client_id');   
    this.reportdetails = {
      brand : this.brandId,
      client : this.clientID
    };
    this.reportEachDetails = {
      year : '',
      brand : this.brandId,
      client : this.clientID
    }
    this.year = [];    
    this.latestReport = [];
    this.reportEach = [];
    this.fileDeleteDetails = {
      file_id : ''
    }
    this.upload = '';
    this.fileUploadDetails = {
      year : '',
      client : this.clientID,
      brand : this.brandId,
      type : 'file'
    }
    this.back = false;
  }

  ngOnInit() {
    //this.getReports(this.reportdetails);
    //this.loaderService.display(true);  
     $('#tab_1').addClass('active');
      this.back = false;
      this.reportEachDetails.brand = this.brandId;
      this.fileUploadDetails.brand = this.brandId;
      this.getReports(this.reportdetails);
  }

  getReports(details:any){
    this.count++
    console.log(this.count,"count");
    this.loaderService.display(true);
    this.reportService.getReport(details).subscribe( (res) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        this.year = res.json().data_found.years;
        this.latestReport = res.json().data_found.latest_report;
        console.log("The report response is:-------------------------------",this.latestReport); 
        this.brandCount = this.brandId.length;
        this.latestReport.map((x) => {x.name = x.name.split('.').slice(0, -1).join('.')});
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }
      
    })
  }

  getEachYear(year:any,id: any){
    console.log("The report called");
    this.back = true;
    this.id = id;
    this.reportEachDetails.year = year;
    this.fileUploadDetails.year = year;
    this.loaderService.display(true);
    this.reportService.getEachYearReports(this.reportEachDetails).subscribe( (res) => {
    this.loaderService.display(false);
    console.log("The report called2");
      if(res.json().status == "success"){
        this.reportEach = res.json().data_found;
        console.log("The reports are:------------------",this.reportEach);
        this.reportEach.map((x) => {x.name = x.name.split('.').slice(0, -1).join('.')});
      }else{
        console.log("The report called3");
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }

      }

    })
  }

  deleteFile(id:any){ 
    var cnf=confirm("Are you sure you want to delete this report?");

    if(cnf){
      this.fileDeleteDetails.file_id = id;
      this.loaderService.display(true);
      this.reportService.deleteClientFile(this.fileDeleteDetails).subscribe( (res) => {
        this.loaderService.display(false);

        if(res.json().status == 'success'){
          this.toastr.success('File Deleted Successfully'); 
          this.getEachYear(this.reportEachDetails.year,this.id);          
          this.year[this.id].file_count = this.year[this.id].file_count - 1;
        }else{

          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
          
        }     
      
      });
    }    
  }


  deleteReport(id:any){ 
    var cnf=confirm("Are you sure you want to delete this report?");
    if(cnf){
      this.fileDeleteDetails.file_id = id;
      this.loaderService.display(true);
      this.reportService.deleteClientFile(this.fileDeleteDetails).subscribe( (res) => {
        this.loaderService.display(false);   
        if(res.json().status == 'success'){
          this.toastr.success('Report Deleted Successfully'); 
           this.getReports(this.reportdetails);
        }else{
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }     
      
      });
    }    
  }

  getFile(event:any) {
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();
      this.fileToImport=event.target.files[0];
      this.upload = 'value';
      this.reportService.uploadReport(this.fileUploadDetails,this.fileToImport).then( (res) => {
        if(res.status == 'success'){
          this.toastr.success('File Uploaded Successfully');
          this.getEachYear(this.reportEachDetails.year,this.id);
          this.year[this.id].file_count = this.year[this.id].file_count + 1;
        }else{
          if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
            //this.toastr.error(this.errorDscrp,this.errorMsg);
            this.authServiceObj.logout(true);
          }
        }
      })

      //reader.readAsDataURL(event.target.files[0]);
   }
  }

  backTab(){
    this.back = false;
    $('#tab_1').addClass('active');
    this.getReports(this.reportdetails);
  }

  // openPopUp(template: TemplateRef<any>,name, pptUrl, pdfUrl){
  //   this.modalRef = this.modalService.show(template, { class: 'modal-pop' });
  //   this.modalTitle = name;
  //   this.pptUrl = pptUrl;
  //   this.pdfUrl = pdfUrl;
  // }

  // downloadFile(url){
  //   this.reportService.downloadFile(this.file_url+url).subscribe( (res) => {

  //   })
  // }

 

}
