import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { AppSettings } from '../../settings.global';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  public headers: Headers;

  brandChanged = new Subject<number>();

  brands: {
    id: number;
    name: string;
  }[];

  constructor(private http: Http, private localSt: LocalStorageService) {
    this.headers = new Headers();

    this.headers.append('Content-Type', 'application/json;charset=UTF-8');
    this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
  }

  getBrands(userid: number, client: any) {
    let headers = new Headers();
    this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
    this.headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: this.headers });
    return this.http.get(AppSettings.API_ENDPOINT + '/dashboard/brand?user_id=' + userid + '&client=' + client, options)
      .map(function (res: any) {
        return res || {
          status: "failed",
          message: "Something went wrong"
        };
      }).catch((error: any) => {
        //return Observable.throw(error.json().error || 'Server error occured')
        return Observable.of({ status: "error", message: "Server error occured" })
      });
  }
  
  getClientSelect() {
    this.headers.set('x-access-token', this.localSt.retrieve("web_access_token"));
    let options = new RequestOptions({ headers: this.headers });
    return this.http.get(AppSettings.API_ENDPOINT + '/client/client_select?user_id=' + this.localSt.retrieve("web_user_details").id, options)
      .map(function (res: any) {
        return res.json() || {
          status: "failed",
          message: "Something went wrong"
        };
      });
  }
}
