import { Directive, Output, EventEmitter, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';
import * as topojson from 'topojson';
import { Http } from '@angular/http';
import WorldJson from '../../../assets/world50.json';

@Directive({
  selector: '[appWorldmap]'
})
export class WorldmapDirective implements OnInit {

  @Output() mapInit = new EventEmitter<any>();

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private width: number;
  private height: number;
  private htmlElement: HTMLElement;
  private projection;
  private path;

  constructor(private el: ElementRef, private http: Http) {


  }

  ngOnInit() {

    this.setup();
    this.mapSet();
    this.mapPlot();
  }

  setup() {

    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
    this.width = this.htmlElement.clientWidth;
    this.height = this.htmlElement.clientHeight;
  }

  ngOnChanges(){
    this.setup();
    this.mapSet();
    this.mapPlot();
  } 

  mapSet() {
    const zoom = D3.zoom()
    .scaleExtent([1, 1.5])
    .translateExtent([[-100, -100], [this.width + 100, 0]])
    .on('zoom', () => zoomed);

    function zoomed() {
      this.svg.attr('transform', D3.event.transform);
    }

    this.projection = D3.geoMercator()
                        .scale(this.width / 2.25 / Math.PI)
                        .translate([this.width / 2, this.height /1.5]);

    this.path = D3.geoPath()
                  .projection(this.projection);

    this.svg = this.host.html('')
                  .append('svg')
                  .attr('width', this.width)
                  .attr('height', this.height)
                  .call(zoom);
  }

  mapPlot() {    
    console.log('^^^^^World--Json-- ',WorldJson)    
    // this.http.get('assets/world50.json')    
      // .subscribe(
        // (res) => {          
          // const worldmap = res.json();
          const worldmap = WorldJson;

          const svg = this.svg;

          const path = this.path;

          const countries = svg.append('g')
                              .attr('class', 'countries')
                              .selectAll('path')
                              .data(topojson.feature(worldmap, worldmap.objects.countries)['features'])
                              .enter().append('path')
                              .attr('stroke', 'rgba(170,170,170,0.4)')
                              .attr('d', path)
                              .attr('fill', '#D3D3D3' )
                              .attr('id', function (d) { return d['id']; });


        this.mapInit.emit({svg: svg, countries: countries});

    // });
  }

}
