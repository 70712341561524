import { Directive, Input } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';
import { LocalStorageService } from 'ngx-webstorage';

@Directive({
  selector: '[appStackedbar]'
})
export class StackedbarDirective {

  @Input () stackchartOption : {};

  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private htmlElement: HTMLElement;
  private width: number;
  private height: number;
  public y1Max: any;
  public xz: any;
  public range_y: any;
  public trans: any;
  public parentNode: any;
  public tooltip: any;
  public data: any;
  private lsService: LocalStorageService;

  constructor(private el: ElementRef) { 
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);
  }

  // ngOnInit(){
  //   this.setup();
  //   this.buildSVG();
  //   this.stackBar();
  // }
  ngOnChanges(){
    this.setup();
    this.buildSVG();    
    if(this.data) {
      if(this.data.length !== 0){    
          this.stackBar();
      }
    }
   }

  private setup(): void {
    this.width = 550;
    this.height = 500;
    this.data = this.stackchartOption['data'];
  }

  private buildSVG(): void {
    this.host.html('');
    this.svg = this.host.append('svg')
      .attr('height', this.height -200)
      .attr('width', this.width)
      .attr('viewBox', '10 40')
      .append('g')
      .attr('transform', `translate(30,5)`);
     //.attr('transform', `translate(30,-12)`);
  }

  stackBar(){
    var displayData = this.stackchartOption['data'];
    var stackData = this.stackchartOption['stackData'];    
    var stacklength = stackData.length;
    var stackKey = [];
    
    for(var i=0;i<stacklength;i++){
      stackKey[i] = stackData[i].name;
    }

    // var data = [{"name":"John Smith","total":56,"market":15,"social":36,"standalone":5},
    // {"name":"Samuel Umtiti","total":41,"market":20,"social":12,"standalone":9},
    // {"name":"Diego de la Veg","total":44,"market":16,"social":22,"standalone":6},
    // {"name":"Patrick Abitbol","total":46,"market":9,"social":0,"standalone":23},
    // {"name":"Ivan Kamovitch","total":49,"market":17,"social":27,"standalone":30},
    // {"name":"John 2Smith","total":63,"market":15,"social":36,"standalone":70}
    // ];

    var arrayLength  = stackData.length;
    var sellerLength = displayData.length;
    var data=[];

    for(var t=0;t<sellerLength;t++){
      let sellerData= displayData[t];              
      var counts=sellerData.counts;
      var json={};
          json["name"]=sellerData.seller_name;
          json["total"]=sellerData.total;
      var sellerJson = "{"+sellerData.counts+"}";
          sellerJson = sellerJson.replace(/'/g, '"')
          sellerJson= JSON.parse(sellerJson);

              for(var p=0;p<arrayLength;p++){
                if(sellerJson[stackKey[p]]){
                  json[stackKey[p]]=sellerJson[stackKey[p]];
                }else{
                  json[stackKey[p]]=0;
                }
              }

         data.push(json);                
    }

    //this.lsService.store('stackData',data);
    var xScale = D3.scaleBand().range([0, this.width-50]).padding(.25),
        yScale = D3.scaleLinear().range([this.height/2.2, 0]),
    // parseDate = D3.timeParse("%m/%Y"),
      // color = ['#f0d128','#6c7279','#3397d8','#f09328','#f06128','#f02828','#58cc73','#58ccc2','#cc58bc','#1c3545'],
      color = ['#f0d128','#c75ac7','#3397d8','#f09328','#f06128','#f02828','#58cc73','#58ccc2','#cc58bc','#1c3545','#6c7279'],
      xAxis = D3.axisBottom(xScale).tickSize(0).tickPadding(6),
      yAxis =  D3.axisLeft(yScale)
                  .ticks(10)
            .tickSize(-this.width);
      // .tickFormat( function(d) { return d } );
    
    var stack = D3.stack()
    .keys(stackKey)
    .order(D3.stackOrderNone)
    .offset(D3.stackOffsetNone);

    var ymax = D3.max(data.map(function(d) { return d.total; }))

    var layers= stack(<any>data);
      data.sort(function(a, b) { return b.total - a.total; });
      xScale.domain(<any>data.map(function(d) { return d.name; }));
      yScale.domain([0,<any> (ymax + <any>ymax/10*2)])
  //  yScale.domain([0, D3.max(layers[layers.length - 1], function(d) { return d[0] + d[1]; }) ]).nice();

    this.svg.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(8," + (this.height/2.2) + ")")
      .call(xAxis)
      .selectAll("text")	
            .style("text-anchor", "end")
            //.attr("dx", "-1em")
            .attr("dx",-((7/data.length)/2)+"em")
            //.attr("dy", "-2em")
            .attr("dy",-(7/data.length)+"em")
            .attr("transform", "rotate(-65)");

    this.svg.append("g")
      .attr("class", "axis axis--y")
      .attr("transform", "translate(8,0)")
      .call(yAxis);	
    
      this.svg.selectAll('.axis--y path')
              .attr('d', 'M550,227.77272727272725H0.5V0.5H0');
      this.svg.append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("x", -6)
      .attr("y", 45)
      .attr("dy", "-5.7em")
      .attr("dx", "-1em")
      .attr("transform", "rotate(-90)")
      .text("Number of URL's");
    

    var layer = this.svg.selectAll(".layer")
      .data(layers)
      .enter().append("g")
      .attr("class", "layer")
      .style("fill", function(d, i) { return color[i]; });

    layer.selectAll("rect")
      .data(function(d) { return d; })
      .enter().append("rect")
        .transition()
        .delay(function(d, i) { return i * 100; })
        .attr("x", function(d) { return xScale(<any>d.data.name)+10; })
        .attr("y", function(d) { return yScale(d[1]); })
        .attr("height", function(d) { return yScale(d[0]) - yScale(d[1]); })
        .attr("width", xScale.bandwidth()/2);
    layer.selectAll("rect")
      .data(function(d) { return d; }) 
      .append("title")
        .text(function(d) { return Math.abs( (d[0]) - (d[1]) ); });
  }

}
