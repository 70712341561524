//import { Directive } from '@angular/core';
import { Directive, Input, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import * as D3 from 'd3';

@Directive({
  selector: '[appPackedcircle]'
})
export class PackedcircleDirective {
  
  @Input () packedCircleChartOption : {};
  private host: D3.Selection<any, any, any, any>;
  private svg: D3.Selection<any, any, any, any>;
  private width: number;
  private height: number;
  private htmlElement: HTMLElement;
  private data: any;
  private margin: any;
  private marginBottom: any;
  private duration: any; 
  private lineOpacity : any;
  private lineOpacityHover: any;
  private otherLinesOpacityHover : any;
  private lineStroke : any;
  private lineStrokeHover : any;
  private circleOpacity : any;
  private circleOpacityOnLineHover : any;
  private circleRadius : any;
  private circleRadiusHover : any;
  private parseDate : any;
  private color : any; 
  public packedCircleChartOptionData: any; 
  public tempData : any;
  public chart : any;  
  public dataset :any; 
    
  constructor(private el: ElementRef) {
    this.htmlElement = this.el.nativeElement;
    this.host = D3.select(this.htmlElement);               
  }

 
  
  ngOnChanges(){
    this.tempData = {};
    this.chart = {};
    this.packedCircleChartOptionData = this.packedCircleChartOption['data'];           
    console.log('*****this.packedCircleChartOptionData--',this.packedCircleChartOptionData)    
    if(this.packedCircleChartOptionData.length !== 0){  
      console.log('**Length is not null')   
      this.setup();          
      this.renderChart();  
     
    }else{
      this.host.html('');
     // this.buildErrorMsg();
    }   
  }

  private setup(): void {
    // set the dimensions and margins of the graph
    this.margin = 60;
    this.marginBottom = 0;
    this.width = 540;
    this.height = 440;
    this.dataset = this.packedCircleChartOptionData ;
    console.log('***dataset--',this.dataset)
    // this.dataset = {
    //   name:'TEST',
    //   children: [
    //     {
    //       name: 'ABC',
    //       children: [
    //         { "Name": "Olives", "Count": 4319 },
    //         { "Name": "Tea", "Count": 4159 },
    //         { "Name": "Mashed Potatoes", "Count": 2583 },
    //         { "Name": "Boiled Potatoes", "Count": 2074 },
    //         { "Name": "Milk", "Count": 1894 },
    //         { "Name": "Chicken Salad", "Count": 1809 },
    //         { "Name": "Vanilla Ice Cream", "Count": 1713 },
    //         { "Name": "Cocoa", "Count": 1636 },
    //         { "Name": "Lettuce Salad", "Count": 1566 }
    //       ]
    //     }, {
    //       name: 'CDE',
    //       children: [{ "Name": "Lobster Salad", "Count": 1511 },
    //       { "Name": "Chocolate", "Count": 1489 },
    //       { "Name": "Apple Pie", "Count": 1487 },
    //       { "Name": "Orange Juice", "Count": 1423 },
    //       { "Name": "American Cheese", "Count": 1372 },
    //       { "Name": "Green Peas", "Count": 1341 },
    //       { "Name": "Assorted Cakes", "Count": 1331 },
    //       { "Name": "French Fried Potatoes", "Count": 1328 },
    //       { "Name": "Potato Salad", "Count": 1306 },
    //       { "Name": "Baked Potatoes", "Count": 1293 },
    //       { "Name": "Roquefort", "Count": 1273 },
    //       { "Name": "Stewed Prunes", "Count": 1268 }]
    //     }      
    //   ]
    // };         
  }

  renderChart() {
    //--------------------------------------First starts---------------------    
    // let color = D3.scaleOrdinal(D3.schemeCategory10);
    // var color = ['#4d4d00','#007acc','#d7b70f','#ff0000','#ff00ff','#000000','#d7b70f','#f0d028','#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];   
    var color = D3.scaleOrdinal(D3.schemeSet2);
    /* Add SVG to the body of the page */
    this.host.html('');
    this.svg = this.host.append('svg')
    .attr("width", (this.width+this.margin)+"px")
    .attr("height", (this.height+this.margin)+"px")
    // .attr("margin", "auto")
    // .attr("margin", "right")
    .style("font-family"," Sans-Serif, Arial")  
    .append('g')
    // .style("fill", "green")
    .attr("transform", `translate(${this.margin}, ${this.marginBottom})`);  
    // .attr("transform", `translate(${this.margin}, 0})`);  


    // var svg = D3.select('body')
    //   .append('svg')     
    //   .attr('width', 540)
    //   .attr('height', 300)
    //   .style("fill", "green");

    // var data = [{
    //       x: Math.random() * 500,
    //       y: Math.random() * 500,
    //       r: Math.random() * 50
    //     }];
        
    // this.svg.selectAll('circle')
    //   .data(data)
    //   .enter()
    //   .append('circle')
    //   .attr('r', function(d){
    //     return d.r;
    //   })
    //   .attr('cx', function(d){
    //     return d.x;
    //   })
    //   .attr('cy', function(d){
    //     return d.y;
    //   }).style("fill", function (d, i: any) {
    //     return color(i);
    //   });
        
    
      //   d3.select(self.frameElement)
      // .style("height", 600 + "px")
      // .style("width", 600 + "px");
    //--------------------------------------first ends-----------------------    
    // let diameter = 600;
    let height = 500;
    let width = 500;
    // let width2 = 200;
    // let color = D3.scaleOrdinal(D3.schemeCategory10);
    // let color = ['#4d4d00','#007acc','#d7b70f','#ff0000','#ff00ff','#000000','#d7b70f','#f0d028','#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];

    let bubble = D3.pack()
      .size([width, height])
      .padding(1.5);
      
    // let svg = D3.select('#chart')
    //   .append("svg")
    //   .attr("width", width)
    //   .attr("height", height)
    //   .attr("class", "bubble");

    let nodes = D3.hierarchy(this.dataset)
      .sum(function (d: any) {
        // return d.Count;
        return d.count;
      });

    let node = this.svg.selectAll(".node")
      .data(bubble(nodes).descendants())
      .enter()      
      .append("g")
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      }).style("fill", function (d, i: any) {
        return color(i);
        // return color[i];
      });

    const leaf = node.filter(function (d) {
      return !d.children
    })

    // leaf.append("title")
    //   .text(function (d: any) {
    //     // return d.data.Name + ": " + d.data.Count;
    //     // return d.data.product + ": " + d.data.Count;
    //     return d.data.product + ": " + d.data.count;
    //   });
  
      leaf.append("title").text(function(d:any) {       
        if(!d.data.hasOwnProperty('stand_alone')) {
          return 'Product: '+d.data.product + "\nMarketplace: " +d.data.market_place + "\nSocial Media: " +d.data.social_media+ "\nTotal: " +d.data.count;
        }
        else {
          return 'Keyword: '+d.data.product + "\nMarketplace: " +d.data.market_place + "\nSocial Media: " +d.data.social_media+ "\nStandalone Website: " +d.data.stand_alone + "\nTotal: " +d.data.count;
        }
      });
    

      // leaf.append("title").text(function (d: any) {
      //   return d.data.product + ": " + d.data.count+"\n: ";
      //   return d.data.product + "\nURLs: " + format(d.data.total);
      //   // return d.data.Name + ": " + d.data.Count;
      //   // return d.data.product + ": " + d.data.Count;
      //   return d.data.product + ": " + d.data.count;
      // });
      node.append("title")
      .text(function (d: any) {
        // return d.data.name;
        return d.data.brand;
      });

    node.append("circle")
      .attr("x", function (d) { return d.x; })
      .attr("y", function (d) { return d.y })
      .attr("r", function (d) { return d.r;
      })
      .style("fill", function (d, i: any) {
        return color(i);
        // return color[i];
      })
      .style("stroke", "black") 
      .style("stroke-opacity", .2)
      //--------radius of circle increases to 10 on mouse hover------
      .on('mouseover', function(d, i) {                          
        D3.select(this).transition()
          .duration(1000)
          .attr("r", function(d) { return (d['r']+10); });          
          
        
        })
      .on('mouseout', function(d, i) {
        D3.select(this).transition()
          .style("opacity", 1)
          .attr("r",  function(d) { return (d['r']); })           
       })  ;
       //--------radius of circle decreases to its original radius on mouse out------


      leaf.append("text")
      // .attr("dy", ".2em")
      .style("text-anchor", "middle")
      .text(function (d: any) {
        // return d.data.Name.substring(0, d.r / 3);
        // return d.data.product.substring(0, d.r / 3);
        if(d['r']>20){
        return d.data.product;
        } else {
          let title = '';
          return title;
        }
      })
      // .data(function(d) { let tempTitle=''; if(d['r']>20){return d.data.product;}else{return tempTitle} })
      .style('font-family','HelveticaNeue')   
      // .attr("font-family", "sans-serif")
      .style("font-size","11px")    
      // .attr("font-size", function (d) {
      //   return d.r / 5;
      // })
      .attr("fill", "black");
      
      // Display count on each bubble
      // leaf.append('text')
      // .attr("dy", "1.3em")
      // .style("text-anchor", "middle")
      // .text(function (d: any) {
      //   // return d.data.Count;
      //   return d.data.count;
      // })
      // .attr("font-family", "Gill Sans")
      // .attr("font-size", function (d) {
      //   return d.r / 5;
      // })
      // .attr("fill", "white");



      // node.append('text')
      // .attr("dy", "1.3em")
      // .style("text-anchor", "bottom")
      // .text(function (d: any) {
      //   return d.data.name;
      // })
      // .attr("font-family", "Gill Sans")
      // .attr("font-size", function (d) {
      //   return d.r / 5;
      // })
      // .attr("fill", "white");

      

    // d3.select(self.frameElement)
    //   .style("height", height + "px")
    //   .style("width", width2 + "px");;

  }



}
