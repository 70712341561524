import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SuccessrateService } from '../../services/successrate/successrate.service';
import * as moment from 'moment';
import * as D3 from 'd3';
import { LocalStorageService } from 'ngx-webstorage';
import { BrandService } from '../../services/brand/brand.service';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../../settings.global';

@Component({
  selector: 'app-success-rate-social-media',
  templateUrl: './success-rate-social-media.component.html',
  styleUrls: ['./success-rate-social-media.component.scss']
})
export class SuccessRateSocialMediaComponent implements OnInit {
  
  @ViewChild('mapContainer') mapContainer: ElementRef;

  public barchartOption: object;
  public data: any;
  public details: any;
  public lsData: any;
  public order: any;
  public mapData: any;
  public socialData: any;
  public svg: any;
  public top: any;
  public left:any;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
  };
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public selectedDateRangeLabel : any;


  constructor(private successrateService: SuccessrateService,
    private lsService: LocalStorageService,
    private loaderService:LoaderService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private brandService: BrandService) {
      this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel');   
    this.data = [];
    this.details = [];
    // this.order = 'ASC';
    this.order = 'DESC';
    this.lsData = JSON.parse(this.lsService.retrieve('search_base'));
    this.details = {
      id: this.lsService.retrieve('active_brand_id'),
      region: this.lsData.region,
      fromDate: this.lsData.fromDate,
      toDate: this.lsData.toDate,
      orderBy: this.order
    };
    
    this.mapData = [];
    this.socialData = [];
    this.daterange.start = this.lsData.fromDate;
    this.daterange.end = this.lsData.toDate;
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    
    // let fromDateLocal =  this.lsData.fromDate.split('-');
    // let formattedFromDateLocal = fromDateLocal[1]+'-'+fromDateLocal[2]+'-'+fromDateLocal[0];
    // let toDateLocal =  this.lsData.toDate.split('-');
    // let formattedtoDateLocal = toDateLocal[1]+'-'+toDateLocal[2]+'-'+toDateLocal[0];
    // this.datepickerOptions.startDate =  formattedFromDateLocal;
    // this.datepickerOptions.endDate = formattedtoDateLocal;
    this.datepickerOptions.startDate =  new Date(this.lsData.fromDate);
    this.datepickerOptions.endDate = new Date(this.lsData.toDate);     
    console.log('this.datepickerOptions.startDate--',this.datepickerOptions.startDate)  
    console.log('this.datepickerOptions.endDate--',this.datepickerOptions.endDate) 
  }
  // ngAfterViewInit(){
  //   const top = this.mapContainer.nativeElement.getBoundingClientRect().top
  //   console.log('top',top);
  // }

  ngOnInit() {
    // this.loaderService.display(true);
    // this.brandService.brandChanged.subscribe((id) => {
    //   this.loaderService.display(false);
    //   this.details.id = id;
    //   this.getSocialMediaData(this.details);
    //   this.drawMap(this.mapData,this.details);
    // });
    this.getSocialMediaData(this.details);
      this.drawMap(this.mapData,this.details);
    
  }

  getSocialMediaData(details) {
    this.lsService. store('search_base', JSON.stringify(this.lsData));
    this.loaderService.display(true);
    this.successrateService.getSocialMediaData(details).subscribe((res: any) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
         /* Sort list based on the success rate, if same success-rate occurs then
           sort it based on total links
        */
        // this.data = res.json().success_rate;
        let graphData = res.json().success_rate;
        graphData.forEach(element => {           
          console.log(element)
          element.success_rate = Math.round(element.success_rate);
         });
         let sortBy = []; 
         if(details.orderBy == 'DESC') {
          sortBy = [{
            prop:'success_rate',
            direction: -1 //descending
          },{
            prop:'total_count',
            direction: -1
          }];
       } 
       if(details.orderBy == 'ASC') {
          sortBy = [{
            prop:'success_rate',
            direction: 1 //ascending
          },{
            prop:'total_count',
            direction: -1
          }];
       }
       graphData.sort(function(a,b) {
        let i = 0, result = 0;
        while(i < sortBy.length && result === 0) {
          // result = sortBy[i].direction*(a[ sortBy[i].prop ].toString() < b[ sortBy[i].prop ].toString() ? -1 : (a[ sortBy[i].prop ].toString() > b[ sortBy[i].prop ].toString() ? 1 : 0));
          result = sortBy[i].direction*(a[ sortBy[i].prop ] < b[ sortBy[i].prop ] ? -1 : (a[ sortBy[i].prop ] > b[ sortBy[i].prop ] ? 1 : 0));
          i++;
        }
        return result;
      })
      console.log('@@@Sorted--data--',graphData)
      this.data = graphData;    
        if(this.data == 0){
          this.data = null;
        }
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }
      
    });
  }

  onChange(order) {
    this.details.orderBy = order;
    this.getSocialMediaData(this.details);
    this.drawMap(this.mapData,this.details);
  }

  //Change date range
  selectedDate(value: any) {
    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel); 

    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

    this.details.fromDate = this.datepickerOptions.startDate;
    this.details.toDate = this.datepickerOptions.endDate;

    this.lsData.fromDate = this.datepickerOptions.startDate;
    this.lsData.toDate = this.datepickerOptions.endDate;

    // this.searchBase.fromDate = this.datepickerOptions.startDate ;
    // this.searchBase.toDate = this.datepickerOptions.endDate;

    this.getSocialMediaData(this.details);
    this.drawMap(this.mapData,this.details);
    

    // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
    // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');

    // this.details.fromDate = this.daterange.start;
    // this.details.toDate = this.daterange.end;

    // this.lsData.fromDate = this.daterange.start;
    // this.lsData.toDate = this.daterange.end;

    // this.getSocialMediaData(this.details);
    // this.drawMap(this.mapData,this.details);    
  }

  onMapInit(selection) {
    this.mapData = selection.countries;
    this.drawMap(this.mapData,this.details);
    const svg = selection.svg;
    const svgHeight = svg.attr('height');
    const svgWidth = svg.attr('width')
    const zoom = D3.zoom()
      .scaleExtent([1, 1.5])
      .translateExtent([[-100, -100], [svgWidth + 100, svgHeight + 100]])
      .on('zoom', zoomed);

    function zoomed() {
      svg.attr('transform', D3.event.transform);
    }
    svg.call(zoom);
  }

  drawMap(country,details){
    console.log(country,details)
    var tooltip = D3.select("div.tooltip");
    this.loaderService.display(true);
    this.successrateService.getSocialMediaData(details).subscribe((res: any) => {
      this.loaderService.display(false);
      this.socialData=res.json().disabled_likes_per_country;
      country.attr('fill', (d) => {
        return this.getColor(d['properties'].iso_a2,this.socialData);
        })
        .on("mouseover",(d) =>{
         const data = this.getCountryData(d['properties'].iso_a2,this.socialData)
          return tooltip.style("hidden", false).html('<strong>'+d['properties'].name+'</strong></br>' + data);
        })
        .on("mousemove",(d) =>{
          this.top = this.mapContainer.nativeElement.getBoundingClientRect().top;
          this.left = this.mapContainer.nativeElement.getBoundingClientRect().left;
          if(D3.event.pageY < 1000){
            this.top = -100;
          }else{
          this.top = 5.2 * this.top;
          this.left = this.left + 5;
          }
          if (this.socialData.some((item) => item.code == d['properties'].iso_a2)) {
              tooltip.classed("hidden", false)
                    .style("top", (D3.event.pageY + this.top ) + "px")
                    .style("left", (D3.event.pageX - this.left) + "px")
                    .html('<strong>'+d['properties'].name+'</strong></br>'+this.getCountryData(d['properties'].iso_a2,this.socialData));
          }
        })
      .on("mouseout",function(d,i){
        tooltip.classed("hidden", true);
        });
          
    });        
  }

  getColor(code,social){  
      if(social.length){
        for(let i=0; i < social.length; i++){
          if(social[i].code == code){
            if(social[i].likes > 4000){
             // return '#ed3b2b';  
              return '#FF9933'; 
            }else if(social[i].likes <= 4000 && social[i].likes >3000){
             // return '#fc7e34';
              return '#FFCC00';
            }else if(social[i].likes <=3000 && social[i].likes >2000){
             // return '#fdca3f';
             return '#FFCC99';
            }else if(social[i].likes <=2000 && social[i].likes >1000){
              //return '#fbe844';
              return '#FAEC7F';
            }else{
              return '#FFFFCC';
            }            
          }     
        }
        return '#d4d4d4';
      }else{
        return '#d4d4d4';
      }
   }

   getCountryData(code,social){
    if(social.length){
      for(let i=0; i < social.length; i++){
        if(social[i].code == code){
          return Math.round(social[i].reported_links_in_percentage) + '% of total volume </br>'+ social[i].likes+' likes removed on '
            +social[i].profile_count+'<br>profiles';
        }
      }
      return 'No data'
    }
   }



}
