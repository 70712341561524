import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-tools',
  templateUrl: './home-tools.component.html',
  styleUrls: ['./home-tools.component.scss']
})
export class HomeToolsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  
}
